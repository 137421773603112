import classNames from 'classnames';
import React, {
  ButtonHTMLAttributes,
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  memo,
  PropsWithChildren,
  Ref,
  RefObject,
  useRef
} from 'react';
import { Color, FieldState } from '../../enums';
import usePressed from '../../hooks/usePressed';
import {
  Contractable,
  Selectable,
  Testable,
  Themeable
} from '../../interfaces/componentProps';
import {
  getFieldStateClass,
  getTestSelectorAttribute,
  getThemeClass
} from '../../utils';
import { AcRipple } from '../ripple';
import { AcSpinnerCover } from '../spinner';
import './AcButton.sass';
import { AcButtonPattern } from './acButtonPattern';
import { AcButtonShape } from './acButtonShape';
import { AcButtonSize } from './acButtonSize';

export interface AcButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    Themeable,
    Selectable,
    Testable,
    Contractable {
  loading?: boolean;
  pattern?: AcButtonPattern;
  shape?: AcButtonShape;
  size?: AcButtonSize;
  fullwidth?: boolean;
  backgroundStyle?: CSSProperties;
  backgroundClassName?: string;
}

const AcButton: ForwardRefRenderFunction<
  HTMLButtonElement,
  PropsWithChildren<AcButtonProps>
> = (
  {
    size,
    shape,
    pattern,
    loading,
    fullwidth,
    contractedTouchArea,
    selected,
    theme,
    testSelector,
    children,
    className: classNameProp,
    backgroundClassName: backgroundClassNameProp,
    backgroundStyle,
    ...buttonProps
  }: PropsWithChildren<AcButtonProps>,
  ref: Ref<HTMLButtonElement>
) => {
  const innerRef = useRef<HTMLButtonElement>(null);
  ref = ref || innerRef;

  const pressed = usePressed(ref as RefObject<HTMLElement>);

  const className: string = classNames(
    'ac-button',
    `ac-button-size-${size || AcButtonSize.Medium}`,
    `ac-button-${pattern || AcButtonPattern.Primary}`,
    `ac-button-shape-${shape || AcButtonShape.Rounded}`,
    { 'ac-button-loading': loading },
    { 'ac-button-full-width': fullwidth },
    { 'ac-button-contracted': contractedTouchArea },
    getThemeClass({ theme }, 'ac-button'),
    getFieldStateClass(selected as boolean, FieldState.Selected, 'ac-button'),
    getFieldStateClass(pressed, FieldState.Pressed, 'ac-button'),
    classNameProp
  );

  const backgroundClassName = classNames(
    'ac-button-background',
    backgroundClassNameProp
  );

  return (
    <button
      ref={ref}
      className={className}
      {...buttonProps}
      {...getTestSelectorAttribute(testSelector)}
    >
      <AcRipple
        className={backgroundClassName}
        ripplesContainerClassName="ac-button-background-ripples"
        color={Color.PrimarySelected}
        style={backgroundStyle}
      >
        {children}
        <AcSpinnerCover
          color={Color.PrimarySelected}
          noBackground={true}
          disabled={!loading}
        />
      </AcRipple>
    </button>
  );
};

export default memo(forwardRef(AcButton));
