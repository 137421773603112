export enum AcFieldTypes {
  Email = 'email',
  Number = 'number',
  Tel = 'tel',
  Text = 'text',
  Password = 'password',
  Search = 'search',
  Date = 'date',
  Time = 'time'
}

export enum AcFieldInputMode {
  None = 'none',
  Text = 'text',
  Decimal = 'decimal',
  Numeric = 'numeric',
  Telephone = 'tel',
  Search = 'search',
  Email = 'email',
  Url = 'url'
}

export enum AcFieldNumberVariant {
  Normal = 'normal',
  Borderless = 'borderless'
}

export const defaultOutputFormat = 'YYYY-MM-DD[T]HH:mm:ss';
export const DISPLAY_TIME_FORMAT_12_HOURS = 'hh:mm A';
