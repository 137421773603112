import classNames from 'classnames';
import React, { FC } from 'react';
import { ChildRequired, Styleable, Testable } from '../../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../../utils';

// tslint:disable-next-line:no-empty-interface
export interface AcModalSubHeaderProps extends ChildRequired, Styleable, Testable {}

const AcModalSubHeader: FC<AcModalSubHeaderProps> = (
  props: AcModalSubHeaderProps
) => {
  const classes: string = classNames('ac-modal-subheader', props.className);

  return (
    <div
      className={classes}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      {props.children}
    </div>
  );
};

export default AcModalSubHeader;
