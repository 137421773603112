import classNames from 'classnames';
import React, { FC, memo, PropsWithChildren } from 'react';
import { Color } from '../../enums';
import { Disableable } from '../../interfaces/componentProps';
import AcSpinner from './AcSpinner';
import './AcSpinnerCover.sass';
import { AcSpinnerProps } from './acSpinnerProps';

export interface AcSpinnerCoverProps extends AcSpinnerProps, Disableable {
  fixed?: boolean;
  coverScreen?: boolean;
  noBackground?: boolean;
}

const defaultProps: AcSpinnerProps = {
  color: Color.PrimaryDark
};

const AcSpinnerCover: FC<PropsWithChildren<AcSpinnerCoverProps>> = (
  props: PropsWithChildren<AcSpinnerCoverProps>
) => {
  const className: string = classNames(
    'ac-spinner-cover',
    props.fixed && 'ac-spinner-cover-fixed',
    props.noBackground && 'ac-spinner-cover-no-background',
    props.coverScreen && 'ac-spinner-cover-screen',
    props.disabled && 'ac-spinner-cover-disabled',
    props.className
  );

  return (
    <div className={className} style={props.style}>
      {props.children}
      <AcSpinner
        color={props.color}
        large={props.large}
        testSelector={props.testSelector}
        className="ac-spinner-cover-spinner"
      />
    </div>
  );
};

AcSpinnerCover.defaultProps = defaultProps;
export default memo(AcSpinnerCover);
