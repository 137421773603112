import classNames from 'classnames';
import React, {
  CSSProperties,
  FC,
  memo,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import AcMobileContext from '../../config/AcMobileContext';
import {
  ChildRequired,
  Disableable,
  Styleable
} from '../../interfaces/componentProps';
import './AcFabContainer.sass';
import AcFloatingContext, { AcFloatingContextData } from './AcFabContext';

interface ExpandedItem {
  itemId?: string;
  eventToDelay?(): void;
}

export interface AcFabNavigationProps
  extends Styleable,
    ChildRequired,
    Disableable {
  paddingStyle?: CSSProperties;
  paddingClassName?: string;
}

const fabVerticalPadding = 9;
const repositionTimeout = 25;

const AcFabContainer: FC<AcFabNavigationProps> = (
  props: AcFabNavigationProps
) => {
  const [isBackdropVisible, setIsBackdropVisible] = useState<boolean>(false);
  const [isDialogExpanded, setDialogExpanded] = useState<boolean>(false);
  const [expandedFabButton, setExpandedFabButton] = useState<ExpandedItem>({});

  const [dialogOffsetBottom, setDialogOffsetBottom] = useState<number>(0);

  const [fabRefs, setFabRefs] = useState<
    Set<MutableRefObject<HTMLElement | null>>
  >(new Set());

  const acMobileContext = useContext(AcMobileContext)!;

  const dialogRef = useRef<HTMLDivElement>(null);
  const paddingRef = useRef<HTMLDivElement>(null);

  const className: string = classNames(
    'ac-fab-navigation',
    { 'ac-fab-open': isDialogExpanded },
    props.className
  );

  const addFabRef = useCallback(
    (ref: MutableRefObject<HTMLElement | null>) =>
      setFabRefs(refs => new Set(refs).add(ref)),
    []
  );

  const removeFabRef = useCallback(
    (ref: MutableRefObject<HTMLElement | null>) =>
      setFabRefs(refs => {
        const newSet = new Set(refs);
        newSet.delete(ref);

        return newSet;
      }),
    []
  );

  useEffect(() => {
    if (expandedFabButton.itemId) {
      setDialogExpanded(true);
      setIsBackdropVisible(true);
    } else {
      setDialogExpanded(false);
    }
  }, [expandedFabButton]);

  useEffect(() => {
    addEventListener('click', onWindowClick);

    return () => {
      removeEventListener('click', onWindowClick);
    };
  }, []);

  const rootElement = useContext(AcMobileContext)!.rootElement;

  const updateDialogOffsets = () => {
    if (paddingRef.current) {
      const offset = paddingRef.current.offsetParent || rootElement;
      let currentElement: HTMLElement | null = paddingRef.current;
      let totalMargin = 0;
      while (currentElement && currentElement !== offset) {
        const style = window.getComputedStyle(currentElement);
        totalMargin +=
          Number.parseFloat(style.paddingBottom) +
          Number.parseFloat(style.marginBottom);
        currentElement = currentElement.parentElement;
      }
      const offsetRect = offset.getBoundingClientRect();
      const offsetMargin =
        window.innerHeight -
        (offsetRect.y + offsetRect.height) -
        fabVerticalPadding +
        totalMargin;

      const notificationsRect = acMobileContext?.overlayNotificationsWrapperRef?.current?.getBoundingClientRect();
      const notificationMargin =
        (notificationsRect?.height || 0) > 0
          ? window.innerHeight - notificationsRect!.top
          : Number.MIN_SAFE_INTEGER;

      const selectedMargin = Math.max(offsetMargin, notificationMargin);

      setDialogOffsetBottom(selectedMargin);
    }
  };

  useLayoutEffect(() => {
    updateDialogOffsets();
    const interval = setInterval(updateDialogOffsets, repositionTimeout);

    return () => {
      clearInterval(interval);
    };
  }, [rootElement, acMobileContext?.overlayNotificationsWrapperRef]);

  const onBackdropAnimationEnd = () => {
    if (!expandedFabButton.itemId) {
      setIsBackdropVisible(false);

      if (expandedFabButton.eventToDelay) {
        expandedFabButton.eventToDelay();
      }
    }
  };

  const floatingContextData: AcFloatingContextData = {
    disableAllButtons: props.disabled,
    registerFab: addFabRef,
    unregisterFab: removeFabRef,
    getExpandedItem: () => expandedFabButton.itemId,
    setItemToExpand: (itemId: string) => setExpandedFabButton({ itemId }),
    closeExpandedItem: (eventToDelay?: () => void) =>
      setExpandedFabButton({ eventToDelay })
  };

  const onWindowClick = (e: MouseEvent): void => {
    const target = e.target as HTMLElement;
    if (!target.closest('.ac-fab-dialog')) {
      floatingContextData.closeExpandedItem();
      setDialogExpanded(false);
    }
  };

  const dialogStyle = useMemo<CSSProperties>(
    () => ({
      marginBottom: dialogOffsetBottom
    }),
    [dialogOffsetBottom]
  );

  const paddingHeight = Math.max(
    0,
    Array.from(fabRefs).reduce(
      (total, ref) => ref.current!.clientHeight + total,
      0
    ) -
      2 * fabVerticalPadding
  );

  const paddingClassname = classNames('ac-fab-padding', props.paddingClassName);

  return (
    <>
      <div
        className={paddingClassname}
        style={{ ...props.paddingStyle, height: paddingHeight }}
        ref={paddingRef}
      >
        {
          ReactDOM.createPortal(
            <AcFloatingContext.Provider value={floatingContextData}>
              <div className={className} style={props.style}>
                {isBackdropVisible && (
                  <div
                    className="ac-fab-backdrop"
                    onAnimationEnd={onBackdropAnimationEnd}
                  />
                )}
                <div
                  className="ac-fab-dialog"
                  role="dialog"
                  ref={dialogRef}
                  style={dialogStyle}
                >
                  {props.children}
                </div>
              </div>
            </AcFloatingContext.Provider>,
            acMobileContext!.rootElement
          ) as ReactNode
        }
      </div>
    </>
  );
};

export default memo(AcFabContainer);
