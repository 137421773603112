import { useCallback, useMemo, useState } from 'react';

import { ViewBreakpoint } from '../responsiveness';

import { getDefaultViewportState, ViewportState } from './state';

export interface ViewportStore {
  state: ViewportState;

  setViewBreakpoint(responsiveBreakpoint: ViewBreakpoint): void;
  setError(error: string | undefined): void;
}

export const useViewportStore = (): ViewportStore => {
  const [state, setState] = useState(getDefaultViewportState());

  const setViewBreakpoint = useCallback(
    (responsiveBreakpoint: ViewBreakpoint) => {
      setState((prevState) => {
        if (responsiveBreakpoint === prevState.responsiveBreakpoint) {
          return prevState;
        }
        return {
          ...prevState,
          responsiveBreakpoint,
        };
      });
    },
    []
  );

  const setError = useCallback((error: string | undefined) => {
    setState((prevState) => ({
      ...prevState,
      error,
    }));
  }, []);

  return useMemo(
    () => ({
      state,
      setViewBreakpoint,
      setError,
    }),
    [state, setViewBreakpoint, setError]
  );
};
