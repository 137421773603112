import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { Color, Icon, IconSize } from '../../enums';
import { Childless, Styleable, Testable } from '../../interfaces/componentProps';
import { getColorClass, getTestSelectorAttribute } from '../../utils';
import './AcIcon.sass';

export interface AcIconProps extends Childless, Styleable, Testable {
  icon: Icon;
  color?: Color;
  size?: IconSize;
}

const defaultProps: AcIconProps = {
  icon: Icon.Check,
  size: IconSize.Standard
};

const AcIcon: FC<AcIconProps> = (props: AcIconProps) => {
  const className: string = classNames(
    'ac-icon',
    props.color ? getColorClass(props.color) : null,
    `ac-icon-${props.icon}`,
    `ac-icon-${props.size}`,
    props.className
  );

  return (
    <i
      className={className}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    />
  );
};

AcIcon.defaultProps = defaultProps;
export default memo(AcIcon);
