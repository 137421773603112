import { useCallback, useState } from 'react';

import {
  ConfigurationEntitiesAggregate,
  PropertyBusinessContext,
} from '@ac/library-api';

import { PropertyDateTimeFormatSettings } from '../../../../businessContext/property/dateTimeFormats';

import {
  getDefaultSinglePropertyWorkContextState,
  SinglePropertyWorkContextState,
} from './state';

/**@deprecated Please use PropertyBusinessContextStore*/
export interface SinglePropertyWorkContextStore {
  state: SinglePropertyWorkContextState;

  setBusinessContext(businessContext: PropertyBusinessContext): void;
  setPropertyDateTimeFormatSettings(
    propertyDateTimeFormatSettings: PropertyDateTimeFormatSettings
  ): void;
  setConfigurationEntities(
    configurationEntities: ConfigurationEntitiesAggregate
  ): void;
}

export const useCreateSinglePropertyWorkContextStore =
  (): SinglePropertyWorkContextStore => {
    const [state, setState] = useState(
      getDefaultSinglePropertyWorkContextState()
    );

    const setBusinessContext = useCallback(
      (businessContext: PropertyBusinessContext) => {
        setState((prevState) => ({
          ...prevState,
          businessContext,
          configurationEntities:
            businessContext.property.configurationEntities.entities,
        }));
      },
      []
    );

    const setPropertyDateTimeFormatSettings = useCallback(
      (propertyDateTimeFormatSettings: PropertyDateTimeFormatSettings) => {
        setState((prevState) => ({
          ...prevState,
          propertyDateTimeFormatSettings,
        }));
      },
      []
    );

    const setConfigurationEntities = (
      configurationEntities: ConfigurationEntitiesAggregate
    ): void => {
      setState((prevState) => ({
        ...prevState,
        configurationEntities,
      }));
    };

    return {
      state,
      setBusinessContext,
      setPropertyDateTimeFormatSettings,
      setConfigurationEntities,
    };
  };
