import { HslColor } from "src/interfaces/hslColor";

function cutHex(color: string): string {
  return color.startsWith('#') ? color.substring(1, 7) : color;
}

const hexToHsl = (color: string): HslColor => {
  const cuttedColor = cutHex(color);
  // Convert hex to RGB first
  let r = 0;
  let g = 0;
  let b = 0;
  if (cuttedColor.length === 3) {
    r = parseInt('0x' + cuttedColor[0] + cuttedColor[0], 16);
    g = parseInt('0x' + cuttedColor[1] + cuttedColor[1], 16);
    b = parseInt('0x' + cuttedColor[2] + cuttedColor[2], 16);
  } else if (cuttedColor.length === 6) {
    r = parseInt('0x' + cuttedColor[0] + cuttedColor[1], 16);
    g = parseInt('0x' + cuttedColor[2] + cuttedColor[3], 16);
    b = parseInt('0x' + cuttedColor[4] + cuttedColor[5], 16);
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }
  h = Math.round(h * 60);

  if (h < 0) {
    h += 360;
  }
  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
};

export default hexToHsl;