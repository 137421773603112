import { CentralReservationOfficeContext } from '@ac/library-api';

export interface CentralReservationOfficeBusinessContextState {
  businessContext: CentralReservationOfficeContext | undefined;
}

export const getDefaultCentralReservationOfficeBusinessContextState =
  (): CentralReservationOfficeBusinessContextState => ({
    businessContext: undefined,
  });
