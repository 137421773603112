import { ViewBreakpoint } from '../responsiveness/viewBreakpoint';

export interface ViewportState {
  responsiveBreakpoint: ViewBreakpoint;
  error?: string;
}

export const getDefaultViewportState = (): ViewportState => ({
  responsiveBreakpoint: ViewBreakpoint.SM,
  error: undefined,
});
