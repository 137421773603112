import React, { FC, memo, PropsWithChildren, useState } from 'react';
import AcAccordionGenericProps from '../interface/AcAccordionGenericProps';
import AcAccordionItemState from '../interface/AcAccordionItemState';
import { AcAccordionContext } from './AcAccordionContext';

type AccordionItemsMap = Map<string, AcAccordionItemState>;

const AcAccordionWrapper: FC<PropsWithChildren<AcAccordionGenericProps>> = (
  props: PropsWithChildren<AcAccordionGenericProps>
) => {
  const [accordionsItem, setAccordionsItem] = useState<AccordionItemsMap>(
    new Map()
  );

  const toggleExpanded = (accordionId: string) => {
    if (accordionsItem.has(accordionId)) {
      const item = accordionsItem.get(accordionId)!;

      if (!item.disabled) {
        item.opened = !item.opened;
        updateAccordionsItems(accordionsItem);
      }
    }
  };

  const isItemExpanded = (accordionId: string): boolean => {
    return !!accordionsItem.get(accordionId)?.opened;
  };

  const isItemDisabled = (accordionId: string): boolean => {
    return !!accordionsItem.get(accordionId)?.disabled;
  };

  const setDisableStatus = (accordionId: string, status: boolean) => {
    if (accordionsItem.has(accordionId)) {
      accordionsItem.get(accordionId)!.disabled = status;
      updateAccordionsItems(accordionsItem);
    }
  };

  const unregisterItem = (accordionId: string) => {
    accordionsItem.delete(accordionId);
    updateAccordionsItems(accordionsItem);
  };

  const registerItem = (accordionId: string, item: AcAccordionItemState) => {
    accordionsItem.set(accordionId, item);
    updateAccordionsItems(accordionsItem);
  };

  const updateAccordionsItems = (items: AccordionItemsMap) => {
    setAccordionsItem(new Map<string, AcAccordionItemState>(items));
  };

  const isRegistered = (accordionId: string): boolean => {
    return accordionsItem.has(accordionId);
  };

  return (
    <AcAccordionContext.Provider
      value={{
        toggleExpanded,
        isItemExpanded,
        isItemDisabled,
        setDisableStatus,
        unregisterItem,
        registerItem,
        isRegistered
      }}
    >
      {props.children || null}
    </AcAccordionContext.Provider>
  );
};

export default memo(AcAccordionWrapper);
