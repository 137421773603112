import { History } from 'history';
import * as qs from 'qs';

const stringMarker = '_';

function decodeQueryParamComponent(URIpart: string): string {
  return decodeURIComponent(URIpart.replace(/\+/g, ' '));
}

function decodeQueryParam(URIpart: string): string {
  return decodeURI(URIpart.replace(/\+/g, ' '));
}

function containsEncodedComponents(str: any): boolean {
  return decodeURI(str) !== decodeURIComponent(str);
}

function containsDecodedComponents(str: any): boolean {
  return encodeURI(str) !== encodeURIComponent(str);
}

function encoder(str: any): string {
  if (typeof str === 'string') {
    if (containsDecodedComponents(str)) {
      return `${stringMarker}${encodeURIComponent(str)}${stringMarker}`;
    }

    return `${stringMarker}${encodeURI(str)}${stringMarker}`;
  }

  return str;
}

function decoder(str: string): any {
  if (str.startsWith(stringMarker) && str.endsWith(stringMarker)) {
    if (containsEncodedComponents(str)) {
      return decodeQueryParamComponent(
        str
          .substring(stringMarker.length)
          .substring(0, str.length - stringMarker.length * 2)
      );
    }

    return decodeQueryParam(
      str
        .substring(stringMarker.length)
        .substring(0, str.length - stringMarker.length * 2)
    );
  }

  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
}

/**
 * @deprecated Use `serializeToUrlSearchString`
 */
export function setUrlSearchParams(history: History, nextQueryObject: any) {
  const prevSearchObject = parseUrlSearchParams(history);
  const obj = {
    ...prevSearchObject,
    ...nextQueryObject,
  };

  const queryString = serializeToUrlSearchString(obj);

  history.replace({
    pathname: history.location.pathname,
    search: `?${queryString}`,
  });
}

export function serializeToUrlSearchString(queryObject: any): string {
  const options: qs.IStringifyOptions = {
    encoder,
    encodeValuesOnly: true,
    arrayFormat: 'brackets',
  };

  return qs.stringify(queryObject, options);
}

/**
 * @deprecated Use `deserializeUrlSearchString`
 */
export function parseUrlSearchParams<T extends {}>(history: History) {
  const searchString = history.location.search;
  return deserializeUrlSearchString<T>(searchString);
}

export function deserializeUrlSearchString<T extends {}>(
  urlSearchString: string
): T {
  const searchString = urlSearchString.replace(/^\?/, '');
  return qs.parse(searchString, { decoder }) as T;
}
