import React from '../../../../../wc';
import { PropsWithChildren } from '../../../../reactTransition/propsWithChildren';

import { UserBusinessContext } from './context';
import { useCreateUserBusinessContextStore } from './store';

export const UserBusinessContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useCreateUserBusinessContextStore();

  return (
    <UserBusinessContext.Provider value={store}>
      {props.children}
    </UserBusinessContext.Provider>
  );
};
