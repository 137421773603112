import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { getTestSelectorAttribute } from '../../../utils';
import { AcAccordionsElementProps } from '../index';

const AcAccordionContent: FC<AcAccordionsElementProps> = (
  props: AcAccordionsElementProps
) => {
  const classes = classNames('ac-accordion-content', props.className);

  return (
    <div className={classes} {...getTestSelectorAttribute(props.testSelector)}>
      <div className="ac-spacing-top-md">{props.children}</div>
    </div>
  );
};

export default memo(AcAccordionContent);
