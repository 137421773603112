import { Fragment, useEffect } from 'react';

import { FullPageSpinner } from '../../../../../components';
import React from '../../../../../wc';
import { PropsWithChildren } from '../../../../reactTransition/propsWithChildren';
import { getCurrentTenantId } from '../../tenant/globalTenant';
import { prepareDateTimeSettings } from '../dateTimeFormats';
import { getCurrentGlobalOrLoadPropertyContext } from '../getCurrentGlobalOrLoadPropertyContext';
import { usePropertyBusinessContext } from '../store';

interface Props {
  propertyId: string;
  tenantId?: string;
  customLoadingView?: JSX.Element;
}

export const ExactPropertyBusinessContextLoader = (
  props: PropsWithChildren<Props>
): JSX.Element => {
  const { state, setBusinessContext, setPropertyDateTimeFormatSettings } =
    usePropertyBusinessContext();

  useEffect(() => {
    const initializeContext = async () => {
      const tenantId = props.tenantId ?? getCurrentTenantId();

      if (!tenantId) {
        throw new Error(
          'Cannot create Exact Property Business Context without known tenant ID'
        );
      }

      const businessContext = await getCurrentGlobalOrLoadPropertyContext({
        propertyId: props.propertyId,
        tenantId,
      });

      setBusinessContext(businessContext);
      setPropertyDateTimeFormatSettings(
        prepareDateTimeSettings(businessContext)
      );
    };

    void initializeContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.propertyId, props.tenantId]);

  return state.businessContext ? (
    <Fragment>{props.children}</Fragment>
  ) : (
    props.customLoadingView ?? <FullPageSpinner isVisible={true} />
  );
};
