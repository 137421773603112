import React, { MutableRefObject } from 'react';
import { Link } from 'react-router-dom';

export interface AcMobileConfig {
  rootElement: Element;
  linkComponent?: typeof Link;
}

export interface AcMobileContextData extends AcMobileConfig {
  overlayNotificationsWrapperRef: MutableRefObject<HTMLDivElement>;
  currentModuleName: string;
  setCurrentModuleName(moduleName: string): void;
}

export default React.createContext<AcMobileContextData | null>(null);
