import { BaseApi } from '@ac/library-api';
import { acConfig } from '@ac/library-utils/dist/declarations';

import { getLibraryApiConfigForSepModule } from './getLibraryApiConfigForSepModule';

export type SetupLibraryApiForModuleOptions = { skipPropertyHeader?: boolean };

export const setUpLibraryApiForModule = ({
  skipPropertyHeader = false,
}: SetupLibraryApiForModuleOptions = {}): void => {
  BaseApi.host = acConfig.apiUrl;
  BaseApi.interval = 500;
  BaseApi.iterations = 40;
  BaseApi.defaultConfig = () =>
    getLibraryApiConfigForSepModule({ skipPropertyHeader });
};
