import React, { useState } from 'react';
import '../AcSelect.sass';
import { AcSelectGeneric } from '../interfaces/AcSelectGeneric';
import AcSelectField from './AcSelectField';
import AcSelectList from './AcSelectList';

export interface AcSelectGenericProps<T> extends AcSelectGeneric<T> {
  multiply: boolean;
  onUpdate(selectedValue: string[]): void;
  clearSelectedValue(): void;
  getSelectedLabels(): string;
  isSelectEmpty(): boolean;
}

const AcSelectBase: <T extends string | string[]>(
  p: AcSelectGenericProps<T>
) => React.ReactElement<AcSelectGenericProps<T>> = props => {
  const [modalState, setModalState] = useState<boolean>(false);

  function openModal(): void {
    if (!props.disabled || !props.readonly) {
      setModalState(true);
    }
  }

  function closeModal(): void {
    setModalState(false);
  }

  return (
    <>
      <AcSelectField
        name={props.name}
        style={props.style}
        onClick={openModal}
        label={props.label}
        loading={props.loading}
        pattern={props.pattern}
        onReload={props.onReload}
        disabled={props.disabled}
        readonly={props.readonly}
        required={props.required}
        clearable={props.clearable}
        className={props.className}
        validation={props.validation}
        placeholder={props.placeholder}
        testSelector={props.testSelector}
        value={props.getSelectedLabels()}
        clearSelectedValue={props.clearSelectedValue}
      />
      <AcSelectList
        label={props.label}
        onClose={closeModal}
        isVisible={modalState}
        multiple={props.multiply}
        itemsList={props.itemsList}
        showSearch={props.showSearch}
        updateSelect={props.onUpdate}
        showInputs={!!props.showInputs}
        forceUpdate={props.forceUpdate}
        translations={props.translations}
        testSelector={props.testSelector}
        itemTemplate={props.itemTemplate}
        defaultSelectedItems={props.selectedItem}
      />
    </>
  );
};

export default AcSelectBase;
