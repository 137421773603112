import { useCallback, useState } from 'react';

import { UserContext } from '@ac/library-api';

import {
  getDefaultUserBusinessContextState,
  UserBusinessContextState,
} from './state';

export interface UserBusinessContextStore {
  state: UserBusinessContextState;

  setBusinessContext(businessContext: UserContext): void;
}

export const useCreateUserBusinessContextStore =
  (): UserBusinessContextStore => {
    const [state, setState] = useState(getDefaultUserBusinessContextState());

    const setBusinessContext = useCallback((businessContext: UserContext) => {
      setState((prevState) => ({
        ...prevState,
        businessContext,
      }));
    }, []);

    return {
      state,
      setBusinessContext,
    };
  };
