import classNames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import { Styleable } from '../../interfaces/componentProps';
import './AcPress.sass';

interface AcPressProps extends Styleable {
  time: number;
  onLongPress?(): void;
  onPress?(): void;
  onTouchStart?(e: TouchEvent): void;
  onTouchEnd?(e: TouchEvent): void;
  onTouchCancel?(e: TouchEvent): void;
  onTouchMove?(e: TouchEvent): void;
}

const defaultProps: AcPressProps = {
  time: 500
};

const AcPress: FC<PropsWithChildren<AcPressProps>> = (
  props: PropsWithChildren<AcPressProps>
) => {
  let timeout: number;
  let shouldShortPress = true;
  let moved = false;
  const classes = classNames('ac-press', props.className);

  function startTimeout() {
    timeout = window.setTimeout(longPressed, props.time);
  }

  function cancelTimeout() {
    clearTimeout(timeout);
  }

  function longPressed() {
    shouldShortPress = false;
    if (props.onLongPress && moved === false) {
      props.onLongPress();
    }
  }

  function onTouchStart(e: any) {
    shouldShortPress = true;
    moved = false;
    startTimeout();
    if (typeof props.onTouchStart === 'function') {
      props.onTouchStart(e);
    }
  }

  function onTouchEnd(e: any) {
    cancelTimeout();
    if (props.onPress && shouldShortPress && moved === false) {
      props.onPress();
    }
    if (typeof props.onTouchEnd === 'function') {
      props.onTouchEnd(e);
    }
  }

  function onTouchCancel(e: any) {
    cancelTimeout();
    if (typeof props.onTouchCancel === 'function') {
      props.onTouchCancel(e);
    }
  }

  function onTouchMove(e: any) {
    moved = true;
    if (typeof props.onTouchMove === 'function') {
      props.onTouchMove(e);
    }
  }

  const childrenProps = {
    onContextMenu: (e: any) => e.preventDefault(),
    onTouchStart,
    onTouchEnd,
    onTouchCancel,
    onTouchMove
  };

  return (
    <div className={classes} {...childrenProps}>
      {props.children}
    </div>
  );
};

AcPress.defaultProps = defaultProps;

export default AcPress;
