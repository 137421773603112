export enum AlignItems {
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  center = 'center',
  stretch = 'stretch',
  normal = 'normal',
  baseline = 'baseline'
}

export enum JustifyContent {
  flexStart = 'flex-start',
  flexEnd = 'flex-end',
  center = 'center',
  spaceAround = 'space-around',
  spaceBetween = 'space-between',
  normal = 'normal'
}

export enum FlexDirection {
  row = 'row',
  column = 'column',
  columnReverse = 'column-reverse',
  rowReverse = 'row-reverse'
}

export enum FlexDisplay {
  flex = 'flex',
  inlineFlex = 'inline-flex'
}
