import { SepModuleBusinessContextData } from '@ac/library-api';

import { getACPNamespace } from '../../../namespace/globalNamespace';

import { BusinessContextInContainer } from './businessContextInContainer';

export const getBusinessContextContainer = ():
  | BusinessContextInContainer
  | undefined => getACPNamespace().businessContext;

export const getCurrentGlobalBusinessContextData = ():
  | SepModuleBusinessContextData
  | undefined => getBusinessContextContainer()?.context;
