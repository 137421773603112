import classNames from 'classnames';
import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { AlignItems, Color, Icon, IconSize, JustifyContent } from '../../enums';
import {
  useComponentDidUpdateEffect,
  useElementSize,
  usePrevious
} from '../../hooks';
import { Childless } from '../../interfaces/componentProps';
import { AcBox } from '../box';
import { AcFlex } from '../flex';
import { AcIcon } from '../icon';
import { AcIconButton } from '../icon-button';
import './AcOverlayMessagePrompt.sass';
import { OverlayMessageState, OverlayMessageType } from './models';

interface OverlayMessageProps extends Childless {
  type: OverlayMessageType;
  messages: OverlayMessageState[];
  onRemove: (type: OverlayMessageType) => void;
}

const DISMISS_TIME: number = 5000;

const iconMap: Map<OverlayMessageType, Icon> = new Map()
  .set(OverlayMessageType.Success, Icon.Done)
  .set(OverlayMessageType.Warning, Icon.Todo)
  .set(OverlayMessageType.Error, Icon.Error);

const AcOverlayMessagePrompt: FC<OverlayMessageProps> = props => {
  const dismissTimeout = useRef<number | undefined>();
  const ref = useRef<HTMLDivElement>(null);
  const lastHeight = useElementSize(ref)[1]!;
  const [exitHeight, setExitHeight] = useState<number | undefined>();
  const [isCollapsing, setIsCollapsing] = useState<boolean>(false);
  const [dismiss, setDismiss] = useState<boolean>(false);
  const [containerHeight, setContainerHeight] = useState<string>('auto');

  const messageContainerRef = useRef<HTMLDivElement>(null);
  const previousMessagesAmount = usePrevious(props.messages.length);

  const className = classNames(
    'ac-overlay-message-prompt',
    `ac-overlay-message-prompt-${props.type}`,
    {
      'ac-overlay-message-prompt-dismissed': dismiss && exitHeight !== undefined
    },
    { 'ac-overlay-message-prompt-collapsed': isCollapsing }
  );
  const icon: Icon = iconMap.get(props.type) || Icon.Default;

  function onCancelClick() {
    setDismiss(true);
  }

  function onAnimationEnd(e: React.AnimationEvent) {
    const name = e.animationName;

    if (name === 'ac-overlay-message-prompt-collapse') {
      props.onRemove(props.type);
    } else if (name === 'ac-overlay-message-prompt-exit') {
      setIsCollapsing(true);
    } else if (!dismissTimeout.current) {
      setDismissTimeout();
    }
  }

  function setDismissTimeout() {
    window.clearTimeout(dismissTimeout.current);
    dismissTimeout.current = window.setTimeout(() => {
      setDismiss(true);
    }, DISMISS_TIME);
  }

  useEffect(() => {
    refreshContainerHeight();

    return () => {
      window.clearTimeout(dismissTimeout.current);
    };
  }, []);

  useComponentDidUpdateEffect(() => {
    // we need to reset timer only if new message show up
    if (previousMessagesAmount !== props.messages.length) {
      setDismissTimeout();
      refreshContainerHeight();
    }
  }, [props.messages]);

  function refreshContainerHeight() {
    return setContainerHeight(
      messageContainerRef.current
        ? `${messageContainerRef.current.clientHeight}px`
        : 'auto'
    );
  }

  if (dismissTimeout.current && dismiss) {
    window.clearTimeout(dismissTimeout.current);
  }

  if (dismiss && exitHeight === undefined) {
    setExitHeight(lastHeight);
  }

  return (
    <div
      ref={ref}
      className={className}
      onAnimationEnd={onAnimationEnd}
      style={{ height: exitHeight }}
    >
      <div
        className="ac-overlay-message-prompt-wrap"
        style={{ height: containerHeight }}
      >
        <div ref={messageContainerRef}>
          <AcFlex
            className="ac-overlay-message-prompt-top"
            alignItems={AlignItems.flexStart}
          >
            <AcFlex
              className="ac-overlay-message-prompt-icon"
              justifyContent={JustifyContent.center}
              alignItems={AlignItems.center}
            >
              <AcIcon icon={icon} size={IconSize.Medium} />
            </AcFlex>
            <AcBox className="ac-overlay-message-prompt-children" grow={true}>
              {props.messages.map((message: OverlayMessageState) => {
                return <div key={message.id}>{message.node}</div>;
              })}
            </AcBox>
            <AcFlex
              className="ac-overlay-message-prompt-close"
              justifyContent={JustifyContent.center}
              alignItems={AlignItems.center}
            >
              <AcIconButton
                icon={Icon.Cancel}
                size={IconSize.Medium}
                color={Color.Black}
                onClick={onCancelClick}
              />
            </AcFlex>
          </AcFlex>
        </div>
        <div className="ac-overlay-message-prompt-bottom" />
      </div>
    </div>
  );
};

export default memo(AcOverlayMessagePrompt);
