import classNames from 'classnames';
import React, { FC, memo, useContext } from 'react';
import { AlignItems, Color, Icon } from '../../../enums';
import { getTestSelectorAttribute } from '../../../utils';
import { AcFlex } from '../../flex';
import { AcIcon } from '../../icon';
import {
  AcAccordionContext,
  AcAccordionContextData
} from '../components/AcAccordionContext';
import { AcAccordionsElementProps } from '../index';

const AcAccordionHeader: FC<AcAccordionsElementProps> = (
  props: AcAccordionsElementProps
) => {
  if (!props.relatedWithItemId) {
    throw new Error(
      `${AcAccordionHeader.displayName}: relatedWithItemId property should be passed by AcAccordionItem`
    );
  }

  const context: AcAccordionContextData = useContext(AcAccordionContext)!;
  const classes = classNames('ac-accordion-header', props.className);

  const icon = context.isItemExpanded(props.relatedWithItemId)
    ? Icon.ActionUp
    : Icon.ActionDown;

  function toggleExpanded() {
    context.toggleExpanded(props.relatedWithItemId!);
  }

  return (
    <div
      className={classes}
      onClick={toggleExpanded}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <AcFlex alignItems={AlignItems.flexStart}>
        <div className="header-content">{props.children}</div>
        {!context.isItemDisabled(props.relatedWithItemId) && (
          <AcIcon
            icon={icon}
            color={Color.PrimaryDark}
            className="header-icon"
          />
        )}
      </AcFlex>
    </div>
  );
};

export default memo(AcAccordionHeader);
