import classNames from 'classnames';
import React, { FC, HTMLAttributes, memo, PropsWithChildren } from 'react';
import { AlignItems, FlexDirection } from '../../enums';
import { Testable } from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import { AcFlex } from '../flex';
import './AcLayout.sass';

export interface AcLayoutProps
  extends HTMLAttributes<HTMLDivElement>,
    Testable {}

const AcLayout: FC<PropsWithChildren<AcLayoutProps>> = ({
  className: classNameProp,
  testSelector,
  children,
  ...divProps
}) => {
  const className = classNames('ac-layout', classNameProp);

  return (
    <AcFlex
      {...divProps}
      direction={FlexDirection.column}
      alignItems={AlignItems.stretch}
      className={className}
      {...getTestSelectorAttribute(testSelector)}
    >
      {children}
    </AcFlex>
  );
};

export default memo(AcLayout);
