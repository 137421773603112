import './AcBadge.sass';

import React, { CSSProperties, FC, memo, useRef } from 'react';
import { Color, Icon, TextSize, TextWeight } from '../../enums';
import {
  Childless,
  Clickable,
  Styleable
} from '../../interfaces/componentProps';
import {
  getBackgroundColorClass,
  getBorderColorClass,
  getColorClass,
  getTestSelectorAttribute,
  isColorEnum
} from '../../utils';

import classNames from 'classnames';
import { BadgeTheme } from 'src/enums/badgeTheme';
import hexToHsl from 'src/utils/hexToHsl';
import hslToHex from 'src/utils/hslToHex';
import useDarkBackground from '../../hooks/useDarkBackground';
import { AcIcon } from '../icon';
import { AcRipple } from '../ripple';
import { AcText } from '../text';
import { AcBadgeProps } from './acBadgeProps';

export enum BadgeSize {
  sm = 'sm',
  md = 'md'
}

// todo Loading and pressed, possible UX error because they look the same

export interface AcBasicBadgeProps
  extends Childless,
    Styleable,
    Clickable,
    AcBadgeProps {
  backgroundColor?: Color | string;
  borderColor?: Color | string;
  onRemove?: () => void;
  fit?: boolean;
  badgeTextSize?: TextSize;
  theme?: BadgeTheme;
  size?: BadgeSize;
}

const defaultProps: AcBasicBadgeProps = {
  badgeText: '',
  backgroundColor: Color.PrimaryDark,
  badgeTextSize: TextSize.Main2,
  theme: BadgeTheme.Default,
  size: BadgeSize.md
};

const AcBadge: FC<AcBasicBadgeProps> = (props: AcBasicBadgeProps) => {
  const isBackgroundColorEnum = isColorEnum(props.backgroundColor);
  const isBorderColorEnum = isColorEnum(props.borderColor);
  const removeRef = useRef<HTMLDivElement>(null);
  const tileRef = useRef<HTMLDivElement>(null);
  const isBackgroundDark = useDarkBackground(tileRef, [props.backgroundColor]);
  const removeIconColor = isBackgroundDark ? Color.Gray5 : Color.PrimaryDark;
  const className = classNames(
    'ac-badge',
    { 'ac-badge-removable': !!props.onRemove },
    { 'ac-badge-uppercase-tile': props.tileUppercase },
    { 'ac-badge-uppercase-label': props.labelUppercase },
    { 'ac-badge-clickable': !!props.onClick },
    props.className
  );
  const tileClassName = classNames(
    'ac-badge-tile',
    `ac-badge-tile-${props.size}`,
    { 'ac-badge-size-fit': !!props.fit },
    isBackgroundColorEnum &&
      getBackgroundColorClass(props.backgroundColor as Color),
    isBorderColorEnum || props.borderColor?.includes('#')
      ? getBorderColorClass(props.borderColor as Color)
      : 'ac-badge-borderless',
    getColorClass(isBackgroundDark ? Color.White : Color.Black)
  );

  const customColor = (color: string) => {
    return color.charAt(0) === '#' ? props.backgroundColor : `#${color}`;
  };

  const badgeThemeBackground = (color: string) => {
    if (props.theme === BadgeTheme.Default) {
      return customColor(color);
    } else {
      const hsl = hexToHsl(color);
      hsl.l = 95;

      return hslToHex(hsl);
    }
  };

  const tileStyle: CSSProperties = {
    backgroundColor:
      !isBackgroundColorEnum && props.backgroundColor
        ? badgeThemeBackground(props.backgroundColor)
        : undefined,
    borderColor:
      !isBorderColorEnum && props.borderColor
        ? customColor(props.borderColor)
        : undefined
  };

  function onRemoveClick() {
    if (props.onRemove) {
      props.onRemove();
    }
  }

  function onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const isRemoveClick = removeRef.current
      ? removeRef.current.contains(event.target as HTMLDivElement)
      : false;
    if (props.onClick && !isRemoveClick) {
      props.onClick();
    }
  }

  return (
    <div
      className={className}
      style={props.style}
      onClick={onClick}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <div className={tileClassName} ref={tileRef} style={tileStyle}>
        <div className="ac-badge-tile-text">
          <AcText
            size={props.badgeTextSize}
            weight={TextWeight.Semibold}
            ellipsis={true}
            {...getTestSelectorAttribute(props.testSelector, 'badgeText')}
          >
            {props.badgeText}
          </AcText>
        </div>
        {props.onRemove && (
          <div
            className="ac-badge-icon"
            ref={removeRef}
            onClick={onRemoveClick}
            {...getTestSelectorAttribute(props.testSelector, 'remove')}
          >
            <AcRipple className="ac-button-background" color={removeIconColor}>
              <AcIcon icon={Icon.Cancel} color={removeIconColor} />
            </AcRipple>
          </div>
        )}
      </div>
      {props.label && (
        <AcText
          className="ac-badge-label"
          size={TextSize.Main1}
          {...getTestSelectorAttribute(props.testSelector, 'labelText')}
        >
          {props.label}
        </AcText>
      )}
    </div>
  );
};

AcBadge.defaultProps = defaultProps;
export default memo(AcBadge);
