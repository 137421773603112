import classNames from 'classnames';
import React, { FC, HTMLAttributes, memo } from 'react';
import { Testable } from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import './AcTileShell.sass';

export interface AcTileShellProps
  extends HTMLAttributes<HTMLDivElement>,
    Testable {}

/* tslint:disable:jsx-no-lambda */
const AcTileShell: FC<AcTileShellProps> = ({
  children,
  className: classNameProp,
  testSelector,
  ...divProps
}) => {
  const className: string = classNames('ac-tile-shell', classNameProp);

  return (
    <div
      {...divProps}
      className={className}
      {...getTestSelectorAttribute(testSelector)}
    >
      {children}
    </div>
  );
};

export default memo(AcTileShell);
