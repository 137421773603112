import classNames from 'classnames';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import useBodyClass from '../../hooks/useBodyClass';
import {
  ChildRequired,
  Styleable,
  Testable
} from '../../interfaces/componentProps';
import { combineStyle, getTestSelectorAttribute } from '../../utils';
import './AcModal.sass';
import { AcModalContext } from './acModalContext';
import { AcModalPattern } from './acModalPattern';

export interface AcModalProps extends ChildRequired, Styleable, Testable {
  isOpen: boolean;
  pattern?: AcModalPattern;
  onClose: () => void;
}

const defaultProps = {
  isOpen: false,
  pattern: AcModalPattern.Normal
};

const AcModal: FC<AcModalProps> = (props: AcModalProps) => {
  useBodyClass('ac-modal-global-open', !props.isOpen);
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
  const [isBackdropVisible, setIsBackdropVisible] = useState<boolean>(false);
  const [dialogHeightRatio, setDialogHeightRatio] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);
  const dialogRef = useRef<HTMLDivElement>(null);
  const isAnythingVisible: boolean = isDialogVisible || isBackdropVisible;

  useEffect(() => {
    addEventListener('click', onWindowClick);

    return () => {
      removeEventListener('click', onWindowClick);
    };
  }, []);

  function onWindowClick(e: MouseEvent): void {
    const target = e.target as HTMLElement;
    if (containerRef.current && target.contains(containerRef.current)) {
      props.onClose();
    }
  }

  const onBackdropAnimationEnd = useCallback(() => {
    if (!props.isOpen) {
      setIsBackdropVisible(false);
    }
  }, [props.isOpen]);

  const onDialogAnimationEnd = useCallback(() => {
    if (!props.isOpen) {
      setIsDialogVisible(false);
    }
  }, [props.isOpen]);

  useLayoutEffect(() => {
    if (props.isOpen) {
      setIsDialogVisible(true);
      setIsBackdropVisible(true);
    }
  }, [props.isOpen]);

  // set height only on opening or closing, prevents changing speed mid animation
  useLayoutEffect(() => {
    if (dialogRef.current) {
      setDialogHeightRatio(
        (dialogRef.current
          ? dialogRef.current.offsetHeight
          : window.innerHeight) / window.innerHeight
      );
    }
  }, [isDialogVisible, dialogRef.current]);

  const className = classNames(
    'ac-modal',
    { 'ac-modal-open': props.isOpen },
    `ac-modal-${props.pattern}-pattern`,
    props.className
  );

  const style = combineStyle(props, {
    '--ac-modal-dialog-height-ratio': dialogHeightRatio
  });

  return isAnythingVisible
    ? ReactDOM.createPortal(
        <AcModalContext.Provider
          value={{ onClose: props.onClose, pattern: props.pattern! }}
        >
          <div
            className={className}
            style={style}
            {...getTestSelectorAttribute(props.testSelector)}
          >
            {isBackdropVisible && (
              <div
                className="ac-modal-backdrop"
                onAnimationEnd={onBackdropAnimationEnd}
              />
            )}
            {isDialogVisible && (
              <div
                className="ac-modal-container"
                tabIndex={-1}
                aria-hidden="true"
                ref={containerRef}
              >
                <div
                  className="ac-modal-dialog"
                  role="dialog"
                  aria-modal="true"
                  onAnimationEnd={onDialogAnimationEnd}
                  ref={dialogRef}
                >
                  {props.children}
                </div>
              </div>
            )}
          </div>
        </AcModalContext.Provider>,
        document.body
      )
    : null;
};

AcModal.defaultProps = defaultProps;

export default memo(AcModal);
