import { acConfig } from '@ac/library-utils/dist/declarations';

import { isLocalhost } from '../environment';

import { AUTHENTICATION_LINKS } from './links.config';

export const getLoginUrl = (): string => {
  return isLocalhost
    ? `${window.location.origin}${AUTHENTICATION_LINKS.LOGIN}`
    : `${acConfig.containerFrontendUrl}${AUTHENTICATION_LINKS.LOGIN}`;
};
