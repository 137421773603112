import {
  BusinessContextUnitIdentifier,
  SepModuleBusinessContextData,
  SepModuleBusinessContextLoader,
  tryAdjustContextToUnitIdentifier,
} from '@ac/library-api';

import {
  getBusinessContextContainer,
  getCurrentGlobalBusinessContextData,
} from './getBusinessContextData';

export async function getCurrentGlobalOrLoadBusinessContextData(
  requiredUnit: BusinessContextUnitIdentifier
): Promise<SepModuleBusinessContextData> {
  const currentGlobal = getCurrentGlobalBusinessContextData();
  const requiredGlobal =
    currentGlobal &&
    tryAdjustContextToUnitIdentifier(currentGlobal, requiredUnit);

  if (requiredGlobal) {
    return currentGlobal;
  }

  const loadedData =
    await (getBusinessContextContainer()?.loadBusinessContextData(
      requiredUnit
    ) ?? new SepModuleBusinessContextLoader().load(requiredUnit));

  return loadedData;
}
