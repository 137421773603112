import { Color } from '../enums';
import { RgbColor } from '../interfaces/rgbColor';

export function getColorClass(color: Color): string {
  return `ac-color-${color}`;
}

export function getBackgroundColorClass(color: Color): string {
  return `ac-background-color-${color}`;
}

export function isColorEnum(color: Color | string | undefined): color is Color {
  return Object.values(Color).includes(color as Color);
}

export function getBorderColorClass(color: Color): string {
  return `ac-border-color-${color}`;
}

export function isColorDark(color: RgbColor): boolean {
  const luminance = (0.299 * color.red + 0.587 * color.green + 0.114 * color.blue) / 255;

  return luminance < 0.5;
}

export function getFullHexColor(hexColor: string): string {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

  return hexColor
    .replace(shorthandRegex, (_, r, g, b) => `#${r + r + g + g + b + b}`)
    .toUpperCase();
}

export function rgbStringColorToRgb(rgbString?: string | null): RgbColor | null {
  const result = /rgba?\((\d+),\W*(\d+),\W*(\d+)/i.exec(rgbString || '');

  return result ? {
    red: parseInt(result[1], 10),
    green: parseInt(result[2], 10),
    blue: parseInt(result[3], 10)
  } : null;
}

export function hexColorToRgb(hexColor: string): RgbColor | null {
  const fullHex = getFullHexColor(hexColor);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(fullHex);

  return result ? {
    red: parseInt(result[1], 16),
    green: parseInt(result[2], 16),
    blue: parseInt(result[3], 16)
  } : null;
}
