import React, { FC, memo } from 'react';
import { AlignItems, Color, Icon, JustifyContent, TextSize } from '../../enums';
import { Childless, Testable } from '../../interfaces/componentProps';
import { formatTestSelector } from '../../utils';
import { AcFlex } from '../flex';
import { AcIconButton } from '../icon-button';
import { AcText } from '../text';
import './AcPaginator.sass';

export interface AcPaginatorProps extends Childless, Testable {
  pageNumber: number;
  pageSize: number;
  totalCount: number;
  onPageRequest: (pageNumber: number) => void;
  pageTextFactory: (current: number, total: number) => string;
  itemsTextFactory: (count: number) => string;
}

const AcPaginator: FC<AcPaginatorProps> = (props: AcPaginatorProps) => {
  const lastPageNumber: number = Math.max(
    1,
    Math.ceil(props.totalCount / props.pageSize)
  );
  const pageText = props.pageTextFactory(props.pageNumber, lastPageNumber);
  const itemsText = props.itemsTextFactory(props.totalCount);

  const prevDisabled: boolean = props.pageNumber <= 1;
  const nextDisabled: boolean = props.pageNumber >= lastPageNumber;

  function request(n: number) {
    props.onPageRequest(n);
  }

  /* tslint:disable:jsx-no-lambda */
  return (
    <AcFlex
      justifyContent={JustifyContent.spaceBetween}
      alignItems={AlignItems.center}
      className="ac-paginator"
    >
      <div className="ac-paginator-icons-wrapper">
        <AcIconButton
          icon={Icon.LastLeft}
          disabled={prevDisabled}
          onClick={() => request(1)}
          testSelector={formatTestSelector(props.testSelector, 'firstPage')}
          color={Color.PrimaryDark}
        />
        <AcIconButton
          icon={Icon.ActionLeft}
          disabled={prevDisabled}
          onClick={() => request(props.pageNumber - 1)}
          testSelector={formatTestSelector(props.testSelector, 'previousPage')}
          color={Color.PrimaryDark}
        />
      </div>
      <div className="ac-paginator-text-wrapper">
        <AcText
          size={TextSize.Main1}
          equalLineHeight={true}
          className="ac-paginator-text"
        >
          {pageText}
        </AcText>
        {/* tslint:disable-next-line:jsx-use-translation-function */}
        &nbsp;
        <AcText
          size={TextSize.Main1}
          equalLineHeight={true}
          color={Color.Gray1}
          className="ac-paginator-text"
        >
          {itemsText}
        </AcText>
      </div>
      <div className="ac-paginator-icons-wrapper">
        <AcIconButton
          icon={Icon.ActionRight}
          disabled={nextDisabled}
          onClick={() => request(props.pageNumber + 1)}
          testSelector={formatTestSelector(props.testSelector, 'nextPage')}
          color={Color.PrimaryDark}
        />
        <AcIconButton
          icon={Icon.LastRight}
          disabled={nextDisabled}
          onClick={() => request(lastPageNumber)}
          testSelector={formatTestSelector(props.testSelector, 'lastPage')}
          color={Color.PrimaryDark}
        />
      </div>
    </AcFlex>
  );
};

export default memo(AcPaginator);
