import React from '../../../../../../wc';
import { PropsWithChildren } from '../../../../../reactTransition/propsWithChildren';

import { SinglePropertyWorkContext } from './context';
import { useCreateSinglePropertyWorkContextStore } from './store';

/**@deprecated Please use PropertyBusinessContextProvider*/
export const SinglePropertyWorkContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useCreateSinglePropertyWorkContextStore();

  return (
    <SinglePropertyWorkContext.Provider value={store}>
      {props.children}
    </SinglePropertyWorkContext.Provider>
  );
};
