import React, { useEffect } from 'react';

import { useDefinedFrontendCommunicationContext } from '../../communication';
import { PropsWithChildren } from '../../reactTransition/propsWithChildren';
import { useViewportContext } from '../store';

import { viewportResizedEventCreator } from './event';
import { getCurrentViewBreakpoint } from '.';

export const ResponsiveViewport = (props: PropsWithChildren): JSX.Element => {
  const { setViewBreakpoint } = useViewportContext();
  const { eventBus } = useDefinedFrontendCommunicationContext();

  useEffect(() => {
    window.addEventListener('resize', handleViewSizeChange);
    const unsubscribeResizeEvent = eventBus.subscribe(
      viewportResizedEventCreator,
      handleViewSizeChange
    );

    handleViewSizeChange();

    return () => {
      window.removeEventListener('resize', handleViewSizeChange);
      unsubscribeResizeEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventBus]);

  const handleViewSizeChange = () =>
    setViewBreakpoint(getCurrentViewBreakpoint());

  return <>{props.children}</>;
};
