import classNames from 'classnames';
import React, { FC, memo, ReactNode, useState } from 'react';
import { Icon } from '../../enums';
import { Clickable } from '../../interfaces/componentProps';
import { AcIconButton } from '../icon-button';
import AcFieldBase from './ac-field-component/AcFieldBase';
import {
  AcFieldProps,
  AcFieldTemplateNodes
} from './ac-field-component/AcFieldProps';
import { AcFieldTypes } from './ac-field-component/type';

interface AcFieldPasswordProps extends AcFieldProps, Clickable {
  icon?: Icon;
}

const AcFieldPassword: FC<AcFieldPasswordProps> = (
  props: AcFieldPasswordProps
) => {
  const [fieldType, setFieldType] = useState<AcFieldTypes>(
    AcFieldTypes.Password
  );
  const [iconType, setIconType] = useState<Icon>(Icon.View);
  const classes: string = classNames('ac-field-password', props.className);

  function toggleType(): void {
    if (fieldType === AcFieldTypes.Password) {
      setFieldType(AcFieldTypes.Text);
      setIconType(Icon.Hide);
    } else {
      setFieldType(AcFieldTypes.Password);
      setIconType(Icon.View);
    }
  }

  function passwordFieldTemplate(
    templateNodes: AcFieldTemplateNodes
  ): ReactNode {
    return (
      <>
        <div className="ac-field-header">
          {templateNodes.labelNode}
          {templateNodes.counterNode}
        </div>
        <div className="ac-field-background">
          {templateNodes.inputNode}
          <AcIconButton
            icon={iconType}
            disabled={props.disabled || props.readonly}
            onClick={toggleType}
          />
        </div>
        {templateNodes.validationMessageNode}
      </>
    );
  }

  return (
    <AcFieldBase
      {...props}
      type={fieldType}
      className={classes}
      testSelector={props.testSelector}
      template={passwordFieldTemplate}
    />
  );
};

export default memo(AcFieldPassword);
