import {
  mapLongDateFormat,
  mapShortDateFormat,
  PropertyContext,
  TimeFormat,
} from '@ac/library-api';

import { DEFAULT_DATE_TIME_FORMATS } from './defaultDateTimeFormats';
import { PropertyDateTimeFormatSettings } from './propertyDateTimeFormatSettings';

export const prepareDateTimeSettings = (
  businessContext: PropertyContext
): PropertyDateTimeFormatSettings => {
  const { shortDateFormat, longDateFormat, timeFormatCode, timeZoneCode } =
    businessContext.details;

  return {
    shortDateFormat: mapShortDateFormat(shortDateFormat),
    longDateFormat: mapLongDateFormat(longDateFormat),
    timeFormat:
      timeFormatCode === TimeFormat.H12
        ? DEFAULT_DATE_TIME_FORMATS.time12h
        : DEFAULT_DATE_TIME_FORMATS.time24h,
    timeFormatCode,
    timeZoneCode,
  };
};
