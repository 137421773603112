import { Fragment, useEffect } from 'react';

import { SepModuleBusinessContextConverter } from '@ac/library-api';

import { FullPageSpinner } from '../../../../../components';
import React from '../../../../../wc';
import { PropsWithChildren } from '../../../../reactTransition/propsWithChildren';
import { getCurrentGlobalOrLoadBusinessContextData } from '../../base';
import { useTenantBusinessContext } from '../store';

interface Props {
  tenantId: string;
  customLoadingView?: JSX.Element;
}

export const ExactTenantBusinessContextLoader = (
  props: PropsWithChildren<Props>
): JSX.Element => {
  const { state, setBusinessContext } = useTenantBusinessContext();

  useEffect(() => {
    const initializeContext = async () => {
      const contextData = await getCurrentGlobalOrLoadBusinessContextData({
        tenantId: props.tenantId,
      });

      const businessContext =
        new SepModuleBusinessContextConverter().buildTenantContext(contextData);

      setBusinessContext(businessContext);
    };

    void initializeContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tenantId]);

  return state.businessContext ? (
    <Fragment>{props.children}</Fragment>
  ) : (
    props.customLoadingView ?? <FullPageSpinner isVisible={true} />
  );
};
