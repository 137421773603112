import classNames from 'classnames';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  memo,
  PropsWithChildren
} from 'react';
import { AcBodyProps } from '../layout/Body/AcBody';
import './AcFormGroup.sass';

const AcFormGroup: ForwardRefRenderFunction<
  HTMLDivElement,
  PropsWithChildren<AcBodyProps>
> = ({ children, className: classNameProp, ...divProps }) => {
  const className = classNames('ac-form-group', classNameProp);

  return (
    <div {...divProps} className={className}>
      {children}
    </div>
  );
};

const forward = forwardRef(AcFormGroup);
export default memo(forward);
