import { getGlobalACPNamespace } from '../../../../../globalAcpNamespace';
import {
  PublicComponents,
  PublicComponentsInitialisation,
} from '../../component/configurePublicComponents';

interface ACPEmberNamespace<PublicModuleComponents extends PublicComponents> {
  initializePublicComponents: PublicComponentsInitialisation<PublicModuleComponents>;
  applicationName: string;
}

interface ACPNamespaceWithEmber<
  PublicModuleComponents extends PublicComponents
> {
  ember?: ACPEmberNamespace<PublicModuleComponents>;
}

export const getAcpNamespaceWithEmber = <
  PublicModuleComponents extends PublicComponents
>(): ACPNamespaceWithEmber<PublicModuleComponents> => getGlobalACPNamespace();
