export function initializeIOSZoomBlock() {
  document.addEventListener('touchmove', blockTouchEvent, {
    passive: false,
    capture: true
  });
  document.addEventListener('touchstart', blockTouchEvent, {
    passive: false,
    capture: true
  });
}

function blockTouchEvent(e: TouchEvent & { scale: number | undefined }) {
  if (e.scale !== undefined && e.scale !== 1) {
    e.preventDefault();
    e.stopImmediatePropagation();
    e.stopPropagation();
  }
}
