import { Testable } from '../interfaces/componentProps';

const ATTRIBUTE_NAME: string = 'data-test-selector';

export function formatTestSelector(
  ...parts: Array<string | undefined>
): string {
  return parts.filter(Boolean).join('-');
}

export function getTestSelectorAttribute(...parts: Array<string | undefined>) {
  const filtered = parts.filter(Boolean);

  return filtered.length
    ? { [ATTRIBUTE_NAME]: formatTestSelector(...filtered) }
    : {};
}

export function getTestSelectorProp(
  ...parts: Array<string | undefined>
): Testable {
  const filtered = parts.filter(Boolean);

  return filtered.length
    ? { testSelector: formatTestSelector(...filtered) }
    : {};
}
