import { useEffect } from 'react';

import { clearHistoryInstance } from '@ac/library-utils/dist/utils';
import {
  addModuleClass,
  removeModuleClass,
} from '@ac/library-utils/dist/utils/micro-frontends';

import React from '../../../wc';
import { FrontendCommunicationContextProvider } from '../../communication';
import { MetricsTrackersInitializer } from '../../developerTools/metrics/MetricsTrackersInitializer';
import {
  MainViewportLayoutWithBreadcrumbs,
  ResponsiveViewport,
  ViewportContextProvider,
} from '../../layout';
import { PropsWithChildren } from '../../reactTransition/propsWithChildren';

export interface ShijiEnterprisePlatformModuleProps {
  moduleClass: string;
}

export const ShijiEnterprisePlatformModule = (
  props: PropsWithChildren<ShijiEnterprisePlatformModuleProps>
): JSX.Element => {
  useEffect(() => {
    addModuleClass(props.moduleClass);

    return () => {
      removeModuleClass(props.moduleClass);
      clearHistoryInstance();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FrontendCommunicationContextProvider>
      <MetricsTrackersInitializer>
        <ViewportContextProvider>
          <ResponsiveViewport>
            <MainViewportLayoutWithBreadcrumbs>
              {props.children}
            </MainViewportLayoutWithBreadcrumbs>
          </ResponsiveViewport>
        </ViewportContextProvider>
      </MetricsTrackersInitializer>
    </FrontendCommunicationContextProvider>
  );
};
