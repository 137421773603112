import classNames from 'classnames';
import React, { FC, memo, RefObject, useEffect, useRef, useState } from 'react';
import { FieldState } from '../../enums';
import usePressed from '../../hooks/usePressed';
import {
  Changeable,
  Childless,
  Contractable,
  Disableable,
  Focusable,
  MoveableLabel,
  Nameable,
  Selectable,
  Styleable,
  Testable,
  Themeable
} from '../../interfaces/componentProps';
import {
  getFieldStateClass,
  getLabelLocationClass,
  getTestSelectorAttribute,
  getThemeClass,
  randomString
} from '../../utils';
import './AcSwitch.sass';

export interface AcSwitchProps
  extends Changeable<boolean>,
    Focusable,
    Styleable,
    Childless,
    Disableable,
    Nameable,
    Themeable,
    Contractable,
    MoveableLabel,
    Selectable,
    Testable {
  state?: boolean;
  label?: string;
}

const defaultProps: AcSwitchProps = {
  state: false,
  disabled: false
};

const AcSwitch: FC<AcSwitchProps> = (props: AcSwitchProps) => {
  const [id, setId] = useState<string>();
  const ref = useRef<HTMLLabelElement>(null);
  const pressed = usePressed(ref as RefObject<HTMLElement>);

  useEffect(() => setId(props.name || randomString()), [props.name]);

  const togglePrefix = 'ac-toggle-input';
  const inputRef = useRef<HTMLInputElement>(null);
  const componentClasses = classNames(
    'ac-switch',
    props.contractedTouchArea && 'ac-switch-contracted',
    togglePrefix,
    getThemeClass(props, togglePrefix),
    getFieldStateClass(props.selected, FieldState.Selected, togglePrefix),
    getLabelLocationClass(props, togglePrefix),
    getFieldStateClass(pressed, FieldState.Pressed, togglePrefix),
    props.className
  );

  function onChange() {
    if (inputRef.current) {
      const newState: boolean = inputRef.current.checked;
      inputRef.current.checked = !!props.state;
      if (props.onChange) {
        props.onChange(newState);
      }
    }
  }

  return (
    <label
      tabIndex={props.disabled ? -1 : 0}
      className={componentClasses}
      style={props.style}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      htmlFor={id}
      ref={ref}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <input
        className="ac-toggle-input-element"
        tabIndex={-1}
        name={props.name}
        id={id}
        ref={inputRef}
        type="checkbox"
        disabled={props.disabled}
        onChange={onChange}
        checked={props.state}
        {...getTestSelectorAttribute(props.testSelector, 'input')}
      />
      <div className="ac-toggle-input-area">
        <div className="ac-toggle-input-focus-circle" />
        <div className="ac-toggle-input-background" />
        <div className="ac-toggle-input-indicator" />
      </div>
      {!!props.label && (
        <span className="ac-toggle-input-label">{props.label}</span>
      )}
    </label>
  );
};

AcSwitch.defaultProps = defaultProps;
export default memo(AcSwitch);
