import { Fragment, useEffect } from 'react';

import { LoginService } from '@ac/library-utils/dist/services';

import { FullPageSpinner } from '../../../../../components';
import React from '../../../../../wc';
import { setUpLibraryApiForModule } from '../../../../api';
import { PropsWithChildren } from '../../../../reactTransition/propsWithChildren';
import { getCurrentTenantId } from '../../tenant';
import { prepareDateTimeSettings } from '../dateTimeFormats';
import { getCurrentGlobalOrLoadPropertyContext } from '../getCurrentGlobalOrLoadPropertyContext';
import { usePropertyBusinessContext } from '../store';

import { ensurePropertyIdForCurrentUser } from './ensurePropertyIdForCurrentUser';
import { registerPropertyBusinessContextUnitChangeHandler } from './registerPropertyBusinessContextUnitChangeHandler';

const configureWorkInProperty = (): void => {
  setUpLibraryApiForModule();
};

interface Props {
  customLoadingView?: JSX.Element;
}

export const GlobalPropertyBusinessContextLoader = (
  props: PropsWithChildren<Props>
): JSX.Element => {
  const { state, setBusinessContext, setPropertyDateTimeFormatSettings } =
    usePropertyBusinessContext();

  useEffect(() => {
    if (!LoginService.isLoggedIn()) {
      throw new Error('Please login to use load property business context');
    }

    const initializeContext = async () => {
      const propertyId = await ensurePropertyIdForCurrentUser();
      const tenantId = getCurrentTenantId();

      if (!tenantId) {
        throw new Error(
          'Cannot create Property Business Context without known tenant ID'
        );
      }

      const businessContext = await getCurrentGlobalOrLoadPropertyContext({
        propertyId,
        tenantId,
      });

      setBusinessContext(businessContext);
      setPropertyDateTimeFormatSettings(
        prepareDateTimeSettings(businessContext)
      );

      configureWorkInProperty();
      registerPropertyBusinessContextUnitChangeHandler(setBusinessContext);
    };

    void initializeContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state.businessContext ? (
    <Fragment>{props.children}</Fragment>
  ) : (
    props.customLoadingView ?? <FullPageSpinner isVisible={true} />
  );
};
