import { Fragment, useEffect } from 'react';

import { SepModuleBusinessContextConverter } from '@ac/library-api';

import { FullPageSpinner } from '../../../../../components';
import React from '../../../../../wc';
import { PropsWithChildren } from '../../../../reactTransition/propsWithChildren';
import { getCurrentGlobalOrLoadBusinessContextData } from '../../base';
import { useTenantBusinessContext } from '../store';

import { getCurrentTenantId } from './getCurrentTenantId';
import { registerTenantBusinessContextUnitChangeHandler } from './registerTenantBusinessContextUnitChangeHandler';

export const GlobalTenantBusinessContextLoader = (
  props: PropsWithChildren
): JSX.Element => {
  const { state, setBusinessContext } = useTenantBusinessContext();

  useEffect(() => {
    const initializeContext = async () => {
      const currentTenantId = getCurrentTenantId();

      if (!currentTenantId) {
        throw new Error(
          'Cannot initialize TenantBusinessContext without known TenantId'
        );
      }

      const contextData = await getCurrentGlobalOrLoadBusinessContextData({
        tenantId: currentTenantId,
      });

      const businessContext =
        new SepModuleBusinessContextConverter().buildTenantContext(contextData);

      setBusinessContext(businessContext);
      registerTenantBusinessContextUnitChangeHandler(setBusinessContext);
    };

    void initializeContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state.businessContext ? (
    <Fragment>{props.children}</Fragment>
  ) : (
    <FullPageSpinner isVisible={true} />
  );
};
