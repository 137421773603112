import classNames from 'classnames';
import React, { FC, memo, RefObject, useRef } from 'react';
import { Color, FieldState, Icon, IconSize } from '../../enums';
import usePressed from '../../hooks/usePressed';
import {
  Childless,
  Clickable,
  Disableable,
  Nameable,
  Selectable,
  Styleable,
  Testable
} from '../../interfaces/componentProps';
import {
  getColorClass,
  getFieldStateClass,
  getTestSelectorAttribute
} from '../../utils';
import { AcIcon } from '../icon';
import { AcSpinner } from '../spinner';
import './AcIconButton.sass';

export interface AcIconButtonProps
  extends Childless,
    Styleable,
    Clickable,
    Disableable,
    Nameable,
    Selectable,
    Testable {
  icon: Icon;
  color?: Color;
  loading?: boolean;
  size?: IconSize;
}

const defaultProps: AcIconButtonProps = {
  icon: Icon.Check,
  loading: false,
  size: IconSize.Standard
};

const AcIconButton: FC<AcIconButtonProps> = (props: AcIconButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  const pressed = usePressed(ref as RefObject<HTMLElement>);
  const className: string = classNames(
    'ac-icon-button',
    { 'ac-icon-button-loading': props.loading },
    getFieldStateClass(props.selected, FieldState.Selected, 'ac-icon-button'),
    getFieldStateClass(pressed, FieldState.Pressed, 'ac-icon-button'),
    props.className
  );
  const iconClassName = props.color ? getColorClass(props.color) : undefined;

  return (
    <button
      name={props.name}
      className={className}
      style={props.style}
      disabled={props.disabled || props.loading}
      onClick={props.onClick}
      ref={ref}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <AcIcon
        icon={props.icon}
        className={iconClassName}
        size={props.size}
        testSelector={`${props.testSelector}-icon`}
      />
      <div className="ac-icon-button-spinner-container">
        <AcSpinner testSelector={`${props.testSelector}-spinner`} />
      </div>
    </button>
  );
};

AcIconButton.defaultProps = defaultProps;
export default memo(AcIconButton);
