import classNames from 'classnames';
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  Ref,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import TetherComponent from 'react-tether';
import AcMobileContext from '../../config/AcMobileContext';
import { Childless, Testable } from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import './AcButtonDropdown.sass';

export enum ButtonDropdownPopoverAlign {
  Left = 'left',
  Right = 'right'
}

export interface AcButtonDropdownProps extends Childless, Testable {
  button: (ref: Ref<HTMLElement>) => ReactNode;
  popover: (ref: Ref<HTMLElement>) => ReactNode;
  popoverAlign?: ButtonDropdownPopoverAlign;
  closeOnOutsideClick?: boolean;
  closeOnPopoverClick?: boolean;
}

const defaultProps: AcButtonDropdownProps = {
  button: () => null,
  popover: () => null,
  popoverAlign: ButtonDropdownPopoverAlign.Right,
  closeOnOutsideClick: true,
  closeOnPopoverClick: true
};

interface AcButtonDropdownRefs {
  button?: RefObject<HTMLElement>;
  popover?: RefObject<HTMLElement>;
}

function isTargetInRef(
  target: HTMLElement,
  ref?: RefObject<HTMLElement>
): boolean {
  return !!ref && !!ref.current && ref.current.contains(target);
}

const AcButtonDropdown: FC<PropsWithChildren<
  AcButtonDropdownProps
>> = props => {
  const [open, setOpen] = useState<boolean>(false);
  const [refs] = useState<AcButtonDropdownRefs>({});

  const className = classNames(
    'ac-button-dropdown-container',
    open && 'ac-button-dropdown-opened'
  );

  const onOutsideTouch = useCallback(() => {
    if (props.closeOnOutsideClick) {
      setOpen(false);
    }
  }, [props.closeOnOutsideClick]);

  const onButtonTap = useCallback(() => {
    setOpen(currentOpen => !currentOpen);
  }, []);

  const onPopoverTap = useCallback(() => {
    if (props.closeOnPopoverClick) {
      setOpen(false);
    }
  }, [props.closeOnPopoverClick]);

  const onWindowTouchStart = useCallback(
    (e: TouchEvent) => {
      const target = e.target as HTMLElement;
      if (
        !isTargetInRef(target, refs.button) &&
        !isTargetInRef(target, refs.popover)
      ) {
        onOutsideTouch();
      }
    },
    [onOutsideTouch]
  );

  const onWindowClick = useCallback(
    (e: TouchEvent) => {
      const target = e.target as HTMLElement;
      if (isTargetInRef(target, refs.button)) {
        onButtonTap();
      } else if (isTargetInRef(target, refs.popover)) {
        onPopoverTap();
      }
    },
    [onButtonTap, onPopoverTap]
  );

  useEffect(() => {
    window.addEventListener('touchstart', onWindowTouchStart, {
      passive: false
    });
    window.addEventListener('mousedown', onWindowTouchStart, {
      passive: false
    });
    window.addEventListener('click', onWindowClick, { passive: false });

    return () => {
      window.removeEventListener('touchstart', onWindowTouchStart);
      window.removeEventListener('mousedown', onWindowTouchStart);
      window.removeEventListener('click', onWindowClick);
    };
  }, [onWindowTouchStart, onWindowClick]);

  const constraints = useMemo(
    () => [
      {
        to: 'scrollParent',
        attachment: 'together'
      }
    ],
    []
  );

  const renderTarget = useCallback(
    (ref: RefObject<HTMLElement>) => {
      refs.button = ref;

      return (
        <div
          className={className}
          {...getTestSelectorAttribute(props.testSelector)}
        >
          {props.button(ref)}
        </div>
      );
    },
    [props.button, props.testSelector, className]
  );

  const renderElement = useCallback(
    (ref: RefObject<HTMLElement>) => {
      refs.popover = ref;

      return open ? props.popover(ref) : () => <></>;
    },
    [open, props.popover]
  );

  const rootElement = useContext(AcMobileContext)!.rootElement as HTMLElement;

  return (
    <TetherComponent
      attachment={`top ${props.popoverAlign}`}
      targetAttachment={`bottom ${props.popoverAlign}`}
      renderElementTo={rootElement}
      constraints={constraints}
      renderTarget={renderTarget}
      renderElement={renderElement}
    />
  );
};

AcButtonDropdown.defaultProps = defaultProps;
export default AcButtonDropdown;
