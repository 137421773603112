import { AcSelectValue } from '../components/select';

export interface AcTimePickerOption {
  value: string;
}
export const TIME_PICKER_OPTIONS = {
  am: 'AM',
  pm: 'PM',
  minuteSymbol: 'M',
  hourSymbol: 'H',
  defaultMinute: '00',
  defaultHour12: '12',
  defaultHour24: '00',
  periodSymbol: 'T'
};

export const getStringFromTimePickerValue = (
  hour: number,
  minutes: number,
  is12Hours?: boolean
): string => {
  const output = `${hour < 10 ? 0 : ''}${hour}:${
    minutes === 0 ? TIME_PICKER_OPTIONS.defaultMinute : minutes
  }`;
  if (is12Hours && output.startsWith(TIME_PICKER_OPTIONS.defaultHour24)) {
    return TIME_PICKER_OPTIONS.defaultHour12 + output.substring(2);
  }

  return output;
};

export const generateTimePickerValues = (
  hours: 12 | 24
): AcTimePickerOption[] => {
  if (hours === 12) {
    const amSuggestions = new Array(hours * 4).fill(null).map((_, index) => ({
      value: `${getStringFromTimePickerValue(
        Math.floor(index / 4),
        (index % 4) * 15,
        true
      )} ${TIME_PICKER_OPTIONS.am}`
    }));
    const pmSuggestions = new Array(hours * 4).fill(null).map((_, index) => ({
      value: `${getStringFromTimePickerValue(
        Math.floor(index / 4),
        (index % 4) * 15,
        true
      )} ${TIME_PICKER_OPTIONS.pm}`
    }));

    return amSuggestions?.concat(pmSuggestions);
  }

  return new Array(hours * 4).fill(null).map((_, index) => ({
    value: getStringFromTimePickerValue(Math.floor(index / 4), (index % 4) * 15)
  }));
};

const convertTo24Hour = (timeString: string): string => {
  const isPM = timeString.includes(TIME_PICKER_OPTIONS.pm);
  const timeParts = timeString
    .replace(TIME_PICKER_OPTIONS.am, '')
    .replace(TIME_PICKER_OPTIONS.pm, '')
    .split(':');

  let hour = Number(timeParts[0]);
  const minutes = Number(timeParts[1]);

  if (isPM && hour !== 12) {
    hour += 12;
  } else if (!isPM && hour === 12) {
    hour = 0;
  }

  const formattedHour = hour.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHour}:${formattedMinutes}`;
};

export const getTimePickerOptions = (hours: 12 | 24): AcSelectValue[] => {
  return generateTimePickerValues(hours).map(option => ({
    itemLabel: option.value,
    value: convertTo24Hour(option.value)
  }));
};
