import classNames from 'classnames';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  memo,
  PropsWithChildren
} from 'react';
import { AcFlex } from '../flex';
import { AcBodyProps } from '../layout/Body/AcBody';
import './AcFormRow.sass';

const AcFormRow: ForwardRefRenderFunction<
  HTMLDivElement,
  PropsWithChildren<AcBodyProps>
> = ({ children, className: classNameProp, ...divProps }) => {
  const className = classNames('ac-form-row', classNameProp);

  return (
    <AcFlex {...divProps} className={className}>
      {children}
    </AcFlex>
  );
};

const forward = forwardRef(AcFormRow);
export default memo(forward);
