import classNames from 'classnames';
import React, { FC, memo, useRef } from 'react';
import { Color, Icon, IconSize, IndicatorSize } from '../../enums';
import useDarkBackground from '../../hooks/useDarkBackground';
import { Childless, Clickable, Styleable, Testable } from '../../interfaces/componentProps';
import { combineStyle, getBackgroundColorClass, getColorClass, isColorEnum } from '../../utils';
import { AcIcon } from '../icon';
import './AcIndicator.sass';

export interface AcIndicatorProps
  extends Childless,
    Styleable,
    Testable,
    Clickable {
  backgroundColor?: Color | string;
  icon?: Icon;
  size?: IndicatorSize;
}

const defaultProps: AcIndicatorProps = {
  backgroundColor: Color.Warning,
  icon: Icon.Info,
  size: IndicatorSize.Standard
};

const AcIndicator: FC<AcIndicatorProps> = (props: AcIndicatorProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const usesEnum = isColorEnum(props.backgroundColor);
  const backgroundClass = usesEnum
    ? getBackgroundColorClass(props.backgroundColor as Color)
    : null;
  const backgroundColor = !usesEnum ? (props.backgroundColor as string) : null;

  const isBackgroundDark = useDarkBackground(ref, [props.backgroundColor]);
  const iconColor = isBackgroundDark ? Color.Gray5 : Color.PrimaryDark;
  const className = classNames(
    'ac-indicator',
    backgroundClass,
    getColorClass(iconColor),
    props.className,
    `ac-indicator-size-${props.size}`
  );

  const style = combineStyle(props, {
    backgroundColor
  });

  const iconSize =
    props.size === IndicatorSize.Standard ? IconSize.Small : IconSize.Tiny;

  return (
    <div ref={ref} className={className} style={style} onClick={props.onClick}>
      <AcIcon
        icon={props.icon!}
        size={iconSize}
        testSelector={props.testSelector}
      />
    </div>
  );
};

AcIndicator.defaultProps = defaultProps;
export default memo(AcIndicator);
