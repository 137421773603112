/* eslint-disable no-console */
import { uuidv4 } from '../../utils';
import { CommunicationEvent } from './event';

type EventSubscriber<TPayload> = (payload: TPayload) => void;
type EventSubscribers = EventSubscriber<unknown>[];
type EventSubscribersMap = Map<string, EventSubscriber<unknown>[]>;

type LogEntry = {
  [key: string]: unknown;
};

const STORAGE_SETTING_KEY = 'aboveCloud.eventBusLogger';

export class EventBusLogger {
  private instanceId = uuidv4();
  private isLoggingActivated: boolean = false;

  constructor() {
    this.isLoggingActivated =
      localStorage.getItem(STORAGE_SETTING_KEY) === 'true';
  }

  logSubscribe(
    eventType: string,
    collectionAfterChange: EventSubscribersMap
  ): void {
    this.logInConsoleGroup('New handler subscribed', eventType, {
      'Event Type': eventType,
      'Subscribers after change': collectionAfterChange,
    });
  }

  logUnsubscribe(
    eventType: string,
    collectionAfterChange: EventSubscribersMap
  ): void {
    this.logInConsoleGroup('Handler unsubscribed', eventType, {
      'Event Type': eventType,
      'Subscribers after change': collectionAfterChange,
    });
  }

  logDispatch(
    event: CommunicationEvent<unknown>,
    subscribers: EventSubscribers
  ): void {
    this.logInConsoleGroup('Event dispatched', event.type, {
      'Event Type': event.type,
      Payload: event.payload,
      'Target subscribers': subscribers,
    });
  }

  private logInConsoleGroup(
    groupTopic: string,
    eventType: string,
    entry: LogEntry
  ): void {
    if (!this.isLoggingActivated) {
      return;
    }

    console.groupCollapsed(
      `SEP Event Bus Logger - ${groupTopic} (${eventType})`
    );
    console.log(`Instance ID: ${this.instanceId}`);

    Object.entries(entry).forEach(([key, value]) => console.log(key, value));

    console.groupEnd();
  }
}
