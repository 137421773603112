import classNames from 'classnames';
import React, { FC } from 'react';
import { Color } from '../../enums';
import AcBadge from './AcBadge';
import './AcBadge.sass';
import { AcBadgeProps } from './acBadgeProps';

export enum InfoBadgeVariant {
  Gray = 'gray',
  Blue = 'blue'
}

interface BackgroundAndBorderColor {
  backgroundColor: Color;
  borderColor: Color;
}

const variantToColorsMap = new Map<InfoBadgeVariant, BackgroundAndBorderColor>()
  .set(InfoBadgeVariant.Blue, {
    backgroundColor: Color.InformationLight,
    borderColor: Color.InformationDark
  })
  .set(InfoBadgeVariant.Gray, {
    backgroundColor: Color.White,
    borderColor: Color.Gray4
  });

export interface AcInfoBadgeProps extends AcBadgeProps {
  variant?: InfoBadgeVariant;
}

const defaultProps: AcInfoBadgeProps = {
  badgeText: '',
  variant: InfoBadgeVariant.Blue
};

const AcInfoBadge: FC<AcInfoBadgeProps> = (props: AcInfoBadgeProps) => {

  const variantColors = variantToColorsMap.get(props.variant as InfoBadgeVariant);
  const className = classNames(
    'ac-info-badge',
    props.className
  );

  return (
    <AcBadge
      badgeText={props.badgeText}
      label={props.label}
      backgroundColor={variantColors && variantColors.backgroundColor}
      borderColor={variantColors && variantColors.borderColor}
      tileUppercase={props.tileUppercase}
      labelUppercase={props.labelUppercase}
      className={className}
      style={props.style}
      onClick={props.onClick}
      testSelector={props.testSelector}
    />
  );
};

AcInfoBadge.defaultProps = defaultProps;
export default AcInfoBadge;
