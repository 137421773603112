import {
  ConfigurationEntitiesAggregate,
  PropertyContext,
} from '@ac/library-api';

import {
  DEFAULT_DATE_TIME_FORMATS,
  PropertyDateTimeFormatSettings,
} from '../dateTimeFormats';

export interface PropertyBusinessContextState {
  businessContext: PropertyContext | undefined;
  configurationEntities: ConfigurationEntitiesAggregate;
  propertyDateTimeFormatSettings: PropertyDateTimeFormatSettings;
}

export const getDefaultPropertyBusinessContextState =
  (): PropertyBusinessContextState => ({
    businessContext: undefined,
    configurationEntities: {},
    propertyDateTimeFormatSettings: {
      longDateFormat: DEFAULT_DATE_TIME_FORMATS.longDate,
      shortDateFormat: DEFAULT_DATE_TIME_FORMATS.shortDate,
      timeFormat: DEFAULT_DATE_TIME_FORMATS.time24h,
      timeFormatCode: DEFAULT_DATE_TIME_FORMATS.timeFormatCode,
    },
  });
