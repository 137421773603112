import classNames from 'classnames';
import React, { FC, memo, useContext } from 'react';
import { Icon, IconSize, TextSize, TextWeight, Theme } from '../../enums';
import {
  Childless,
  Clickable,
  Disableable,
  Styleable,
  Testable,
  Themeable
} from '../../interfaces/componentProps';
import { Linkable } from '../../interfaces/componentProps/linkable';
import { formatTestSelector } from '../../utils';
import { AcButton, AcButtonPattern } from '../button';
import { AcButtonShape } from '../button/acButtonShape';
import { AcButtonSize } from '../button/acButtonSize';
import { AcIcon } from '../icon';
import AcText from '../text/AcText';
import { AcWrappedLink } from '../wrapped-link';
import './AcFabChild.sass';
import AcFloatingContext from './AcFabContext';

export interface AcFabChildButtonProps
  extends Styleable,
    Childless,
    Themeable,
    Testable,
    Linkable,
    Disableable,
    Clickable {
  icon: Icon;
  text: string;
}

const defaultProps: Partial<AcFabChildButtonProps> = {
  theme: Theme.Dark
};

const AcFabChild: FC<AcFabChildButtonProps> = (
  props: AcFabChildButtonProps
) => {
  const acFloatingContext = useContext(AcFloatingContext)!;

  const prefix = 'ac-fab-child-button';
  const className: string = classNames(prefix, props.className);

  const onClick = () => {
    if (props.onClick) {
      acFloatingContext.closeExpandedItem(props.onClick);
    }
  };

  return (
    <div className={`${className}`}>
      <AcWrappedLink link={props.link}>
        <AcButton
          className={`${prefix} fab-button`}
          backgroundClassName="fab-button-child-background"
          shape={AcButtonShape.Rectangle}
          size={AcButtonSize.Medium}
          pattern={AcButtonPattern.Secondary}
          theme={props.theme}
          disabled={props.disabled || acFloatingContext.disableAllButtons}
          onClick={onClick}
          testSelector={props.testSelector}
        >
          <AcText
            size={TextSize.Main2}
            weight={TextWeight.Semibold}
            className="ac-spacing-right-md"
            testSelector={formatTestSelector(props.testSelector, 'text')}
          >
            {props.text}
          </AcText>
          {!!props.icon && (
            <AcIcon
              className="ac-spacing-right-s"
              icon={props.icon}
              size={IconSize.Small}
              testSelector={formatTestSelector(props.testSelector, 'icon')}
            />
          )}
        </AcButton>
      </AcWrappedLink>
    </div>
  );
};

AcFabChild.defaultProps = defaultProps;

export default memo(AcFabChild);
