import classNames from 'classnames';
import React, {
  CSSProperties,
  FC,
  memo,
  useContext,
  useEffect,
  useRef
} from 'react';
import { getTestSelectorAttribute } from '../../../utils';
import {
  AcAccordionContext,
  AcAccordionContextData
} from '../components/AcAccordionContext';
import { AcAccordionsElementProps } from '../index';

interface AcAccordionClampContentProps extends AcAccordionsElementProps {
  minVisibleLines?: number;
  maxVisibleLines?: number;
  lineHeight?: number;
}

const defaultProps: Partial<AcAccordionClampContentProps> = {
  minVisibleLines: 0,
  maxVisibleLines: 40,
  lineHeight: 1.5
};

const AcAccordionClampContent: FC<AcAccordionClampContentProps> = (
  props: AcAccordionClampContentProps
) => {
  if (!props.relatedWithItemId) {
    throw new Error(
      `${AcAccordionClampContent.displayName}: relatedWithItemId property should be passed by AcAccordionItem`
    );
  }

  const context: AcAccordionContextData = useContext(AcAccordionContext)!;

  const classes = classNames(props.className, 'ac-accordion-clamp-content', {
    'ac-accordion-ellipsis':
      context.isItemExpanded(props.relatedWithItemId) ||
      props.minVisibleLines! > 0
  });

  const offset =
    context.isItemExpanded(props.relatedWithItemId) ||
    props.minVisibleLines! > 0
      ? 1
      : 0;

  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const usedStyle: CSSProperties = {};
  usedStyle.lineHeight = `${props.lineHeight}em`;
  usedStyle.maxHeight = context.isItemExpanded(props.relatedWithItemId)
    ? `${props.maxVisibleLines! * props.lineHeight! + offset}em`
    : `${props.minVisibleLines! * props.lineHeight! + offset}em`;

  useEffect(() => {
    if (
      context.isRegistered(props.relatedWithItemId!) &&
      contentRef.current &&
      containerRef.current
    ) {
      const contentRects = contentRef.current.getClientRects()[0];
      const containerRects = containerRef.current.getClientRects()[0];

      if (
        contentRects &&
        containerRects &&
        containerRects.height >= contentRects.height
      ) {
        context.setDisableStatus(props.relatedWithItemId!, true);
      }
    }
  }, [
    containerRef.current,
    contentRef.current,
    context.isRegistered(props.relatedWithItemId!)
  ]);

  return (
    <div
      ref={containerRef}
      style={usedStyle}
      className={classes}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <div ref={contentRef} className="ac-accordion-content-text">
        {props.children}
      </div>
      <div className="fade" style={{ height: `${props.lineHeight}em` }} />
    </div>
  );
};

AcAccordionClampContent.defaultProps = defaultProps;

export default memo(AcAccordionClampContent);
