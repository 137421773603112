import classNames from 'classnames';
import React, { FC, memo, RefObject, useEffect, useState } from 'react';
import { Childless, Styleable, Testable } from '../../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../../utils';
import AcAnchorButton from '../button/AcAnchorButton';
import { AcAnchorItem } from '../interfaces/AcAnchorItem';
import { AcAnchorListState } from '../interfaces/AcAnchorsState';
import './AcAnchorNavigation.sass';

interface AcAnchorNavigationProps extends Styleable, Testable, Childless {
  items: AcAnchorListState;
  rootRef: RefObject<HTMLElement>;
}

// tslint:disable:jsx-no-lambda
const AcAnchorNavigation: FC<AcAnchorNavigationProps> = (
  props: AcAnchorNavigationProps
) => {
  const classes = classNames('ac-anchor-navigation', props.className);
  const itemList = Object.values(props.items);
  const [selected, setSelected] = useState<AcAnchorItem>();

  useEffect(() => {
    const selectedItem = itemList.find(item => item.entry?.isIntersecting);
    setSelected(selectedItem);
  }, [props.items]);

  return (
    <div
      className={classes}
      {...getTestSelectorAttribute(props.testSelector, 'navigation')}
    >
      <div className="ac-anchor-navigation-item-list">
        {itemList.map((item: AcAnchorItem) => {
          return (
            <AcAnchorButton
              to={item.to}
              label={item.label}
              rootRef={props.rootRef}
              key={`anchor-${item.to}`}
              selected={selected?.to === item.to}
              testSelector={`anchor-nav-item-${item.to}`}
            />
          );
        })}
      </div>
    </div>
  );
};

export default memo(AcAnchorNavigation);
