import React from '../../../../../wc';
import { PropsWithChildren } from '../../../../reactTransition/propsWithChildren';

import { TenantBusinessContext } from './context';
import { useCreateTenantBusinessContextStore } from './store';

export const TenantBusinessContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useCreateTenantBusinessContextStore();

  return (
    <TenantBusinessContext.Provider value={store}>
      {props.children}
    </TenantBusinessContext.Provider>
  );
};
