import { Fragment, useEffect } from 'react';

import { LoginService } from '@ac/library-utils/dist/services';

import { FullPageSpinner } from '../../../../../components';
import React from '../../../../../wc';
import { setUpLibraryApiForModule } from '../../../../api';
import { PropsWithChildren } from '../../../../reactTransition/propsWithChildren';
import { prepareDateTimeSettings } from '../../../businessContext/property/dateTimeFormats';
import { ensurePropertyIdForCurrentUser } from '../../../businessContext/property/globalProperty';
import { getCurrentTenantId } from '../../../businessContext/tenant';
import { getCurrentGlobalOrLoadPropertyBusinessContext } from '../getCurrentGlobalOrLoadPropertyBusinessContext';

import { registerDeprecatedPropertyBusinessContextUnitChangeHandler } from './registerPropertyBusinessContextUnitChangeHandler';
import { useSinglePropertyWorkContext } from './store';

const configureWorkInProperty = (): void => {
  setUpLibraryApiForModule();
};

interface SinglePropertyWorkContextLoaderProps {
  customLoadingView?: JSX.Element;
}

/**@deprecated Please use GlobalPropertyBusinessContextLoader (which requires PropertyBusinessContextProvider) */
export const SinglePropertyWorkContextLoader = (
  props: PropsWithChildren<SinglePropertyWorkContextLoaderProps>
): JSX.Element => {
  const { state, setBusinessContext, setPropertyDateTimeFormatSettings } =
    useSinglePropertyWorkContext();

  useEffect(() => {
    if (!LoginService.isLoggedIn()) {
      throw new Error('Please login to use load single property work context');
    }

    const initializeContext = async () => {
      const propertyId = await ensurePropertyIdForCurrentUser();
      const tenantId = getCurrentTenantId();

      if (!tenantId) {
        throw new Error(
          'Cannot create Single Property Business Context without known tenant ID'
        );
      }

      const businessContext =
        await getCurrentGlobalOrLoadPropertyBusinessContext({
          propertyId,
          tenantId,
        });

      setBusinessContext(businessContext);
      setPropertyDateTimeFormatSettings(
        prepareDateTimeSettings(businessContext.property)
      );

      configureWorkInProperty();
      registerDeprecatedPropertyBusinessContextUnitChangeHandler(
        setBusinessContext
      );
    };

    void initializeContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state.businessContext ? (
    <Fragment>{props.children}</Fragment>
  ) : (
    props.customLoadingView ?? <FullPageSpinner isVisible={true} />
  );
};
