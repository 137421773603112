import {
  CustomerContext,
  isTenantBusinessContextData,
  SepModuleBusinessContextConverter,
  SepModuleBusinessContextData,
} from '@ac/library-api';

import { getACPNamespace } from '../../../../namespace/globalNamespace';

export const registerTenantBusinessContextUnitChangeHandler = (
  handler: (context: CustomerContext) => void
): void => {
  const businessContext = getACPNamespace()?.businessContext;

  if (!businessContext) {
    return;
  }

  const currentOnUnitChange = businessContext.onUnitChanged;
  const newOnUnitChange = (updatedContext: SepModuleBusinessContextData) => {
    if (!isTenantBusinessContextData(updatedContext)) {
      return;
    }

    const newContext =
      new SepModuleBusinessContextConverter().buildTenantContext(
        updatedContext
      );

    handler(newContext);
  };

  businessContext.onUnitChanged = (context: SepModuleBusinessContextData) => {
    currentOnUnitChange?.(context);
    newOnUnitChange(context);
  };
};
