import React, { FC, memo, RefObject, useEffect } from 'react';
import { Childless, Clickable, Styleable, Testable } from '../../../interfaces/componentProps';
import { AcButton, AcButtonPattern } from '../../button';
import './AcAnchorButton.sass';

export interface AcAnchorBase {
  to: string;
  label: string;
}

interface AcAnchorProps
  extends AcAnchorBase,
    Styleable,
    Testable,
    Clickable,
    Childless {
  rootRef: RefObject<HTMLElement>;
  selected?: boolean;
}

const AcAnchorButton: FC<AcAnchorProps> = (props: AcAnchorProps) => {
  const pattern = props.selected
    ? AcButtonPattern.AnchorSelected
    : AcButtonPattern.Anchor;

  useEffect(() => {
    require('smoothscroll-polyfill').polyfill();
  });

  const smoothScroll = () => {
    const anchorElement = document.getElementById(props.to);
    const anchorsContainer = props.rootRef.current;

    if (anchorElement && anchorsContainer) {
      const offset = anchorsContainer.getBoundingClientRect().top;
      const anchorElementPosition =
        anchorElement.getBoundingClientRect().top +
        anchorsContainer.scrollTop -
        offset;

      anchorsContainer.scrollTo({
        top: Math.round(anchorElementPosition),
        behavior: 'smooth'
      });

      if (props.onClick) {
        props.onClick();
      }
    }
  };

  return (
    <AcButton
      pattern={pattern}
      style={props.style}
      onClick={smoothScroll}
      testSelector={props.testSelector}
    >
      {props.label}
    </AcButton>
  );
};

export default memo(AcAnchorButton);
