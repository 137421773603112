import { TextSize, TextStyle, TextWeight } from '../enums';

export function getFontSizeClass(size: TextSize): string {
  return `ac-font-size-${size}`;
}

export function getLineHeightClass(size: TextSize): string {
  return `ac-line-height-${size}`;
}

export function getEqualLineHeightClass(size: TextSize): string {
  return `ac-line-height-equal-${size}`;
}

export function getFontWeightClass(weight: TextWeight): string {
  return `ac-font-weight-${weight}`;
}

export function getFontStyleClass(fontStyle: TextStyle): string {
  return `ac-font-style-${fontStyle}`;
}
