/** To support React 18+ */
import { AppProps } from 'single-spa';
import * as React from 'react';
import singleSpaReact from 'single-spa-react';
import { isDefined } from '../../../../isDefined';
import {
  AppToRender,
  ComponentToRender,
  createModuleToRender,
} from '../../../rendering/shared';
import { WithoutKeys } from '../../../../../declarations';

type RenderType = 'createRoot' | 'hydrateRoot' | 'hydrate' | 'render';

type ReactRenderer = {
  [T in RenderType]?: unknown;
};

export interface WithReactRenderer {
  ReactDOMClient: ReactRenderer;
}

export type RenderableComponent<ComponentProps> =
  | React.ComponentClass<ComponentProps, any>
  | React.FunctionComponent<ComponentProps>;

// `StableReact` type added only to make sure that React 18 is passed in
// After removal of the legacy support (React 17), this type can be removed
type StableReact = typeof React & {
  useId: () => void;
};

export type RenderableComponentProps<Props> = Props extends RenderableComponent<
  infer ComponentProps
>
  ? ComponentProps
  : Props;

export type LoadRootComponent<
  ComponentProps,
  Component extends RenderableComponent<ComponentProps> = RenderableComponent<ComponentProps>
> = () => Promise<Component>;

interface SingleSpaReactProps<ComponentProps> extends WithReactRenderer {
  React: StableReact;
  domElementGetter: (props: ComponentProps) => HTMLElement;
}

export interface CreatePublicReactModuleParams<ModuleProps = {}>
  extends SingleSpaReactProps<ModuleProps> {
  initApp?: () => Promise<any>;
  rootComponent: RenderableComponent<ModuleProps>;
}

export const createPublicReactModule = <ModuleProps>(
  params: CreatePublicReactModuleParams<ModuleProps>
): AppToRender => {
  const lifeCycles = createModuleToRender(
    singleSpaReact({
      // casting needed because some modules use ComponentClass (not FunctionComponent)
      // what causes TS error according to the required AppProps (AppProps are delivered by single-spa
      // so they should not be required to be used by consumer if it does not need it).
      // Modules do not use and do not need AppProps so it is not worth to break our API to deliver these Props
      // to consumer.
      ...(params as CreatePublicReactModuleParams<ModuleProps & AppProps>),
    })
  );

  return {
    ...lifeCycles,
    bootstrap: [...lifeCycles.bootstrap, params.initApp].filter(isDefined),
  };
};

export interface PublicComponentWrapper {
  tag: keyof React.ReactHTML;
  moduleClass: string;
}

/**
 * @deprecated use CreatePublicReactComponentParamsV2
 */
export interface CreatePublicReactComponentParams<ComponentProps = {}>
  extends Pick<
    SingleSpaReactProps<ComponentProps>,
    'React' | 'ReactDOMClient'
  > {
  initModuleForComponentUsage?: () => Promise<any>;
  wrapWith: PublicComponentWrapper;
  name?: string;
  loadRootComponent: LoadRootComponent<ComponentProps>;
}

export type CreatePublicReactComponentParamsV2<ComponentProps> = WithoutKeys<
  CreatePublicReactComponentParams,
  'loadRootComponent'
> & { rootComponent: RenderableComponent<ComponentProps> };

/**
 * @deprecated use createPublicComponent from @ac/react-infrastructure
 *
 * Stable version:
 * import { createPublicComponent } from '@ac/react-infrastructure/dist/features/microfrontends/publicComponents/react/stable'
 *
 */
export const createPublicReactComponent = <ComponentProps extends {} = {}>(
  params: CreatePublicReactComponentParams<ComponentProps>
): ComponentToRender<ComponentProps> => {
  const {
    ReactDOMClient,
    loadRootComponent: loadRootComponentParam,
    ...restParams
  } = params;

  const loadRootComponent = createLoadRootComponentFunction(
    loadRootComponentParam,
    params.wrapWith
  );

  const componentLifeCycles = createModuleToRender(
    singleSpaReact({
      ...restParams,
      ReactDOMClient,
      loadRootComponent,
    })
  );

  return {
    ...componentLifeCycles,
    bootstrap: [
      ...componentLifeCycles.bootstrap,
      params.initModuleForComponentUsage,
    ].filter(isDefined),
    name: params.name,
  };
};

export const createPublicReactComponentV2 = <ComponentProps extends {} = {}>(
  params: CreatePublicReactComponentParamsV2<ComponentProps>
): ComponentToRender<ComponentProps> => {
  const { ReactDOMClient, rootComponent, ...restParams } = params;

  const componentLifeCycles = createModuleToRender<ComponentProps>(
    singleSpaReact({
      ...restParams,
      ReactDOMClient,
      rootComponent: createRootComponent(rootComponent, params.wrapWith),
    })
  );

  return {
    ...componentLifeCycles,
    bootstrap: [
      ...componentLifeCycles.bootstrap,
      params.initModuleForComponentUsage,
    ].filter(isDefined),
    name: params.name,
  };
};

export const createRootComponent = <
  ComponentProps extends Record<string, unknown>
>(
  Component: RenderableComponent<ComponentProps>,
  wrapWith: PublicComponentWrapper
): RenderableComponent<ComponentProps & AppProps> => {
  const FunctionalWrapper = ((wrappedComponentProps: ComponentProps) => {
    return React.createElement(
      wrapWith.tag,
      { className: wrapWith.moduleClass },
      React.createElement(Component, wrappedComponentProps)
    );
  }) as React.ComponentType<ComponentProps & AppProps>;

  return FunctionalWrapper;
};

export const createLoadRootComponentFunction =
  <ComponentProps extends {} = {}>(
    loadRootComponent: LoadRootComponent<ComponentProps>,
    wrapWith: PublicComponentWrapper
  ): LoadRootComponent<ComponentProps & AppProps> =>
  async () => {
    const Component = await loadRootComponent();

    return createRootComponent(Component, wrapWith);
  };
