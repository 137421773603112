import React, { FC, memo } from 'react';
import './AcSelect.sass';
import AcSelectBase from './components/AcSelectBase';
import { AcSelectGeneric } from './interfaces/AcSelectGeneric';
import { AcSelectValue } from './interfaces/AcSelectValue';

export interface AcSingleSelectProps extends AcSelectGeneric<string> {
  onChange(selectedItem: string): void;
}

const AcSelect: FC<AcSingleSelectProps> = (props: AcSingleSelectProps) => {
  function clearSelectedValue(): void {
      props.onChange('');
  }

  function getSelectedLabels(): string {
    const selectedValue: AcSelectValue | undefined = props.itemsList.find((item: AcSelectValue) => item.value === props.selectedItem);

    return selectedValue ? selectedValue.inputLabel || selectedValue.itemLabel : getUnlistedLabel();
  }

  function getUnlistedLabel(): string {
    return isSelectEmpty() ? '' : props.unlistedValueLabel || '';
  }

  function isSelectEmpty(): boolean {
    return !props.selectedItem;
  }

  function onUpdate(selectedItems: string[]): void {
    const newSelectedItem: string =
      selectedItems.length > 0 ? selectedItems[0] : '';

    props.onChange(newSelectedItem);
  }

  return (
    <AcSelectBase<string>
      {...props}
      clearSelectedValue={clearSelectedValue}
      getSelectedLabels={getSelectedLabels}
      isSelectEmpty={isSelectEmpty}
      placeholder={props.placeholder}
      onUpdate={onUpdate}
      multiply={false}
      translations={props.translations}
      testSelector={props.testSelector}
    />
  );
};

export default memo(AcSelect);
