import classNames from 'classnames';
import React from 'react';
import {
  ChildRequired,
  Styleable,
  Testable
} from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import './AcAnchorList.sass';
import { AcAnchorContext, AcAnchorContextData } from './AcAnchorListContext';
import { AcAnchorItem } from './interfaces/AcAnchorItem';
import { AcAnchorListState } from './interfaces/AcAnchorsState';
import AcAnchorNavigation from './navigation/AcAnchorNavigation';

interface AcAnchorListProps extends Styleable, ChildRequired, Testable {
  navClass?: string;
}

class AcAnchorList extends React.PureComponent<
  AcAnchorListProps,
  AcAnchorListState
> {
  public readonly state: AcAnchorListState = {};
  public listRef = React.createRef<HTMLDivElement>();

  private anchorContext: AcAnchorContextData = {
    items: this.state,
    rootRef: this.listRef,
    updateItem: (prop: string, item: AcAnchorItem): void => {
      this.setState({ [prop]: item });
    }
  };

  public render() {
    const classes = classNames('ac-anchor-list', this.props.className);

    return (
      <AcAnchorContext.Provider value={this.anchorContext}>
        <div
          className={classes}
          style={this.props.style}
          {...getTestSelectorAttribute(this.props.testSelector)}
        >
          <AcAnchorNavigation
            className={this.props.navClass}
            items={this.state}
            rootRef={this.listRef}
            testSelector={this.props.testSelector}
          />
          <div
            className="ac-anchor-content-list"
            ref={this.listRef}
            {...getTestSelectorAttribute(
              this.props.testSelector,
              'section-list'
            )}
          >
            {this.props.children}
          </div>
        </div>
      </AcAnchorContext.Provider>
    );
  }
}

export default AcAnchorList;
