import {
  PropertyContext,
  SepModuleBusinessContextConverter,
  SepModuleBusinessContextLoader,
} from '@ac/library-api';

import {
  getBusinessContextContainer,
  getCurrentGlobalBusinessContextData,
} from '../base';

interface PropertyContextId {
  tenantId: string;
  propertyId: string;
}

export async function getCurrentGlobalOrLoadPropertyContext({
  tenantId,
  propertyId,
}: PropertyContextId): Promise<PropertyContext> {
  const currentGlobal = getCurrentGlobalBusinessContextData();
  const requiredUnit = {
    tenantId,
    propertyId,
  };

  if (
    currentGlobal?.customer?.details.id === tenantId &&
    currentGlobal.property?.details.id === propertyId
  ) {
    return new SepModuleBusinessContextConverter().buildPropertyContext(
      currentGlobal
    );
  }

  const loadedData =
    await (getBusinessContextContainer()?.loadBusinessContextData(
      requiredUnit
    ) ?? new SepModuleBusinessContextLoader().load(requiredUnit));

  return new SepModuleBusinessContextConverter().buildPropertyContext(
    loadedData
  );
}
