import { HslColor } from 'src/interfaces/hslColor';

const hslToHex = (hsl: HslColor): string => {
  hsl.l /= 100;
  const a = (hsl.s * Math.min(hsl.l, 1 - hsl.l)) / 100;
  const f = (n: number) => {
    const k = (n + hsl.h / 30) % 12;
    const color = hsl.l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0');
  };

  return `#${f(0)}${f(8)}${f(4)}`;
};

export default hslToHex;