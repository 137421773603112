import { i18n as I18n, TFunction } from 'i18next';
import { getI18nextInitOptions } from '../initOptions';
import { HttpBackendOptions } from 'i18next-http-backend';
import { initPathResolver } from './pathResolver';
import { I18N_DEFAULT_NS } from '../constants';
import { I18nConfig } from './types';
import { isDefined } from '../../utils';

const getBackendOptions = (config: I18nConfig): HttpBackendOptions => {
  return {
    loadPath: initPathResolver(config),
    allowMultiLoading: false,
  };
};

const prepareNamespaceList = (i18Namespaces?: readonly string[] | string) => {
  const namespaces: string[] = (
    Array.isArray(i18Namespaces) ? i18Namespaces : [i18Namespaces]
  ).filter(isDefined);
  if (!namespaces.includes(I18N_DEFAULT_NS)) {
    namespaces.push(I18N_DEFAULT_NS);
  }

  return namespaces;
};

// cache promise to avoid multiple initialization in case of too fast repeated call of setUpI18next
let initPromise: Promise<unknown> | undefined;

export const setUpI18next = async (
  i18nApi: I18n,
  config: I18nConfig
): Promise<TFunction> => {
  const ns = prepareNamespaceList(config.initOptions?.ns);

  if (!i18nApi.isInitialized && !initPromise) {
    const initOptions = getI18nextInitOptions({
      ...(config.initOptions ?? {}),
      load: 'currentOnly',
      backend: getBackendOptions(config),
      defaultNS: I18N_DEFAULT_NS,
      fallbackNS: I18N_DEFAULT_NS,
      ns,
    });

    const { backend, initReactI18next, custom } = config.plugins;
    const plugins = [backend, initReactI18next, ...(custom || [])];

    plugins.forEach((plugin) => i18nApi.use(plugin));

    initPromise = i18nApi.init(initOptions);

    await initPromise;

    initPromise = undefined;

    return i18nApi.t;
  } else {
    if (initPromise) {
      await initPromise;
    }
    // this is safe to try load any namespace. I18n will skip already loaded namespaces.
    initPromise = i18nApi.loadNamespaces(ns);

    await initPromise;

    initPromise = undefined;

    return i18nApi.t;
  }
};
