export enum Icon {
  AcProject = 'ac-project',
  AccessHistory = 'access-history',
  Accompanying = 'accompanying',
  Accounting = 'accounting',
  AccountsReceivable = 'accounts-receivable',
  ActionBar = 'action-bar',
  ActionDown = 'action-down',
  ActionLeft = 'action-left',
  ActionMenu = 'action-menu',
  ActionRight = 'action-right',
  ActionUp = 'action-up',
  Activities = 'activities',
  AddNote = 'add-note',
  AddTask = 'add-task',
  AddressDetails = 'address-details',
  Adults = 'adults',
  Airport = 'airport',
  AirportIn = 'airport-in',
  AirportOut = 'airport-out',
  Alert = 'alert',
  AlternativeHotels = 'alternative-hotels',
  Archive = 'archive',
  Arr = 'arr',
  ArrDep = 'arr-dep',
  ArrowDown = 'arrow-down',
  ArrowLeft = 'arrow-left',
  ArrowRight = 'arrow-right',
  ArrowTop = 'arrow-top',
  Ascending = 'ascending',
  AtEmail = 'at-email',
  Attachment = 'attachment',
  Attendant = 'attendant',
  Attraction = 'attraction',
  AttributesGeneric = 'attributes-generic',
  Audit = 'audit',
  Authorization = 'authorization',
  AutoMerge = 'auto-merge',
  AutoSent = 'auto-sent',
  BatchChange = 'batch-change',
  Bell = 'bell',
  Billing = 'billing',
  Blacklist = 'blacklist',
  BlankPermission = 'blank-permission',
  Bold = 'bold',
  Bookmark = 'bookmark',
  BrokeLink = 'broke-link',
  BulletList = 'bullet-list',
  Calendar = 'calendar',
  CalendarCancel = 'calendar-cancel',
  CalendarCheck = 'calendar-check',
  CalendarPeriod = 'calendar-period',
  Cancel = 'cancel',
  CancelReservation = 'cancel-reservation',
  Cancellation = 'cancellation',
  Cancelled = 'cancelled',
  Cash = 'cash',
  Cashier = 'cashier',
  Cashiering = 'cashiering',
  Champaigne = 'champaigne',
  Check = 'check',
  CheckIn = 'check-in',
  CheckOut = 'check-out',
  Children = 'children',
  Chocolates = 'chocolates',
  Clean = 'clean',
  CleanOcc = 'clean-occ',
  CleanVac = 'clean-vac',
  Clock = 'clock',
  CloseUp = 'close-up',
  Code = 'code',
  CollapseCorners = 'collapse-corners',
  CollapseHorizontal = 'collapse-horizontal',
  CollapseVertical = 'collapse-vertical',
  ColorScheme = 'color-scheme',
  Commission = 'commission',
  CommunicationDetails = 'communication-details',
  Company = 'company',
  Compare = 'compare',
  Component = 'component',
  Concierge = 'concierge',
  ConectLink = 'conect-link',
  Conferences = 'conferences',
  ConfigCustomer = 'config-customer',
  ConfigProperty = 'config-property',
  ContactOnSite = 'contact-on-site',
  Contacts = 'contacts',
  ContinentalBfst = 'continental-bfst',
  CreateReservation = 'create-reservation',
  CreditCard = 'credit-card',
  Culture = 'culture',
  CurrentPrices = 'current-prices',
  CustomSection = 'custom-section',
  CustomerUser = 'customer-user',
  Dashboard = 'dashboard',
  Default = 'default',
  Definition = 'definition',
  Definitions = 'definitions',
  Delete = 'delete',
  Dep = 'dep',
  Deposit = 'deposit',
  Descending = 'descending',
  DetailsGeneric = 'details-generic',
  Dictionary = 'dictionary',
  DirectionArrows = 'direction-arrows',
  Disability = 'disability',
  DistributionChannel = 'distribution-channel',
  Done = 'done',
  DoorLockSystem = 'door-lock-system',
  Dot = 'dot',
  DowngradeRoom = 'downgrade-room',
  Download = 'download',
  Duplicate = 'duplicate',
  Edit = 'edit',
  EditNote = 'edit-note',
  Email = 'email',
  Engineering = 'engineering',
  EnglishBfst = 'english-bfst',
  Entities = 'entities',
  Eod = 'eod',
  Error = 'error',
  Exclamation = 'exclamation',
  Execute = 'execute',
  ExpandCorners = 'expand-corners',
  ExpandHorizontal = 'expand-horizontal',
  ExpandVertical = 'expand-vertical',
  Export = 'export',
  ExternalLink = 'external-link',
  Facebook = 'facebook',
  Fax = 'fax',
  Filter = 'filter',
  Finish = 'finish',
  FlexibleDate = 'flexible-date',
  FlipHorizontal = 'flip-horizontal',
  FlipVertical = 'flip-vertical',
  Flowers = 'flowers',
  FolioWindows = 'folio-windows',
  Food = 'food',
  Frontdesk = 'frontdesk',
  FsCombination = 'fs-combination',
  FsShareable = 'fs-shareable',
  FullHighView = 'full-high-view',
  FunctionSpace = 'function-space',
  GenerateSatement = 'generate-satement',
  Google = 'google',
  GreenService = 'green-service',
  Group = 'group',
  GueastMessages = 'gueast-messages',
  GuestLocator = 'guest-locator',
  GuestServiceStatus = 'guest-service-status',
  Hash = 'hash',
  Help = 'help',
  Hide = 'hide',
  HistoricalRsv = 'historical-rsv',
  Home = 'home',
  HotelFeatures = 'hotel-features',
  Housekeeping = 'housekeeping',
  Ids = 'ids',
  Image = 'image',
  InProgress = 'in-progress',
  Inactive = 'inactive',
  Info = 'info',
  Information = 'information',
  Inquiry = 'inquiry',
  InsertingUrl = 'inserting-url',
  Instruction = 'instruction',
  InterfaceCommands = 'interface-commands',
  Inventory = 'inventory',
  Italic = 'italic',
  LSidebarClose = 'l-sidebar-close',
  Language = 'language',
  LastLeft = 'last-left',
  LastRight = 'last-right',
  Left = 'left',
  LeftFilledStar = 'left-filled-star',
  LeftOutlineStar = 'left-outline-star',
  LegalOwner = 'legal-owner',
  LicensingPlan = 'licensing-plan',
  Limo = 'limo',
  LinkedRsv = 'linked-rsv',
  Linkedin = 'linkedin',
  Links = 'links',
  LinenChange = 'linen-change',
  ListView = 'list-view',
  Lock = 'lock',
  LockStatus = 'lock-status',
  Loyalty = 'loyalty',
  ManualMerge = 'manual-merge',
  MarketSegment = 'market-segment',
  Measurement = 'measurement',
  Membership = 'membership',
  Menu = 'menu',
  Messenger = 'messenger',
  Minus = 'minus',
  MinusStatus = 'minus-status',
  Miscellaneous = 'miscellaneous',
  Mobile = 'mobile',
  MoveTo = 'move-to',
  MultiselectZone = 'multiselect-zone',
  Newspaper = 'newspaper',
  Noisy = 'noisy',
  Notes = 'notes',
  NumberList = 'number-list',
  Off = 'off',
  On = 'on',
  OnlyThis = 'only-this',
  OpenDown = 'open-down',
  OpenInNewTab = 'open-in-new-tab',
  OrganizationalUnit = 'organizational-unit',
  Parameter = 'parameter',
  Pause = 'pause',
  Payment = 'payment',
  PerPax = 'per-pax',
  PerRoom = 'per-room',
  PersonalDetails = 'personal-details',
  Plus = 'plus',
  PlusStatus = 'plus-status',
  Position = 'position',
  PostingCharge = 'posting-charge',
  PostingDailyCharge = 'posting-daily-charge',
  Power = 'power',
  Preferences = 'preferences',
  Preset = 'preset',
  Pricing = 'pricing',
  Print = 'print',
  Privacy = 'privacy',
  Profile = 'profile',
  ProfileAdd = 'profile-add',
  ProfileDetails = 'profile-details',
  ProfileEdit = 'profile-edit',
  ProfileOutlined = 'profile-outlined',
  ProfileOwner = 'profile-owner',
  ProfileSelect = 'profile-select',
  ProfileSettings = 'profile-settings',
  Projection = 'projection',
  PropertyZone = 'property-zone',
  PurchaseElements = 'purchase-elements',
  Question = 'question',
  Queue = 'queue',
  RSidebarClose = 'r-sidebar-close',
  RSidebarOpen = 'r-sidebar-open',
  RateCode = 'rate-code',
  RateManager = 'rate-manager',
  Rates = 'rates',
  ReassignRoom = 'reassign-room',
  Redo = 'redo',
  RegistrationCard = 'registration-card',
  ReinstateReservation = 'reinstate-reservation',
  ReleaseRoom = 'release-room',
  Report = 'report',
  ReportDesigner = 'report-designer',
  Requests = 'requests',
  Reservations = 'reservations',
  ResizeDown = 'resize-down',
  ResizeUp = 'resize-up',
  RestItems = 'rest-items',
  Restart = 'restart',
  Restaurant = 'restaurant',
  Restriction = 'restriction',
  Resume = 'resume',
  Revenue = 'revenue',
  Right = 'right',
  RightFilledStar = 'right-filled-star',
  RightOutlineStar = 'right-outline-star',
  RingingBell = 'ringing-bell',
  RolePermission = 'role-permission',
  Room = 'room',
  RoomAssign = 'room-assign',
  RoomAttributes = 'room-attributes',
  RoomAvailability = 'room-availability',
  RoomHistory = 'room-history',
  RoomLayout = 'room-layout',
  RoomService = 'room-service',
  RoomType = 'room-type',
  RotateLeft = 'rotate-left',
  RotateRight = 'rotate-right',
  Routing = 'routing',
  RowOrder = 'row-order',
  RsvDetails = 'rsv-details',
  RsvTemplate = 'rsv-template',
  RulesGeneric = 'rules-generic',
  Save = 'save',
  Scan = 'scan',
  Search = 'search',
  Settings = 'settings',
  Settlement = 'settlement',
  Sharers = 'sharers',
  SingleselectZone = 'singleselect-zone',
  Skip = 'skip',
  Skype = 'skype',
  Social = 'social',
  Star = 'star',
  StarHalf = 'star-half',
  StarOutline = 'star-outline',
  StarSecHalf = 'star-sec-half',
  Start = 'start',
  Structure = 'structure',
  Subsidiary = 'subsidiary',
  SubsidiaryZone = 'subsidiary-zone',
  Suspend = 'suspend',
  Synchro = 'synchro',
  SystemUsers = 'system-users',
  Task = 'task',
  TaxCalculator = 'tax-calculator',
  Taxi = 'taxi',
  Telephone = 'telephone',
  TenantZone = 'tenant-zone',
  Test = 'test',
  TileView = 'tile-view',
  Today = 'today',
  Todo = 'todo',
  TollfreePhone = 'tollfree-phone',
  TourSeries = 'tour-series',
  Transfer = 'transfer',
  Transport = 'transport',
  Travel = 'travel',
  Twitter = 'twitter',
  TwoColumnView = 'two-column-view',
  Underline = 'underline',
  Undo = 'undo',
  Unlock = 'unlock',
  UpgradeRoom = 'upgrade-room',
  Upsell = 'upsell',
  View = 'view',
  ViewReservation = 'view-reservation',
  Vip = 'vip',
  Weechat = 'weechat',
  Whatsapp = 'whatsapp',
  Widgets = 'widgets',
  Www = 'www'
}
