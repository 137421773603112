import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { Color, Icon } from '../../enums';
import { Childless } from '../../interfaces/componentProps';
import { AcIcon } from '../icon';

export interface AcButtonContentProps extends Childless {
  text?: string | { string: string };
  icon?: Icon;
  iconColor?: Color;
}

const AcButtonContent: FC<AcButtonContentProps> = (
  props: AcButtonContentProps
) => {
  const text =
    props.text &&
    (typeof props.text === 'string' ? props.text : props.text.string);

  return (
    <>
      {!!props.icon && (
        <AcIcon
          className={classNames({ 'ac-spacing-right-sm': props.text })}
          icon={props.icon}
          color={props.iconColor}
        />
      )}
      {text}
    </>
  );
};

export default memo(AcButtonContent);
