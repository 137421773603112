import { useEffect } from 'react';

import { LoginService } from '@ac/library-utils/dist/services';

import { FullPageSpinner } from '../../components';
import React from '../../wc';
import { setUpLibraryApiForModule } from '../api';
import { useRoutingContext } from '../routing/storage/context';

import { checkIfUserIsAuthenticated } from './checkIfUserIsAuthenticated';
import { getLoginUrl } from './getLoginUrl';
import { useAuthenticationContext } from './store';

interface LoginContainerProps {
  customLoadingView?: JSX.Element;
}

export const LoginContainer = (props: LoginContainerProps): JSX.Element => {
  const { state: routingState } = useRoutingContext();
  const { setAuthenticationResult } = useAuthenticationContext();
  const loginUrl = getLoginUrl();
  const returnUrl =
    new URL(window.location.href).searchParams.get('returnUrl') || '';

  const proceedSuccessfulAuthentication = (): void => {
    routingState.routeChangeCallbacks.onAuthenticateRouteChange(returnUrl);

    const result = checkIfUserIsAuthenticated();

    setUpLibraryApiForModule();
    setAuthenticationResult(result);
  };

  const executeAuthorizationByLoginService = (): void => {
    LoginService.setRedirectUri(loginUrl);
    LoginService.setOnSuccessHandler(proceedSuccessfulAuthentication);

    LoginService.handleModuleCallBack({
      moduleLoginUrl: loginUrl,
      returnUrl,
    });
  };

  useEffect(() => {
    executeAuthorizationByLoginService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return props.customLoadingView ?? <FullPageSpinner isVisible={true} />;
};
