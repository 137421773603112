import {
  PropertyBusinessContext,
  SepModuleBusinessContextFactory,
  SepModuleBusinessContextLoader,
  tryAdjustContextToUnitIdentifier,
} from '@ac/library-api';

import {
  getBusinessContextContainer,
  getCurrentGlobalBusinessContextData,
} from '../../businessContext/base';

interface PropertyContextId {
  tenantId: string;
  propertyId: string;
}

/**@deprecated Please use getCurrentGlobalOrLoadPropertyContext*/
export async function getCurrentGlobalOrLoadPropertyBusinessContext({
  tenantId,
  propertyId,
}: PropertyContextId): Promise<PropertyBusinessContext> {
  const currentGlobal = getCurrentGlobalBusinessContextData();
  const requiredUnit = {
    tenantId,
    propertyId,
  };

  const requiredGlobal =
    currentGlobal &&
    tryAdjustContextToUnitIdentifier(currentGlobal, requiredUnit);

  if (requiredGlobal) {
    return new SepModuleBusinessContextFactory().buildPropertyContext(
      currentGlobal
    );
  }

  const loadedData =
    await (getBusinessContextContainer()?.loadBusinessContextData(
      requiredUnit
    ) ?? new SepModuleBusinessContextLoader().load(requiredUnit));

  return new SepModuleBusinessContextFactory().buildPropertyContext(loadedData);
}
