import { getGlobalACPNamespace as getAlreadyKnownGlobalACPNamespace } from '@ac/library-utils/dist/utils';

import { CommunicationRequiredByReactInfrastructure } from '../communication';
import { DeveloperToolsRequiredByReactInfrastructure } from '../developerTools';
import { BusinessContextInContainer } from '../featureSchema/businessContext';
import { UserLanguageInContainer } from '../multiLanguage/userLanguage';
import { ToastPresenterInContainer } from '../notifications/toast';
import { WebSockets } from '../websocket';

import { ContextHelpInContainer } from './contextHelp';

export type ContainerRequiredByReactInfrastructure = ContextHelpInContainer & {
  toast: ToastPresenterInContainer;
};

export type ApiRequiredByReactInfrastructure = {
  webSockets?: WebSockets;
};

export type ACPNamespaceRequiredByReactInfrastructure =
  UserLanguageInContainer & {
    container?: ContainerRequiredByReactInfrastructure;
    businessContext?: BusinessContextInContainer;
    api?: ApiRequiredByReactInfrastructure;
    developerTools?: DeveloperToolsRequiredByReactInfrastructure;
    communication?: CommunicationRequiredByReactInfrastructure;
  };

export const getContainer = ():
  | ContainerRequiredByReactInfrastructure
  | undefined => getACPNamespace().container;

export const getACPNamespace = (): ACPNamespaceRequiredByReactInfrastructure =>
  getAlreadyKnownGlobalACPNamespace();
