import { MutableRefObject, Ref } from 'react';
import { uid } from '../../utils';
import {
  NewOverlayMessage,
  NewTypedOverlayMessage,
  OverlayMessageState,
  OverlayMessageType
} from './models';

export function addNewOverlayMessageToArray(
  newMessage: OverlayMessageState,
  messages: OverlayMessageState[]
) {
  return [...messages, newMessage];
}

export function removeOverlayMessageFromArray(
  type: OverlayMessageType,
  messages: OverlayMessageState[]
) {
  return messages.filter(message => message.type !== type);
}

export function calculateOverlayMessageBottomOffset(
  refs?: Array<Ref<HTMLElement>>
): number {
  return (refs || []).reduce(
    (max: number, currentRef: MutableRefObject<HTMLElement>) => {
      const current = currentRef.current;
      const currentOffset: number = current
        ? window.innerHeight - current.getBoundingClientRect().top
        : 0;

      return Math.max(max, currentOffset);
    },
    0
  );
}

export function createOverlayMessageState(
  newTypedMessage: NewTypedOverlayMessage
): OverlayMessageState {
  return {
    id: newTypedMessage.id || uid(),
    node: newTypedMessage.node,
    type: newTypedMessage.type
  };
}

export function createNewTypedOverlayMessage(
  newMessage: NewOverlayMessage | string,
  type: OverlayMessageType
): NewTypedOverlayMessage {
  return {
    ...(typeof newMessage === 'string' ? { node: newMessage } : newMessage),
    type
  };
}
