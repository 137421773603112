import { CustomerContext } from '@ac/library-api';

export interface TenantBusinessContextState {
  businessContext: CustomerContext | undefined;
}

export const getDefaultTenantBusinessContextState =
  (): TenantBusinessContextState => ({
    businessContext: undefined,
  });
