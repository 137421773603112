import React, { FC, memo, PropsWithChildren, useRef, useState } from 'react';
import AcMobileContext, {
  AcMobileConfig,
  AcMobileContextData
} from './AcMobileContext';

interface AcMobileProviderProps {
  config: AcMobileConfig;
}

export const AcMobileProvider: FC<PropsWithChildren<AcMobileProviderProps>> = (
  props: PropsWithChildren<AcMobileProviderProps>
) => {
  const [currentModuleName, setCurrentModuleName] = useState<string>();
  const overlayNotificationsWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <AcMobileContext.Provider
      value={
        {
          ...props.config,
          currentModuleName,
          setCurrentModuleName,
          overlayNotificationsWrapperRef
        } as AcMobileContextData
      }
    >
      {props.children}
    </AcMobileContext.Provider>
  );
};

export default memo(AcMobileProvider);
