import React from '../../../wc';
import { PropsWithChildren } from '../../reactTransition/propsWithChildren';

import { FrontendCommunicationContext } from './context';
import { useCreateFrontendCommunicationStore } from './store';

export const FrontendCommunicationContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useCreateFrontendCommunicationStore();

  return (
    <FrontendCommunicationContext.Provider value={store}>
      {props.children}
    </FrontendCommunicationContext.Provider>
  );
};
