import classNames from 'classnames';
import React, { FC, memo, RefObject, useEffect, useRef, useState } from 'react';
import { FieldState } from '../../enums';
import useFocused from '../../hooks/useFocused';
import usePressed from '../../hooks/usePressed';
import {
  Changeable,
  Childless,
  Disableable,
  Focusable,
  Nameable,
  Readable,
  Requireable,
  Styleable,
  Testable,
  Validatable
} from '../../interfaces/componentProps';
import { getFieldStateClass, getTestSelectorAttribute, uid } from '../../utils';
import './AcTextarea.sass';

export interface AcFieldProps
  extends Childless,
    Styleable,
    Disableable,
    Nameable,
    Validatable,
    Requireable,
    Readable,
    Changeable<string>,
    Testable,
    Focusable {
  value?: string;
  label?: string;
  placeholder?: string;
  maxlength?: number;
}

const defaultProps: AcFieldProps = {
  value: '',
  disabled: false,
  required: false,
  readonly: false
};

/* tslint:disable:jsx-use-translation-function */
const AcTextarea: FC<AcFieldProps> = (props: AcFieldProps) => {
  const [id, setId] = useState<string>();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const prefix = 'ac-field';
  const focused = useFocused(inputRef as RefObject<HTMLElement>);
  const pressed = usePressed(inputRef as RefObject<HTMLElement>);

  const classes = classNames(
    prefix,
    'ac-field-textarea',
    'ac-field-default',
    props.className,
    getFieldStateClass(pressed, FieldState.Pressed, prefix),
    getFieldStateClass(focused, FieldState.Focused, prefix),
    getFieldStateClass(!!props.validation, FieldState.Invalid, prefix),
    getFieldStateClass(!!props.readonly, FieldState.Readonly, prefix),
    getFieldStateClass(!!props.disabled, FieldState.Disabled, prefix)
  );

  const labelClasses = classNames('ac-field-label', {
    required: !!props.required
  });

  function onChange() {
    if (inputRef.current) {
      const newState: string = inputRef.current.value;
      if (props.onChange) {
        props.onChange(newState);
      }
    }
  }

  useEffect(() => setId(props.name || uid()), [props.name]);

  return (
    <div
      className={classes}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <div className="ac-field-header">
        <label className={labelClasses} htmlFor={id}>
          {props.label}
        </label>
        {props.maxlength && (
          <span className="ac-field-counter">
            {props.value!.length}/{props.maxlength}
          </span>
        )}
      </div>
      <div className="ac-field-background">
        <textarea
          id={id}
          ref={inputRef}
          value={props.value}
          name={props.name}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onChange={onChange}
          readOnly={props.readonly}
          className="ac-field-input"
          maxLength={props.maxlength}
          {...getTestSelectorAttribute(props.testSelector, 'input')}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
        />
      </div>
      {!!props.validation && (
        <div className="ac-field-error-message">{props.validation}</div>
      )}
    </div>
  );
};

AcTextarea.defaultProps = defaultProps;
export default memo(AcTextarea);
