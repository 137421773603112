import { useState } from 'react';

import { EventBus } from '@ac/library-utils/dist/communication/event-bus';

import { getACPNamespace } from '../../namespace/globalNamespace';

import {
  FrontendCommunicationState,
  getDefaultFrontendCommunicationState,
} from './state';

export interface FrontendCommunicationStore {
  state: FrontendCommunicationState;
}

export const useCreateFrontendCommunicationStore =
  (): FrontendCommunicationStore => {
    const [state] = useState(
      getDefaultFrontendCommunicationState(
        getACPNamespace().communication?.eventBus ?? new EventBus()
      )
    );

    return {
      state,
    };
  };
