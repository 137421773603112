import classNames from 'classnames';
import React, {
  FC,
  FormEvent,
  memo,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Color, FieldState, Icon } from '../../enums';
import useFocused from '../../hooks/useFocused';
import usePressed from '../../hooks/usePressed';
import {
  Changeable,
  Childless,
  Disableable,
  Focusable,
  Nameable,
  Readable,
  Requireable,
  Selectable,
  Styleable,
  Testable,
  Validatable
} from '../../interfaces/componentProps';
import { getFieldStateClass, getTestSelectorAttribute, uid } from '../../utils';
import { dayjs } from '../../utils/dayjs-extended';
import { getTimePickerOptions } from '../../utils/timePickerUtils';
import AcIconButton from '../icon-button/AcIconButton';
import AcSelect from '../select/AcSelect';
import {
  AcFieldTypes,
  defaultOutputFormat,
  DISPLAY_TIME_FORMAT_12_HOURS
} from './ac-field-component/type';

export interface AcFieldTimeProps
  extends Childless,
    Styleable,
    Disableable,
    Focusable,
    Nameable,
    Validatable,
    Requireable,
    Readable,
    Changeable<string>,
    Selectable,
    Testable {
  value?: string;
  label?: string;
  placeholder?: string;
  format: string;

  onClick?(): void;
}

const AcFieldTime: FC<AcFieldTimeProps> = (props: AcFieldTimeProps) => {
  const [id, setId] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);

  const prefix = 'ac-field';
  const focused = useFocused(inputRef as RefObject<HTMLElement>);
  const pressed = usePressed(inputRef as RefObject<HTMLElement>);
  const date = !!props.value ? dayjs(props.value) : undefined;
  const isDateValid = date && date.isValid();
  const inputValue = isDateValid ? date!.format(props.format) : '';

  const classes = classNames(
    prefix,
    props.className,
    'ac-field-datetimepicker',
    getFieldStateClass(pressed, FieldState.Pressed, prefix),
    getFieldStateClass(focused, FieldState.Focused, prefix),
    getFieldStateClass(!!props.validation, FieldState.Invalid, prefix),
    getFieldStateClass(!!props.readonly, FieldState.Readonly, prefix),
    getFieldStateClass(!!props.disabled, FieldState.Disabled, prefix)
  );

  const labelClasses = classNames('ac-field-label', {
    required: !!props.required
  });

  const onChange = (): void => {
    let newTime;
    if (inputRef.current && !!inputRef.current.value) {
      const [hours, minutes] = inputRef.current.value.split(':');
      newTime = dayjs(isDateValid ? props.value : undefined)
        .set('hour', parseInt(hours, 10))
        .set('minute', parseInt(minutes, 10))
        .set('second', 0);
    }

    if (props.onChange && newTime) {
      props.onChange(newTime.format(defaultOutputFormat));
    }
  };

  // IOS hack: working clear button
  const onInput = (e: FormEvent<HTMLInputElement>) => {
    const target = e.currentTarget;
    setTimeout(() => {
      target.defaultValue = '';
      // IOS Hack: otherwise double click on clear button is needed
      target.dispatchEvent(new Event('change', { bubbles: true }));
    }, 0);
  };

  const onSelect = useCallback(
    (value?: string): void => {
      if (props.onChange && value) {
        const [hours, minutes] = value.split(':');
        const newDate = dayjs()
          .set('hour', Number(hours))
          .set('minute', Number(minutes));
        props.onChange(newDate.format(defaultOutputFormat));
      }
    },
    [props]
  );

  useEffect(() => setId(props.name || uid()), [props.name]);

  useEffect(() => {
    if (pressed && props.onFocus) {
      props.onFocus();
    }
  }, [pressed]);

  const mapToSelectedValue = useCallback((value?: string) => {
    if (!value) {
      return '';
    }
    const hour = dayjs(value).hour();
    const minute = dayjs(value).minute();

    return `${hour.toString().padStart(2, '0')}:${minute
      .toString()
      .padStart(2, '0')}`;
  }, []);

  const timePickerOptions = getTimePickerOptions(
    props.format === DISPLAY_TIME_FORMAT_12_HOURS ? 12 : 24
  );

  return (
    <div
      className={classes}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <div className="ac-field-header">
        <label className={labelClasses} htmlFor={id}>
          {props.label}
        </label>
      </div>
      <div className="ac-field-background">
        {props.selectable ? (
          <AcSelect
            showInputs={true}
            label={props.label}
            selectedItem={mapToSelectedValue(props.value)}
            itemsList={timePickerOptions}
            onChange={onSelect}
          />
        ) : (
          <>
            <input
              id={id}
              type={AcFieldTypes.Time}
              value={inputValue}
              name={props.name}
              disabled={props.disabled}
              onChange={onChange}
              readOnly={props.readonly}
              className="ac-field-datetime"
              ref={inputRef}
              onBlur={props.onBlur}
              onFocus={props.onFocus}
              required={props.required}
              onInput={onInput}
              {...getTestSelectorAttribute(props.testSelector, 'input')}
            />

            <button className="ac-field-input">
              {!!inputValue && <span className="value"> {inputValue} </span>}
              {!inputValue && (
                <span className="placeholder">
                  {' '}
                  {props.placeholder || props.format?.toUpperCase()}{' '}
                </span>
              )}
            </button>
            <AcIconButton icon={Icon.Clock} color={Color.PrimaryDark} />
          </>
        )}
      </div>
      {!!props.validation && (
        <div className="ac-field-error-message">{props.validation}</div>
      )}
    </div>
  );
};

export default memo(AcFieldTime);
