import { CSSProperties } from 'react';
import { Styleable } from '../interfaces/componentProps';

export type CSSPropertiesAndVars =
  | CSSProperties
  | { [key: string]: string | number | undefined | null };

export function combineStyle(
  props: Styleable,
  properties: CSSPropertiesAndVars
): CSSProperties {
  return { ...properties, ...props.style };
}
