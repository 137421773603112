import { useLayoutEffect, useRef } from 'react';
import { uid } from '../utils';

const enabledClassesMap = new Map<string, Set<string>>();
let lastEnabledClassesSet = new Set<string>();

function addBodyClass(className: string) {
  document.body.classList.add(className);
}

function removeBodyClass(className: string) {
  document.body.classList.remove(className);
}

function register(className: string, hookId: string) {
    const set = enabledClassesMap.get(className) || new Set<string>();
    if (!set.has(hookId)) {
        set.add(hookId);
        enabledClassesMap.set(className, set);
        updateBodyClasses();
    }
}

function unregister(className: string, hookId: string) {
    const set = enabledClassesMap.get(className);
    if (set && set.has(hookId)) {
        set.delete(hookId);
        if (set.size === 0) {
            enabledClassesMap.delete(className);
        }
        updateBodyClasses();
    }
}

function updateBodyClasses() {
    const enabledClasses = Array.from(enabledClassesMap.keys());
    const lastEnabledClasses = Array.from(lastEnabledClassesSet);

    enabledClasses.forEach(enabledClass => {
        if (!lastEnabledClassesSet.has(enabledClass)) {
            addBodyClass(enabledClass);
        }
    });

    lastEnabledClasses.forEach(lastEnabledClass => {
        if (!enabledClassesMap.has(lastEnabledClass)) {
            removeBodyClass(lastEnabledClass);
        }
    });

    lastEnabledClassesSet = new Set(enabledClassesMap.keys());
}

export default function useBodyClass(className: string, disabled?: boolean) {

    const hookId: string = useRef<string>(uid()).current;

    useLayoutEffect(
        () => {
            disabled ? unregister(className, hookId) : register(className, hookId);

            return () => {
                unregister(className, hookId);
            };
        },
        [className, disabled]
    );
}
