import classNames from 'classnames';
import React, { FC, memo, useContext, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { ChildRequired, Styleable, Testable } from '../../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../../utils';
import { AcAnchorContext } from '../AcAnchorListContext';
import { AcAnchorBase } from '../button/AcAnchorButton';
import { AcAnchorItem } from '../interfaces/AcAnchorItem';
import './AcAnchorSection.sass';

export interface AcAnchorSectionProps
  extends AcAnchorBase,
    Styleable,
    ChildRequired,
    Testable {
  isLast?: boolean;
}

const AcAnchorSection: FC<AcAnchorSectionProps> = (
  props: AcAnchorSectionProps
) => {
  let marginBottom = 0;
  const context = useContext(AcAnchorContext);
  const classes = classNames('ac-anchor-section', props.className);

  const [ref, inView, entry] = useInView({
    threshold: 0,
    rootMargin: '-1px',
    root: context?.rootRef.current || null
  });

  useEffect(() => {
    if (context) {
      const item: AcAnchorItem = {
        to: props.to,
        entry,
        inView,
        label: props.label
      };
      context.updateItem(props.to, item);
    }
  }, [inView]);

  // hack - add dummy bottom margin in order to scroll the last element to the top of content
  if (props.isLast) {
    const containerHeight = context?.rootRef.current?.clientHeight;
    const sectionHeight = entry?.boundingClientRect.height;

    if (containerHeight && sectionHeight) {
      marginBottom =
        containerHeight - sectionHeight > 0
          ? containerHeight - sectionHeight
          : 0;
    }
  }

  return (
    <div
      className={classes}
      id={props.to}
      ref={ref}
      {...getTestSelectorAttribute(props.testSelector)}
      style={{ marginBottom }}
    >
      {props.children}
    </div>
  );
};

export default memo(AcAnchorSection);
