import React from '../../../wc';
import { PropsWithChildren } from '../../reactTransition/propsWithChildren';

import { ViewportContext } from './context';
import { useViewportStore } from './store';

export const ViewportContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useViewportStore();

  return (
    <ViewportContext.Provider value={store}>
      {props.children}
    </ViewportContext.Provider>
  );
};
