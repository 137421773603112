import classNames from 'classnames';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  HTMLAttributes,
  memo,
  PropsWithChildren
} from 'react';
import {
  AlignItems,
  FlexDirection,
  FlexDisplay,
  JustifyContent
} from '../../enums';
import { Testable } from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import './AcFlex.sass';

export interface AcFlexProps extends HTMLAttributes<HTMLDivElement>, Testable {
  alignItems?: AlignItems;
  direction?: FlexDirection;
  display?: FlexDisplay;
  grow?: boolean;
  justifyContent?: JustifyContent;
  wrapped?: boolean;
}

const defaultProps: AcFlexProps = {
  alignItems: AlignItems.normal,
  direction: FlexDirection.row,
  display: FlexDisplay.flex,
  grow: false,
  justifyContent: JustifyContent.normal,
  wrapped: false
};

const AcFlex: ForwardRefRenderFunction<
  HTMLDivElement,
  PropsWithChildren<AcFlexProps>
> = (
  {
    className: classNameProp,
    testSelector,
    children,
    alignItems,
    direction,
    justifyContent,
    display,
    grow,
    wrapped,
    ...divProps
  },
  ref
) => {
  const className = classNames(
    'ac-flex',
    `ac-flex-align-items-${alignItems}`,
    `ac-flex-justify-content-${justifyContent}`,
    `ac-flex-direction-${direction}`,
    `ac-flex-display-${display}`,
    {
      'ac-flex-grow': grow,
      'ac-flex-wrap': wrapped
    },
    classNameProp
  );

  return (
    <div
      {...divProps}
      ref={ref}
      className={className}
      {...getTestSelectorAttribute(testSelector)}
    >
      {children}
    </div>
  );
};

const forward = forwardRef(AcFlex);
forward.defaultProps = defaultProps;
export default memo(forward);
