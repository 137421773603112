import { LoaderSize } from '@ac/web-components';

import React from '../../../wc';

export interface FullPageSpinnerProps {
  isVisible: boolean;
  text?: string;
}

export const FullPageSpinner = (
  props: FullPageSpinnerProps
): JSX.Element | null => {
  return props.isVisible ? (
    <ac-loader-covering text={props.text} loaderSize={LoaderSize.sm} />
  ) : null;
};
