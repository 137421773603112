let isAnythingFocused: boolean = false;
let lastWidth: number = -1;
let lastHeight: number = -1;
let didInit: boolean = false;

let repaintTimeouts: number[] = [];

const WRITE_TOTAL_TIME: number = 250;
const DELAYED_WRITE_COUNT: number = 2;
const DELAYED_WRITE_COOL_DOWN: number = WRITE_TOTAL_TIME / DELAYED_WRITE_COUNT;

export function initializeAppSizeManagement() {
  addEventListener('focus', () => (isAnythingFocused = true), {
    passive: false,
    capture: true
  });
  addEventListener('blur', () => (isAnythingFocused = false), {
    passive: false,
    capture: true
  });
  frameLoop();
}

function frameLoop() {
  handleFrame();
  requestAnimationFrame(frameLoop);
}

function handleFrame() {
  const w = window.innerWidth;
  const h = window.innerHeight;
  const sizeChanged: boolean = lastWidth !== w || lastHeight !== h;

  if (sizeChanged || !isAnythingFocused) {
    resetBodyAndDocumentScroll();
  }

  // Rewrite dimensions if size changed
  if (sizeChanged) {
    if (didInit) {
      // Clear timeouts
      repaintTimeouts.forEach(timeout => clearTimeout(timeout));
      repaintTimeouts = [];

      // Write new properties multiple times over time, all but last should have random offset to ensure repaint each time
      // Workaround for iOS
      setAllCSSVars(w, h, true);
      for (let i: number = 1; i <= DELAYED_WRITE_COUNT; i++) {
        const isLast: boolean = i === DELAYED_WRITE_COUNT;
        const delayTime: number = i * DELAYED_WRITE_COOL_DOWN;
        repaintTimeouts.push(
          window.setTimeout(() => setAllCSSVars(w, h, !isLast), delayTime)
        );
      }
    } else {
      setAllCSSVars(w, h);
    }

    lastWidth = w;
    lastHeight = h;
    didInit = true;
  }
}

function setAllCSSVars(w: number, h: number, useRandomOffset: boolean = false) {
  const randomOffset: number = useRandomOffset ? 0 : (0.5 - Math.random()) / 10;
  w += randomOffset;
  h += randomOffset;
  setCSSVar('vw100', w);
  setCSSVar('vh100', h);
  setCSSVar('vw', w / 100);
  setCSSVar('vh', h / 100);
}

function setCSSVar(name: string, value: number) {
  document.documentElement.style.setProperty(`--${name}`, `${value}px`);
}

function resetBodyAndDocumentScroll() {
  document.body.scrollTop = 0; // iOS <= 12 scrolls body on when displaying keyboards
  document.documentElement.scrollTop = 0; // iOS >= 13 scrolls document
}
