import { Fragment, useEffect, useMemo } from 'react';

import React from '../../wc';
import { PropsWithChildren } from '../reactTransition/propsWithChildren';

import { checkIfUserIsAuthenticated } from './checkIfUserIsAuthenticated';
import { isUserAuthenticated } from './isUserAuthenticated';
import { LoginContainer } from './LoginContainer';
import { useAuthenticationContext } from './store';

interface UnauthorizedAccessLockerProps {
  customLoadingView?: JSX.Element;
}

export const UnauthorizedAccessLocker = (
  props: PropsWithChildren<UnauthorizedAccessLockerProps>
): JSX.Element => {
  const { state, setAuthenticationResult } = useAuthenticationContext();

  const isAuthenticated = useMemo(
    () => isUserAuthenticated(state.authenticatedUserType),
    [state.authenticatedUserType]
  );

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    const result = checkIfUserIsAuthenticated();

    setAuthenticationResult(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return isAuthenticated ? (
    <Fragment>{props.children}</Fragment>
  ) : (
    <LoginContainer customLoadingView={props.customLoadingView} />
  );
};
