import { Color } from '../../enums';
import { Styleable, Testable } from '../../interfaces/componentProps';

export interface AcSpinnerProps extends Styleable, Testable {
  large?: boolean;
  color?: Color;
}

export const defaultAcSpinnerProps: AcSpinnerProps = {
  color: Color.PrimaryDark
};
