import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { Childless } from '../../interfaces/componentProps';
import { combineStyle, getBorderColorClass, getTestSelectorAttribute } from '../../utils';
import './AcSpinner.sass';
import { AcSpinnerProps, defaultAcSpinnerProps } from './acSpinnerProps';

export interface AcSpinnerBasicProps extends AcSpinnerProps, Childless {}

const AcSpinner: FC<AcSpinnerBasicProps> = (props: AcSpinnerBasicProps) => {
  const style = combineStyle(props, {
    borderTopColor: 'transparent'
  });
  const className: string = classNames(
    'ac-spinner',
    { 'ac-spinner-large': props.large },
    props.color ? getBorderColorClass(props.color) : null,
    props.className
  );

  return (
    <div
      className={className}
      style={style}
      {...getTestSelectorAttribute(props.testSelector)}
    />
  );
};

AcSpinner.defaultProps = defaultAcSpinnerProps;
export default memo(AcSpinner);
