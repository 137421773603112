import { acConfig } from '@ac/library-utils/dist/declarations';
import { ButtonPattern, ButtonTheme, IconName } from '@ac/web-components';

import Breadcrumbs from '../../components/layout/Breadcrumbs/Breadcrumbs';
import React from '../../wc';
import { getContainer } from '../namespace/globalNamespace';
import { PropsWithChildren } from '../reactTransition/propsWithChildren';
import {
  RouteChangeCallbacksNavigate,
  RouteChangeCallbacksRequired,
} from '../routing';
import { useRoutingContext } from '../routing/storage';

interface BreadcrumbsProps {
  testSelector?: string;
  routeChangeCallbacks?: RouteChangeCallbacksNavigate;
}

const openUserDocumentationInNewTab = () => {
  window.open(acConfig.frontendUrls.userDocumentation, '_blank');
};

const openUserDocumentation = () => {
  const openUserDocumentation =
    getContainer()?.openContextHelpTopic || openUserDocumentationInNewTab;
  openUserDocumentation();
};

/**
 * Breadcrumb wrapper. By default it will use `window.history` to change routes. To change this behavior, please provide `routeChangeCallbacks` prop directly. Or if context wrappers are used; then to - `SinglePropertyModule` or `SinglePropertyFeature`.
 *
 * @param routeChangeCallbacks(optional) Options to override default `window.history` way of route change
 * @param routeChangeCallbacks.onNavigate(optional) Callback to change any route
 * @param routeChangeCallbacks.onNavigateBack(optional) Callback to go back in history
 */
export const BreadcrumbsWrapper = (
  props: PropsWithChildren<BreadcrumbsProps>
): JSX.Element => {
  const {
    state: {
      currentRoute: { title, breadcrumbContent },
      history,
      routeChangeCallbacks: routeChangeCallbacksViaContext,
      onBeforePageUnload,
    },
  } = useRoutingContext();
  const prevStates = history?.slice(0, history?.length - 1);
  const routeChangeCallbacks: RouteChangeCallbacksRequired = {
    ...routeChangeCallbacksViaContext,
    ...(props.routeChangeCallbacks || {}),
  };

  // If the current page has setup an `onBeforePageUnload`, it should be called before the route change to previous pages
  const onNavigate = (path: string): void => {
    if (onBeforePageUnload) {
      onBeforePageUnload(path);
    }
    routeChangeCallbacks.onNavigate(path);
  };
  const onNavigateBack = (): void => {
    if (onBeforePageUnload) {
      onBeforePageUnload();
    }
    routeChangeCallbacks.onNavigateBack();
  };

  return (
    <Breadcrumbs
      prevStates={prevStates}
      title={title}
      onNavigate={onNavigate}
      onNavigateBack={onNavigateBack}
    >
      {props.children}
      <span>{breadcrumbContent}</span>
      <ac-button
        pattern={ButtonPattern.tertiary}
        theme={ButtonTheme.light}
        onClick={openUserDocumentation}
      >
        <ac-button-content
          icon={IconName.help}
          data-test-selector="documentation-link"
        />
      </ac-button>
    </Breadcrumbs>
  );
};
