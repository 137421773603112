import classNames from 'classnames';
import React, { FC, memo, PropsWithChildren } from 'react';
import { Styleable } from '../../interfaces/componentProps';
import './AcAccordion.sass';
import AcAccordionWrapper from './components/AcAccordionWrapper';
import AcAccordionGenericProps from './interface/AcAccordionGenericProps';

export interface AcAccordionsProps extends AcAccordionGenericProps, Styleable {}

const AcAccordion: FC<PropsWithChildren<AcAccordionsProps>> = (
  props: PropsWithChildren<AcAccordionsProps>
) => {
  const classes = classNames('ac-accordion', props.className);

  return (
    <AcAccordionWrapper onChange={props.onChange}>
      <div className={classes}>{props.children}</div>
    </AcAccordionWrapper>
  );
};

export default memo(AcAccordion);
