import { useCallback, useState } from 'react';

import { SystemContext } from '@ac/library-api';

import {
  getDefaultSystemBusinessContextState,
  SystemBusinessContextState,
} from './state';

export interface SystemBusinessContextStore {
  state: SystemBusinessContextState;

  setBusinessContext(businessContext: SystemContext): void;
}

export const useCreateSystemBusinessContextStore =
  (): SystemBusinessContextStore => {
    const [state, setState] = useState(getDefaultSystemBusinessContextState());

    const setBusinessContext = useCallback((businessContext: SystemContext) => {
      setState((prevState) => ({
        ...prevState,
        businessContext,
      }));
    }, []);

    return {
      state,
      setBusinessContext,
    };
  };
