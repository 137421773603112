import { ReactNode, useCallback, useMemo, useState } from 'react';

import {
  getDefaultRoutingState,
  OnBeforePageUnloadCallback,
  RouteChangeCallbacks,
  RoutingState,
} from './state';

export interface RouteDetails {
  title: string;
  label?: string;
  link: string;
  breadcrumbContent?: ReactNode;
  onBeforePageUnload?: OnBeforePageUnloadCallback;
}

export interface RoutingStore {
  state: RoutingState;

  setCurrentRoute(route: RouteDetails): void;
  removeOnBeforePageUnload(): void;
}

export const useRoutingStore = (
  routeChangeCallbacks?: RouteChangeCallbacks
): RoutingStore => {
  const [state, setState] = useState<RoutingState>(
    getDefaultRoutingState(routeChangeCallbacks)
  );

  const setCurrentRoute = useCallback(
    (route: RouteDetails) => {
      const { link, title, label, breadcrumbContent, onBeforePageUnload } =
        route;
      const history = state.history.filter((item) => item.link !== link);

      history.push({
        link,
        label: label ?? title,
      });

      setState(
        (currentState: RoutingState): RoutingState => ({
          ...currentState,
          currentRoute: {
            title,
            breadcrumbContent,
          },
          history,
          onBeforePageUnload,
        })
      );
    },
    [state.currentRoute, state.history]
  );

  const removeOnBeforePageUnload = () => {
    setState(
      (currentState: RoutingState): RoutingState => ({
        ...currentState,
        onBeforePageUnload: undefined,
      })
    );
  };

  return useMemo(
    () => ({
      state,
      setCurrentRoute,
      removeOnBeforePageUnload,
    }),
    [state, setCurrentRoute]
  );
};
