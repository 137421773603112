import classNames from 'classnames';
import React, {
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction,
  HTMLAttributes,
  memo,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useRef,
  useState
} from 'react';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { FlexDirection } from '../../../enums';
import { Testable } from '../../../interfaces/componentProps';
import { formatTestSelector, getTestSelectorAttribute } from '../../../utils';
import { AcFlex } from '../../flex';
import { AcSpinnerCover } from '../../spinner';
import './AcBody.sass';

export interface AcBodyProps extends HTMLAttributes<HTMLDivElement>, Testable {
  contentClassName?: string;
  contentStyle?: CSSProperties;
  mainClassName?: string;
  mainStyle?: CSSProperties;
  noPadding?: boolean;
  showSpinner?: boolean;
  subheader?: ReactNode;
  fabs?: ReactNode;
  scrollRef?: MutableRefObject<HTMLDivElement | null>;
}

const scrollDeadzone: number = 25;
const subheaderMinimumScrollForHiding: number = 20;

const AcBody: ForwardRefRenderFunction<
  HTMLDivElement,
  PropsWithChildren<AcBodyProps>
> = (
  {
    className: classNameProp,
    testSelector,
    fabs,
    noPadding,
    scrollRef,
    contentClassName: contentClassNameProp,
    contentStyle,
    mainClassName: mainClassNameProp,
    mainStyle,
    showSpinner,
    subheader,
    children,
    ...divProps
  },
  ref
) => {
  const lastScrollPositionRef = useRef<number>(0);
  const [showSubheader, setShowSubheader] = useState<boolean>(true);
  const className = classNames(
    'ac-body',
    !showSubheader && 'ac-body-subheader-hidden',
    noPadding && 'ac-body-no-padding',
    classNameProp
  );

  const {
    ref: subheaderWrapperRef,
    height: subheaderWrapperHeight
  } = useResizeObserver();

  const onScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLElement;
    const scrollPosition = target.scrollTop;
    const isOverScrolling =
      scrollPosition < 0 ||
      scrollPosition + target.clientHeight > target.scrollHeight;

    if (isOverScrolling) {
      return;
    }

    const didPositionChangeEnough =
      Math.abs(scrollPosition - lastScrollPositionRef.current) > scrollDeadzone;
    const isSubheaderPossibleToHide =
      scrollPosition > subheaderMinimumScrollForHiding;
    if (isSubheaderPossibleToHide) {
      if (didPositionChangeEnough) {
        setShowSubheader(scrollPosition < lastScrollPositionRef.current);
        lastScrollPositionRef.current = scrollPosition;
      }
    } else {
      setShowSubheader(true);
      lastScrollPositionRef.current = 0;
    }
  }, []);

  const contentClassName = classNames('ac-body-content', contentClassNameProp);
  const mainClassName = classNames('ac-body-main', mainClassNameProp);

  return (
    <div
      ref={ref}
      onScrollCapture={subheader ? onScroll : undefined}
      {...divProps}
      className={className}
      {...getTestSelectorAttribute(testSelector)}
    >
      <div className="ac-subheader-wrapper" ref={subheaderWrapperRef}>
        {subheader}
      </div>
      <AcFlex
        className="ac-body-wrap"
        ref={scrollRef}
        direction={FlexDirection.column}
      >
        {subheader && (
          <div
            className="ac-subheader-spacer"
            style={{ flexBasis: subheaderWrapperHeight }}
          />
        )}
        <main className={mainClassName} style={mainStyle}>
          <div className={contentClassName} style={contentStyle}>
            {children}
          </div>
          {fabs && <div className="ac-spacing-top-md">{fabs}</div>}
        </main>
      </AcFlex>
      <AcSpinnerCover
        disabled={!showSpinner}
        testSelector={formatTestSelector(testSelector, 'bodySpinner')}
      />
    </div>
  );
};

const forward = forwardRef(AcBody);
export default memo(forward);
