import classNames from 'classnames';
import React, { FC, PropsWithChildren, ReactNode, useRef } from 'react';
import { FieldState, Icon, LoadingState } from '../../enums';
import usePressed from '../../hooks/usePressed';
import {
  Clickable,
  Contractable,
  Disableable,
  Selectable,
  Styleable,
  Testable,
  Themeable
} from '../../interfaces/componentProps';
import { getFieldStateClass, getTestSelectorAttribute, getThemeClass } from '../../utils';
import { AcIcon } from '../icon';
import { AcRipple } from '../ripple';
import './AcSystemUser.sass';

interface AcSystemUserProps
  extends Styleable,
    Clickable,
    Selectable,
    Disableable,
    Contractable,
    Themeable,
    Testable {
  loading?: LoadingState;
}

const defaultProps: AcSystemUserProps = {
  loading: LoadingState.Default
};

const AcSystemUser: FC<PropsWithChildren<AcSystemUserProps>> = (
  props: PropsWithChildren<AcSystemUserProps>
) => {
  const ref = useRef<HTMLButtonElement>(null);
  const pressed = usePressed(ref);
  const prefix = 'ac-system-user';
  const notClickable =
    !props.onClick || props.disabled || props.loading === LoadingState.Loading;

  const classes: string = classNames(
    prefix,
    { [`${prefix}-contracted`]: props.contractedTouchArea },
    getThemeClass(props, prefix),
    { [`${prefix}-loading`]: props.loading !== LoadingState.Default },
    getFieldStateClass(props.selected, FieldState.Selected, prefix),
    getFieldStateClass(props.disabled, FieldState.Disabled, prefix),
    getFieldStateClass(pressed && !notClickable, FieldState.Pressed, prefix),
    props.className
  );

  const getContent = (): ReactNode | null => {
    switch (props.loading) {
      case LoadingState.LoadingError:
        return <AcIcon icon={Icon.Restart} />;
      case LoadingState.Loading:
        return null;
      default:
        return props.children;
    }
  };

  return (
    <button
      className={classes}
      style={props.style}
      onClick={props.onClick}
      ref={ref}
      disabled={props.disabled}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <AcRipple className="ac-system-user-square" disabled={notClickable}>
        <div className="ac-system-user-children">{getContent()}</div>
        <div className="ac-system-user-border" />
      </AcRipple>
    </button>
  );
};

AcSystemUser.defaultProps = defaultProps;

export default AcSystemUser;
