import classNames from 'classnames';
import React, { FC } from 'react';
import { ChildRequired, Styleable, Testable } from '../../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../../utils';

export interface AcModalFooterProps
  extends ChildRequired,
    Styleable,
    Testable {}

const AcModalFooter: FC<AcModalFooterProps> = (props: AcModalFooterProps) => {
  const classes: string = classNames('ac-modal-footer', props.className);

  return (
    <div
      className={classes}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      {props.children}
    </div>
  );
};

export default AcModalFooter;
