import { RefObject, useLayoutEffect, useState } from 'react';

function useElementSize(ref: RefObject<HTMLElement>) {

  const [width, setWidth] = useState<number>();
  const [height, setHeight] = useState<number>();

  useLayoutEffect(() => {
    const interval = setInterval(checkChange);
    checkChange();

    return () => {
      clearInterval(interval);
    };
  }, [width, height]);

  function checkChange() {
    const newWidth = ref.current ? ref.current.offsetWidth : 0;
    const neHeight = ref.current ? ref.current.offsetHeight : 0;
    if (newWidth !== width) {
      setWidth(newWidth);
    }
    if (neHeight !== height) {
      setHeight(neHeight);
    }
  }

  return [width, height];
}

export default useElementSize;
