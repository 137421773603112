/* eslint-disable @typescript-eslint/no-explicit-any */
import React from '../../../wc';
import { PropsWithChildren } from '../../reactTransition/propsWithChildren';

import { RoutingContext } from './context';
import { RouteChangeCallbacks } from './state';
import { useRoutingStore } from './store';

interface Props {
  routeChangeCallbacks?: RouteChangeCallbacks;
}

export const RoutingContextProvider = (
  props: PropsWithChildren<Props>
): JSX.Element => {
  const store = useRoutingStore(props.routeChangeCallbacks);

  return (
    <RoutingContext.Provider value={store}>
      {props.children}
    </RoutingContext.Provider>
  );
};
