import { WithoutKeys } from '../../../../../declarations/types';

interface LoadStyleParams {
  fullUrl: string;
}

const getExternalStyleElement = ({
  fullUrl,
}: LoadStyleParams): HTMLLinkElement => {
  const styleElement = document.createElement('link');
  styleElement.rel = 'stylesheet';
  styleElement.crossOrigin = 'use-credentials';
  styleElement.href = fullUrl;

  return styleElement;
};

export const loadExternalStyle = (params: LoadStyleParams): Promise<void> => {
  const elementToAdd = getExternalStyleElement(params);
  const alreadyExistentElement = document.head.querySelector(
    `[href="${elementToAdd.href}"]`
  );
  if (alreadyExistentElement) {
    return Promise.resolve();
  }

  const loadingPromise = new Promise<void>((resolve) => {
    elementToAdd.onload = () => resolve();
  });
  document.head.appendChild(elementToAdd);

  return loadingPromise;
};

export type LoadingStyleConfiguration = WithoutKeys<
  LoadStyleParams,
  'fullUrl'
> & { filePath: string };

export const loadExternalStyles = async (
  pageUrl: string,
  styles: LoadingStyleConfiguration[]
): Promise<void> => {
  await Promise.all(
    styles.map((style) =>
      loadExternalStyle({
        ...style,
        fullUrl: `${pageUrl}/${style.filePath}`,
      })
    )
  );
};
