import classNames from 'classnames';
import React, { FC, memo, PropsWithChildren, useRef } from 'react';
import usePressed from '../../hooks/usePressed';
import {
  ChildRequired,
  Clickable,
  Linkable,
  Styleable,
  Testable
} from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import { AcWrappedLink } from '../wrapped-link';
import './AcDropdownList.sass';

export interface AcButtonDropdownItemProps
  extends Styleable,
    ChildRequired,
    Clickable,
    Linkable,
    Testable {}

const AcDropdownItem: FC<PropsWithChildren<AcButtonDropdownItemProps>> = (
  props: PropsWithChildren<AcButtonDropdownItemProps>
) => {
  const ref = useRef<HTMLDivElement>(null);
  const pressed = usePressed(ref);

  const className = classNames(
    'ac-button-dropdown-item',
    { 'ac-button-dropdown-item-pressed': pressed },
    { 'ac-button-dropdown-linked': !!props.link },
    props.className
  );

  return (
    <div
      ref={ref}
      className={className}
      style={props.style}
      onClick={props.onClick}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <AcWrappedLink link={props.link} className="ac-button-dropdown-link">
        {props.children}
      </AcWrappedLink>
    </div>
  );
};

export default memo(AcDropdownItem);
