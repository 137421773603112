import React from 'react';
import AcAccordionItemState from '../interface/AcAccordionItemState';

export interface AcAccordionContextData {
  toggleExpanded(accordionId: string): void;
  isItemExpanded(accordionId: string): boolean;

  isItemDisabled(accordionId: string): boolean;
  setDisableStatus(accordionId: string, status: boolean): void;

  unregisterItem(accordionId: string): void;
  isRegistered(accordionId: string): boolean;
  registerItem(accordionId: string, item: AcAccordionItemState): void;
}

export const AcAccordionContext = React.createContext<AcAccordionContextData | null>(
  null
);
