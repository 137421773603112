import classNames from 'classnames';
import React, {
  FC,
  memo,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import { FieldState } from '../../enums';
import usePressed from '../../hooks/usePressed';
import {
  Clickable,
  Contractable,
  Focusable,
  Selectable,
  Styleable,
  Testable
} from '../../interfaces/componentProps';
import { getFieldStateClass, getTestSelectorAttribute } from '../../utils';
import { AcRipple } from '../ripple';
import './AcAvatar.sass';

export interface AcAvatarProps
  extends Styleable,
    Clickable,
    Focusable,
    Selectable,
    Contractable,
    Testable {
  getImage?: Promise<string>;
  indicator?: ReactNode;
}

const AcAvatar: FC<PropsWithChildren<AcAvatarProps>> = (
  props: PropsWithChildren<AcAvatarProps>
) => {
  const [isImageLoading, setIsImageLoading] = useState(!!props.getImage);
  const [image, setImage] = useState<string>();
  const ref = useRef<HTMLButtonElement>(null);
  const pressed = usePressed(ref);

  useEffect(() => {
    setIsImageLoading(!!props.getImage);
    if (props.getImage) {
      props.getImage.then(onImagePromiseResolved).catch(onImageLoadError);
    }
  }, [props.getImage]);

  const classes: string = classNames(
    'ac-avatar',
    { 'ac-avatar-loading': isImageLoading },
    { 'ac-avatar-loaded': !!image },
    { 'ac-avatar-no-image': !isImageLoading && !image },
    { 'ac-avatar-contracted': props.contractedTouchArea },
    getFieldStateClass(props.selected, FieldState.Selected, 'ac-avatar'),
    getFieldStateClass(pressed, FieldState.Pressed, 'ac-avatar'),
    props.className
  );

  function onImagePromiseResolved(resolvedImage: string) {
    resolvedImage === image ? onImageLoadSuccess() : setImage(resolvedImage);
  }

  function onImageLoadSuccess() {
    setIsImageLoading(false);
  }

  function onImageLoadError() {
    setIsImageLoading(false);
    setImage(undefined);
  }

  return (
    <button
      className={classes}
      style={props.style}
      onClick={props.onClick}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      ref={ref}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <AcRipple className="ac-avatar-circle">
        <img
          src={image}
          className="ac-avatar-image"
          onLoad={onImageLoadSuccess}
          onError={onImageLoadError}
          {...getTestSelectorAttribute(props.testSelector, 'image')}
          alt=""
        />
        <div
          className="ac-avatar-children"
          {...getTestSelectorAttribute(props.testSelector, 'content')}
        >
          {props.children}
        </div>
        <div className="ac-avatar-border" />
      </AcRipple>
      <div className="ac-avatar-indicator-wrapper">{props.indicator}</div>
    </button>
  );
};

export default memo(AcAvatar);
