import { LayoutProperties } from '@ac/web-components';

import React from '../../wc';
import { PropsWithChildren } from '../reactTransition/propsWithChildren';

import { BreadcrumbsWrapper } from './BreadcrumbsWrapper';

export const MainViewportLayoutWithBreadcrumbs = (
  props: PropsWithChildren
): JSX.Element => {
  return (
    <ac-layout
      gridTemplate="'menu' 'body'"
      gridAutoRows="var(--menu-height) auto"
    >
      <ac-layout-item area="menu" />
      <ac-layout-item area="body" class="overflow-hidden">
        <ac-layout layout={LayoutProperties.headerBody}>
          <ac-layout-item area="header">
            <BreadcrumbsWrapper />
          </ac-layout-item>
          <ac-layout-item area="body" class="overflow-hidden">
            {props.children}
          </ac-layout-item>
        </ac-layout>
      </ac-layout-item>
    </ac-layout>
  );
};
