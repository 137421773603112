import React from '../../../wc';
import { PropsWithChildren } from '../../reactTransition/propsWithChildren';

import { AuthenticationContext } from './context';
import { useAuthenticationStore } from './store';

export const AuthenticationContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useAuthenticationStore();

  return (
    <AuthenticationContext.Provider value={store}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};
