import {
  buildFIQLFilter,
  combineFilters,
  FIQLOperators,
  OrganizationUnitType,
  PageResponse,
  RawUnitAssignmentListItem,
  UnitAssignmentListItem,
} from '@ac/library-api';
import { UnitAccessApi } from '@ac/library-api/dist/api/v0/permissionManagement';
import { SessionService } from '@ac/library-utils/dist/services';

const findUserDefaultPropertyId = async (): Promise<string | undefined> => {
  const response = (await UnitAccessApi.getMyUnits({
    queryParams: {
      filter: combineFilters([
        buildFIQLFilter(
          'type',
          FIQLOperators.equal,
          OrganizationUnitType.Property
        ),
        buildFIQLFilter('isPublished', FIQLOperators.equal, true),
      ]),
      pageNumber: 1,
      pageSize: 1,
    },
  })) as PageResponse<RawUnitAssignmentListItem, UnitAssignmentListItem>;

  return response.results[0]?.unitId;
};

export const ensurePropertyIdForCurrentUser = async () => {
  const savedPropertyId = SessionService.getPropertyId();

  if (savedPropertyId) {
    return savedPropertyId;
  }

  const userDefaultPropertyId = await findUserDefaultPropertyId();

  if (!userDefaultPropertyId) {
    throw new Error(
      'Cannot use property context for currently logged-in user. The property ID is not defined'
    );
  }

  SessionService.setPropertyId(userDefaultPropertyId);

  return userDefaultPropertyId;
};
