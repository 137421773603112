import { AppToRender } from '../../../rendering/shared';
import { StartParams } from '../../../start';
/* This is a helper function that will load an ember app via script tags and then
 * hook into Ember's built-in module registry (window.define/window.require) in order
 * to pass the app module and it's lifecycle functions to single-spa.
 *
 * appName: the name of the ember application. This will be used to window.require(appName + '/app');
 * appUrl: the url to script tag for the ember application.
 * vendorUrl (optional): if provided, the vendor bundle will be loaded first before the application bundle.
 *   This is optional since you could have more than one single-spa applications written in Ember, but only
 *   one vendor bundle.
 */

export function loadEmberScripts<AdditionalModuleExports>(
  appName: string,
  appUrl: string,
  vendorUrl: string | null = null
): Promise<AppToRender<StartParams> & AdditionalModuleExports> {
  return new Promise((resolve, reject) => {
    function loadApp(): void {
      const scriptEl = document.createElement('script');
      scriptEl.src = appUrl;
      scriptEl.charset = 'utf-8';
      scriptEl.async = true;
      scriptEl.onload = (): void => {
        resolve(window.require(appName + '/app'));
      };
      scriptEl.onerror = reject;
      document.head.appendChild(scriptEl);
    }

    if (typeof appName !== 'string') {
      reject(
        new Error(
          `single-spa-ember requires an appName string as the first argument`
        )
      );

      return;
    }

    if (typeof appUrl !== 'string') {
      reject(
        new Error(
          `single-spa-ember requires an appUrl string as the second argument`
        )
      );

      return;
    }

    if (vendorUrl && typeof vendorUrl !== 'string') {
      reject(
        new Error(
          `single-spa-ember vendorUrl (the third argument) is optional, but must be a string if present`
        )
      );

      return;
    }

    if (vendorUrl) {
      const scriptVendor = document.createElement('script');
      scriptVendor.src = vendorUrl;
      scriptVendor.charset = 'utf-8';
      scriptVendor.async = true;
      scriptVendor.onload = loadApp;
      scriptVendor.onerror = reject;
      document.head.appendChild(scriptVendor);
    } else {
      loadApp();
    }
  });
}
