import classNames from 'classnames';
import React, { FC, memo, ReactNode } from 'react';
import { AlignItems, FlexDirection, JustifyContent } from '../../enums';
import {
  ChildRequired,
  Requireable,
  Styleable,
  Testable
} from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import { AcFlex } from '../flex';
import './AcFormElement.sass';

export interface AcFormElement
  extends ChildRequired,
    Styleable,
    Testable,
    Requireable {
  label: string | ReactNode;
  ellipsis?: boolean;
  smallLabel?: boolean;
  equalLineHeight?: boolean;
  flatAndJustify?: JustifyContent;
}

const AcFormElement: FC<AcFormElement> = (props: AcFormElement) => {
  const classes = classNames('ac-form-element', props.className);

  const labelClasses = classNames(
    'ac-form-element-label',
    props.required && 'required',
    props.smallLabel && 'ac-form-element-label-small'
  );

  const valueClassName = classNames(
    'ac-form-element-value',
    props.ellipsis && 'ac-form-element-value-ellipsis',
    props.equalLineHeight && 'ac-form-element-value-equal-line-height',
    props.flatAndJustify && 'ac-spacing-left-xs'
  );

  return (
    <AcFlex
      className={classes}
      style={props.style}
      alignItems={props.flatAndJustify ? AlignItems.center : AlignItems.normal}
      justifyContent={props.flatAndJustify || JustifyContent.normal}
      direction={
        props.flatAndJustify ? FlexDirection.row : FlexDirection.column
      }
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <label
        className={labelClasses}
        {...getTestSelectorAttribute(props.testSelector, 'label')}
      >
        {props.label}
      </label>
      <div
        className={valueClassName}
        {...getTestSelectorAttribute(props.testSelector, 'value')}
      >
        {props.children}
      </div>
    </AcFlex>
  );
};
export default memo(AcFormElement);
