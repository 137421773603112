import { extractScriptPath } from '../shared/scriptPathExtraction';

const getScriptNameRegexp = (
  fileNamePrefix: string,
  fileExtension: string
): RegExp => new RegExp(`assets\/${fileNamePrefix}.*\.${fileExtension}`);

export const extractVendorCssPath = (indexPage: string) =>
  extractScriptPath(getScriptNameRegexp('vendor', 'css'), indexPage);

export const extractAppCssPath = (indexPage: string, appName: string) =>
  extractScriptPath(getScriptNameRegexp(appName, 'css'), indexPage);

export const extractVendorJsPath = (indexPage: string) =>
  extractScriptPath(getScriptNameRegexp('vendor', 'js'), indexPage);

export const extractAppJsPath = (indexPage: string, appName: string) =>
  extractScriptPath(getScriptNameRegexp(appName, 'js'), indexPage);
