import React, { FC, memo, useMemo } from 'react';
import {
  AlignItems,
  Color,
  FlexDirection,
  Icon,
  IconSize,
  TextSize
} from '../../enums';
import { Childless, Testable } from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import { AcButton } from '../button';
import { AcButtonContent } from '../button-content';
import { AcFlex } from '../flex';
import { AcIcon } from '../icon';
import { AcText } from '../text';
import './AcNoContent.sass';
import { AcNoContentText } from './acNoContentText';

interface AcNoContentProps extends Childless, Testable {
  text?: AcNoContentText;
  isFiltered?: boolean;
  onFiltersReset?: () => void;
}

const AcNoContent: FC<AcNoContentProps> = props => {
  const text: AcNoContentText = useMemo(() => {
    const defaultText: AcNoContentText = {
      title: 'No Items found.',
      description: 'There is no items in the database.',
      filteredDescription:
        'There is no items matching the filtering criteria. Clear or change filter settings.',
      resetFiltersButton: 'Reset filters'
    };
    const propsText = props.text || {};

    return { ...defaultText, ...propsText };
  }, [props.text]);

  return (
    <AcFlex
      direction={FlexDirection.column}
      alignItems={AlignItems.center}
      className="ac-no-content"
      testSelector={props.testSelector}
    >
      <AcIcon
        icon={props.isFiltered ? Icon.Search : Icon.Cancel}
        size={IconSize.Standard}
        color={Color.PrimaryDark}
      />
      <AcText
        color={Color.Black}
        size={TextSize.Main1}
        className="ac-spacing-top-xs"
      >
        {text.title}
      </AcText>
      <AcText
        color={Color.Black}
        size={TextSize.Main3}
        className="ac-spacing-top-xs ac-no-content-description"
      >
        {props.isFiltered ? text.filteredDescription : text.description}
      </AcText>
      {props.isFiltered && props.onFiltersReset && (
        <AcButton
          className="ac-spacing-top-md"
          onClick={props.onFiltersReset}
          {...getTestSelectorAttribute(props.testSelector, 'filtered')}
        >
          <AcButtonContent text={text.resetFiltersButton} />
        </AcButton>
      )}
    </AcFlex>
  );
};

export default memo(AcNoContent);
