import classNames from 'classnames';
import React, { FC, PropsWithChildren, useContext } from 'react';
import { AlignItems, Color, Icon, JustifyContent } from '../../../enums';
import { Styleable, Testable } from '../../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../../utils';
import { AcFlex } from '../../flex';
import { AcIconButton } from '../../icon-button';
import { AcText } from '../../text';
import { AcModalContext } from '../acModalContext';
import { AcModalPattern } from '../acModalPattern';

export interface AcModalHeaderProps extends Styleable, Testable {
  showCloseButton?: boolean;
}

const defaultProps: AcModalHeaderProps = {
  showCloseButton: true
};

const AcModalHeader: FC<PropsWithChildren<AcModalHeaderProps>> = (
  props: PropsWithChildren<AcModalHeaderProps>
) => {
  const acModalContext = useContext(AcModalContext)!;
  const classes: string = classNames('ac-modal-header', props.className);

  const onClickHandler = () => {
    acModalContext.onClose();
  };

  return (
    <AcFlex
      justifyContent={JustifyContent.spaceBetween}
      alignItems={AlignItems.center}
      className={classes}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <AcText className="ac-modal-header-title">{props.children}</AcText>
      {props.showCloseButton && (
        <AcIconButton
          className="ac-modal-header-close-button"
          icon={Icon.Cancel}
          color={
            acModalContext.pattern !== AcModalPattern.Drawer
              ? Color.White
              : Color.PrimaryDark
          }
          onClick={onClickHandler}
          {...getTestSelectorAttribute(props.testSelector, 'button-close')}
        />
      )}
    </AcFlex>
  );
};

AcModalHeader.defaultProps = defaultProps;

export default AcModalHeader;
