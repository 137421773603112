import classNames from 'classnames';
import React, { FC, memo, PropsWithChildren } from 'react';
import { Styleable, Testable } from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import './AcBox.sass';
import { BoxSizes } from './types';

export interface AcBoxProps extends Styleable, Testable {
  form?: boolean;
  grow?: boolean;
  size?: BoxSizes;
}

const defaultProps: AcBoxProps = {
  form: false,
  grow: false,
  size: 12
};

const AcBox: FC<PropsWithChildren<AcBoxProps>> = (
  props: PropsWithChildren<AcBoxProps>
) => {
  const classes: string = classNames('ac-box', props.className, {
    [`box-size-${props.size}`]: true,
    'box-form': props.form,
    'box-grow': props.grow
  });

  return (
    <div
      className={classes}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      {props.children}
    </div>
  );
};

AcBox.defaultProps = defaultProps;

export default memo(AcBox);
