import classNames from 'classnames';
import React, { AnchorHTMLAttributes, FC, memo, useContext } from 'react';
import AcMobileContext from '../../config';
import { Linkable, Testable } from '../../interfaces/componentProps';

interface Props
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    Testable,
    Linkable {}

const AcWrappedLink: FC<Props> = ({
  link,
  children,
  className: classNameProp,
  ...anchorProps
}) => {
  const LinkComponent = useContext(AcMobileContext)!.linkComponent!;

  if (!LinkComponent && link) {
    throw new Error(
      'Please inject a link component globally via AcMobileContext. Unfortunately this is a workaround, so that mobile components with links can connect to app router.'
    );
  }

  const className = classNames(classNameProp, 'unstyled-link');

  return (
    <>
      {link ? (
        <LinkComponent {...anchorProps} className={className} to={link}>
          {children}
        </LinkComponent>
      ) : (
        children
      )}
    </>
  );
};

export default memo(AcWrappedLink);
