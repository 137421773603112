import React, { MutableRefObject } from 'react';

export interface AcFloatingContextData {
  disableAllButtons?: boolean;
  getExpandedItem(): string | undefined;
  setItemToExpand(id: string): void;
  closeExpandedItem(eventToDelay?: () => void): void;
  registerFab(ref: MutableRefObject<HTMLElement | null>): void;
  unregisterFab(ref: MutableRefObject<HTMLElement | null>): void;
}

export default React.createContext<AcFloatingContextData | null>(null);
