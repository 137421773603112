import classNames from 'classnames';
import React, { memo } from 'react';
import { Styleable } from '../../interfaces/componentProps';
import './AcDivider.sass';

const AcDivider: React.FC<Styleable> = ({ className }) => (
  <div className={classNames(className, 'ac-divider')} />
);

export default memo(AcDivider);
