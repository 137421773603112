import React from '../../../../../wc';
import { PropsWithChildren } from '../../../../reactTransition/propsWithChildren';

import { SystemBusinessContext } from './context';
import { useCreateSystemBusinessContextStore } from './store';

export const SystemBusinessContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useCreateSystemBusinessContextStore();

  return (
    <SystemBusinessContext.Provider value={store}>
      {props.children}
    </SystemBusinessContext.Provider>
  );
};
