import classNames from 'classnames';
import React, {
  FC,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Color, FieldState, Icon, LoadingState } from '../../../enums';
import useFocused from '../../../hooks/useFocused';
import usePressed from '../../../hooks/usePressed';
import {
  Clearable,
  Disableable,
  Nameable,
  Readable,
  Requireable,
  Styleable,
  Testable,
  Validatable
} from '../../../interfaces/componentProps';
import {
  getFieldStateClass,
  getTestSelectorAttribute,
  uid
} from '../../../utils';
import { AcIconButton } from '../../icon-button';
import { AcSpinner } from '../../spinner';
import '../AcSelect.sass';
import { AcSelectPattern } from '../acSelectPattern';

export interface AcSelectField
  extends Validatable,
    Readable,
    Disableable,
    Styleable,
    Requireable,
    Clearable,
    Testable,
    Nameable {
  value: string;
  label?: string;
  placeholder?: string;
  loading?: LoadingState;
  pattern?: AcSelectPattern;

  onClick?(): void;
  onReload?(): void;
  clearSelectedValue?(): void;
}

const AcSelectField: FC<AcSelectField> = (props: AcSelectField) => {
  const [id, setId] = useState<string>();

  const prefix = 'ac-field';
  const buttonRef = useRef<HTMLButtonElement>(null);
  const focused = useFocused(buttonRef as RefObject<HTMLElement>);
  const pressed = usePressed(buttonRef as RefObject<HTMLElement>);

  const classes = classNames(
    prefix,
    'ac-field-select',
    props.className,
    getFieldStateClass(focused, FieldState.Focused, prefix),
    getFieldStateClass(pressed, FieldState.Pressed, prefix),
    getFieldStateClass(!!props.validation, FieldState.Invalid, prefix),
    getFieldStateClass(!!props.readonly, FieldState.Readonly, prefix),
    getFieldStateClass(!!props.disabled, FieldState.Disabled, prefix),
    `ac-field-${props.pattern || AcSelectPattern.Default}`
  );

  const labelClasses = classNames('ac-field-label', {
    required: props.required
  });

  const isNotClickable = props.disabled || props.readonly;

  const getInputIcon = useCallback(() => {
    if (props.loading === LoadingState.Loading) {
      return <AcSpinner />;
    }

    if (props.pattern === AcSelectPattern.Borderless && props.readonly) {
      return null;
    }

    if (props.loading === LoadingState.LoadingError) {
      return (
        <AcIconButton
          icon={Icon.Restart}
          onClick={props.onReload}
          disabled={isNotClickable}
          color={Color.PrimaryDark}
        />
      );
    }

    if (props.pattern === AcSelectPattern.Dropdown) {
      return (
        <AcIconButton
          icon={Icon.OpenDown}
          onClick={props.onClick}
          disabled={isNotClickable}
          color={Color.PrimaryDark}
        />
      );
    }

    if (!!props.clearable && props.value) {
      return (
        <AcIconButton
          icon={Icon.Cancel}
          onClick={props.clearSelectedValue}
          color={Color.PrimaryDark}
        />
      );
    }

    return (
      <AcIconButton
        icon={Icon.ActionDown}
        onClick={props.onClick}
        disabled={isNotClickable}
        color={Color.PrimaryDark}
      />
    );
  }, [
    props.disabled,
    props.readonly,
    props.loading,
    props.onClick,
    props.clearSelectedValue,
    props.clearable,
    props.value,
    props.onReload,
    props.pattern
  ]);

  useEffect(() => setId(props.name || uid()), [props.name]);

  return (
    <div
      className={classes}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      {props.label && (
        <label className={labelClasses} htmlFor={id}>
          {props.label}
        </label>
      )}
      <div className="ac-field-background">
        <button
          ref={buttonRef}
          className="ac-field-input"
          onClick={props.onClick}
          disabled={isNotClickable}
          {...getTestSelectorAttribute(props.testSelector, 'input')}
        >
          {props.value && <span className="value"> {props.value} </span>}
          {!props.value && (
            <span className="placeholder"> {props.placeholder} </span>
          )}
        </button>
        {getInputIcon()}
      </div>

      {!!props.validation && (
        <div className="ac-field-error-message">{props.validation}</div>
      )}
    </div>
  );
};

export default AcSelectField;
