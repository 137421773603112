import { AppProps } from 'single-spa';

type ValuesArray<Type> = {
  [key in keyof Type]: Array<Required<Type>[key]>;
};

export type UnknownProps = Record<string, unknown>;

export type ComponentLifecycle<Props = unknown> = (
  props: Props
) => Promise<void>;

export interface ComponentLifeCycles<Props = any> {
  bootstrap: ComponentLifecycle<Props>;
  mount: ComponentLifecycle<Props>;
  unmount: ComponentLifecycle<Props>;
}
export interface ExportedApp<Props = {}>
  extends ComponentLifeCycles<Props & AppProps> {
  update?: ComponentLifecycle<Props>;
}
export type AppToRender<Props = {}> = ValuesArray<ExportedApp<Props>>;

export type ComponentToRender<Props = any> = ValuesArray<
  ComponentLifeCycles<Props>
> & { name?: string };

export const createModuleToRender = <Props = any>(
  lifeCycles: ComponentLifeCycles<Props>
): ValuesArray<ComponentLifeCycles<Props>> =>
  Object.entries(lifeCycles).reduce(
    (moduleToRenderLifeCycles, [lifeCycleName, lifeCycleFunction]) => {
      moduleToRenderLifeCycles[lifeCycleName] = [lifeCycleFunction];

      return moduleToRenderLifeCycles;
    },
    {} as Partial<ValuesArray<ComponentLifeCycles<Props>>>
  ) as ValuesArray<ComponentLifeCycles<Props>>;
