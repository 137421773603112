import classNames from 'classnames';
import React, {
  FC,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { FieldState } from '../../../enums';
import useFocused from '../../../hooks/useFocused';
import usePressed from '../../../hooks/usePressed';
import {
  getFieldStateClass,
  getTestSelectorAttribute,
  uid
} from '../../../utils';
import { AcFieldProps, AcFieldTemplateNodes } from './AcFieldProps';
import { AcFieldInputMode, AcFieldTypes } from './type';

export interface AcFieldBaseProps<T = string> extends AcFieldProps<T> {
  maxlength?: number;
  min?: number;
  max?: number;
  step?: number;
  type: AcFieldTypes;
  inputMode?: AcFieldInputMode;
  pattern?: string;

  template(templateNodes: AcFieldTemplateNodes): ReactNode;
}

const defaultProps: Partial<AcFieldBaseProps> = {
  value: '',
  disabled: false,
  required: false,
  readonly: false,
  type: AcFieldTypes.Text
};

const AcFieldBase: FC<AcFieldBaseProps> = (props: AcFieldBaseProps) => {
  const [id, setId] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);

  const prefix = 'ac-field';
  const focused = useFocused(inputRef as RefObject<HTMLElement>);
  const pressed = usePressed(inputRef as RefObject<HTMLElement>);

  const classes = classNames(
    prefix,
    props.className,
    'ac-field-default',
    getFieldStateClass(pressed, FieldState.Pressed, prefix),
    getFieldStateClass(focused, FieldState.Focused, prefix),
    getFieldStateClass(!!props.validation, FieldState.Invalid, prefix),
    getFieldStateClass(!!props.readonly, FieldState.Readonly, prefix),
    getFieldStateClass(!!props.disabled, FieldState.Disabled, prefix)
  );

  function onChange() {
    if (inputRef.current) {
      const newState: string = inputRef.current.value;
      if (props.onChange) {
        props.onChange(newState);
      }
    }
  }

  useEffect(() => setId(props.name || uid()), [props.name]);

  const inputNode: ReactNode = (
    <input
      id={id}
      ref={inputRef}
      type={props.type}
      value={props.value || ''}
      name={props.name}
      placeholder={props.placeholder}
      disabled={props.disabled}
      onChange={onChange}
      readOnly={props.readonly}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      className="ac-field-input"
      maxLength={props.maxlength}
      min={props.min}
      max={props.max}
      step={props.step}
      inputMode={props.inputMode}
      pattern={props.pattern}
      autoComplete={props.autocomplete ? 'on' : 'off'}
      autoCorrect={props.autocomplete ? 'on' : 'off'}
      {...getTestSelectorAttribute(props.testSelector, 'input')}
    />
  );

  const labelNode = useMemo(
    () => (
      <label
        className={`ac-field-label ${props.required ? 'required' : ''}`}
        htmlFor={id}
      >
        {props.label}
      </label>
    ),
    [props.label, props.required, id]
  );

  const counterNode = useMemo(
    () => (
      <>
        {props.maxlength && (
          <span className="ac-field-counter">
            {/*tslint:disable-next-line:jsx-use-translation-function*/}
            {(props.value || '').length}/{props.maxlength}
          </span>
        )}
      </>
    ),
    [props.value, props.maxlength]
  );

  const validationMessageNode = useMemo(
    () => (
      <>
        {!!props.validation && (
          <div className="ac-field-error-message">{props.validation}</div>
        )}
      </>
    ),
    [props.validation]
  );

  return (
    <div
      className={classes}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      {props.template({
        counterNode,
        inputNode,
        labelNode,
        validationMessageNode
      })}
    </div>
  );
};

AcFieldBase.defaultProps = defaultProps;

export default AcFieldBase;
