import { DependencyList, RefObject, useLayoutEffect, useState } from 'react';
import { isColorDark, rgbStringColorToRgb } from '../utils/color';

function useDarkBackground(ref: RefObject<HTMLElement>, deps?: DependencyList) {

  const [dark, setDark] = useState<boolean>(false);

  useLayoutEffect(
    () => {
      if (ref.current) {
        const rgbString = window.getComputedStyle(ref.current).backgroundColor;
        const rgb = rgbStringColorToRgb(rgbString);
        setDark(rgb ? isColorDark(rgb) : false);
      }
    },
    deps
  );

  return dark;
}

export default useDarkBackground;
