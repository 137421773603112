import React, { Fragment, useEffect } from 'react';

import { BaseApi } from '@ac/library-api';

import { getACPNamespace } from '../../namespace/globalNamespace';
import { PropsWithChildren } from '../../reactTransition/propsWithChildren';

let areMetricTrackersInitialized = false;

const initMetricTrackersOnce = async () => {
  const metrics = await getACPNamespace().developerTools?.metrics;

  if (areMetricTrackersInitialized || !metrics) {
    return;
  }

  BaseApi.addPreRequestMiddlewares(metrics.tracking.getApiMiddlewares());
  areMetricTrackersInitialized = true;
};

export const MetricsTrackersInitializer = (
  props: PropsWithChildren
): JSX.Element => {
  useEffect(() => {
    void initMetricTrackersOnce();
  }, []);

  return <Fragment>{props.children}</Fragment>;
};
