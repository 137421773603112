import { Fragment, useEffect } from 'react';

import {
  BusinessContextUnitIdentifier,
  SepModuleBusinessContextConverter,
} from '@ac/library-api';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';

import { FullPageSpinner } from '../../../../../components';
import React from '../../../../../wc';
import { PropsWithChildren } from '../../../../reactTransition/propsWithChildren';
import { getCurrentGlobalOrLoadBusinessContextData } from '../../base';
import { getCurrentTenantId } from '../../tenant/globalTenant';
import { useCentralReservationOfficeBusinessContext } from '../store';

import { registerCentralReservationOfficeBusinessContextUnitChangeHandler } from './registerCentralReservationOfficeBusinessContextUnitChangeHandler';

const getUnitId = (): BusinessContextUnitIdentifier => {
  if (LoginService.isSuperUser()) {
    throw new Error(
      'CentralReservationOfficeModule is not dedicated for SystemUser. ' +
        'Please use ExactCentralReservationOfficeModule instead'
    );
  }

  const tenantId = getCurrentTenantId();
  const centralReservationOfficeId =
    SessionService.getCentralReservationOfficeId();

  if (!tenantId) {
    throw new Error(
      'Missing TenantId. Cannot use CentralReservationOfficeBusinessContext'
    );
  }

  if (!centralReservationOfficeId) {
    throw new Error(
      'Missing CroID in the cookie. Cannot use CentralReservationOfficeBusinessContext'
    );
  }

  return {
    tenantId,
    centralReservationOfficeId,
    // todo: propertyId is not saved in the session service (cookies) and cannot be instantiated by default
  };
};

export const GlobalCentralReservationOfficeBusinessContextLoader = (
  props: PropsWithChildren
): JSX.Element => {
  const { state, setBusinessContext } =
    useCentralReservationOfficeBusinessContext();

  useEffect(() => {
    const initializeContext = async () => {
      const contextData = await getCurrentGlobalOrLoadBusinessContextData(
        getUnitId()
      );

      const businessContext =
        new SepModuleBusinessContextConverter().buildCentralReservationOfficeContext(
          contextData
        );

      setBusinessContext(businessContext);

      registerCentralReservationOfficeBusinessContextUnitChangeHandler(
        setBusinessContext
      );
    };

    void initializeContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state.businessContext ? (
    <Fragment>{props.children}</Fragment>
  ) : (
    <FullPageSpinner isVisible={true} />
  );
};
