import {
  API_LANGUAGE_HEADER,
  Config,
  ConfigBuilder,
  LibraryApiRequestHeaders,
  PROPERTY_ID_HEADER,
  TENANT_ID_HEADER,
} from '@ac/library-api';
import { LoginService, SessionService } from '@ac/library-utils/dist/services';

import { SetupLibraryApiForModuleOptions } from './setUpLibraryApiForModule';

export interface Options {
  injectAuthHeader?: boolean;
  propertyId?: string;
  headers?: Record<string, string>;
}

const buildHeadersWithSelectedProperty = (
  propertyId: string | undefined,
  language: string,
  skipTenantHeader: boolean = false
): LibraryApiRequestHeaders | undefined => {
  if (!LoginService.isLoggedIn()) {
    return;
  }

  let headers: LibraryApiRequestHeaders = {
    [API_LANGUAGE_HEADER]: language,
    'Content-Type': 'application/json; charset=utf-8',
  };

  const authData = LoginService.authData();
  const token = authData?.token;
  const tenantId = authData?.tenantId;

  if (token) {
    headers = {
      ...headers,
      Authorization: `Bearer ${token}`,
    };
  }

  if (tenantId && !skipTenantHeader) {
    headers = {
      ...headers,
      [TENANT_ID_HEADER]: tenantId,
    };
  }

  if (propertyId && !LoginService.isSuperUser()) {
    headers = {
      ...headers,
      [PROPERTY_ID_HEADER]: propertyId,
    };
  }

  return headers;
};

export const getLibraryApiConfigForSepModule = ({
  skipPropertyHeader = false,
}: SetupLibraryApiForModuleOptions = {}): Config => {
  const propertyId = SessionService.getPropertyId();
  const language = SessionService.getLanguage() ?? 'EN';

  return new ConfigBuilder()
    .setHeaders(
      buildHeadersWithSelectedProperty(
        skipPropertyHeader ? undefined : propertyId,
        language,
        true
      )
    )
    .getConfig();
};
