import {
  SepModuleBusinessContextConverter,
  SepModuleBusinessContextData,
  UserContext,
} from '@ac/library-api';

import { getACPNamespace } from '../../../namespace/globalNamespace';

export const registerUserBusinessContextUnitChangeHandler = (
  handler: (context: UserContext) => void
): void => {
  const businessContext = getACPNamespace()?.businessContext;

  if (!businessContext) {
    return;
  }

  const currentOnUnitChange = businessContext.onUnitChanged;
  const newOnUnitChange = (updatedContext: SepModuleBusinessContextData) => {
    const newContext = new SepModuleBusinessContextConverter().buildUserContext(
      updatedContext
    );

    handler(newContext);
  };

  businessContext.onUnitChanged = (context: SepModuleBusinessContextData) => {
    currentOnUnitChange?.(context);
    newOnUnitChange(context);
  };
};
