import {
  CentralReservationOfficeContext,
  isCentralReservationOfficeBusinessContextData,
  SepModuleBusinessContextConverter,
  SepModuleBusinessContextData,
} from '@ac/library-api';

import { getACPNamespace } from '../../../../namespace/globalNamespace';

export const registerCentralReservationOfficeBusinessContextUnitChangeHandler =
  (handler: (context: CentralReservationOfficeContext) => void): void => {
    const businessContext = getACPNamespace()?.businessContext;

    if (!businessContext) {
      return;
    }

    const currentOnUnitChange = businessContext.onUnitChanged;
    const newOnUnitChange = (updatedContext: SepModuleBusinessContextData) => {
      if (!isCentralReservationOfficeBusinessContextData(updatedContext)) {
        return;
      }

      const newContext =
        new SepModuleBusinessContextConverter().buildCentralReservationOfficeContext(
          updatedContext
        );

      handler(newContext);
    };

    businessContext.onUnitChanged = (context: SepModuleBusinessContextData) => {
      currentOnUnitChange?.(context);
      newOnUnitChange(context);
    };
  };
