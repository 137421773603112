import React, { RefObject } from 'react';
import { AcAnchorItem } from './interfaces/AcAnchorItem';
import { AcAnchorListState } from './interfaces/AcAnchorsState';

export interface AcAnchorContextData {
  items: AcAnchorListState;
  rootRef: RefObject<HTMLElement>;
  updateItem(prop: string, item: AcAnchorItem): void;
}

export const AcAnchorContext = React.createContext<AcAnchorContextData | null>(null);
