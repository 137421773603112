import React, {
  FC,
  memo,
  PropsWithChildren,
  Ref,
  useCallback,
  useContext,
  useMemo
} from 'react';
import ReactDOM from 'react-dom';
import AcMobileContext from '../../config/AcMobileContext';
import { FlexDirection, JustifyContent } from '../../enums';
import { CSSPropertiesAndVars } from '../../utils';
import { AcFlex } from '../flex';
import './AcOverlayMessageContainer.sass';
import OverlayMessage from './AcOverlayMessagePrompt';
import { OverlayMessageState, OverlayMessageType } from './models';

import {
  calculateOverlayMessageBottomOffset,
  removeOverlayMessageFromArray
} from './utils';

interface Props {
  messages: OverlayMessageState[];
  bottomOffsetRefs?: Array<Ref<HTMLElement>>;
  onMessagesChange: (newMessages: OverlayMessageState[]) => void;
}

const AcOverlayMessageContainer: FC<PropsWithChildren<Props>> = props => {
  const acMobileContext = useContext(AcMobileContext);

  const offset = useMemo(
    () => calculateOverlayMessageBottomOffset(props.bottomOffsetRefs),
    [props.bottomOffsetRefs]
  );

  const style: CSSPropertiesAndVars = useMemo(
    () => ({
      '--ac-overlay-message-container-offset': `-${offset}px`
    }),
    [offset]
  );

  const onRemove = useCallback(
    (type: OverlayMessageType) => {
      props.onMessagesChange(
        removeOverlayMessageFromArray(type, props.messages)
      );
    },
    [props.messages, props.onMessagesChange]
  );

  const messageQueue: Map<
    OverlayMessageType,
    OverlayMessageState[]
  > = useMemo(() => {
    const messagesMap = new Map<OverlayMessageType, OverlayMessageState[]>();
    props.messages.forEach((message: OverlayMessageState) => {
      if (messagesMap.has(message.type)) {
        messagesMap.get(message.type)!.push(message);
      } else {
        messagesMap.set(message.type, [message]);
      }
    });

    return messagesMap;
  }, [props.messages]);

  const consumeOnlyType = Array.from(messageQueue.keys())[0];

  return (
    <>
      {ReactDOM.createPortal(
        <AcFlex
          style={style}
          className="ac-overlay-message-container"
          direction={FlexDirection.column}
          justifyContent={JustifyContent.flexEnd}
        >
          <AcFlex
            ref={acMobileContext?.overlayNotificationsWrapperRef}
            direction={FlexDirection.column}
            justifyContent={JustifyContent.flexEnd}
          >
            {// even thought we display only one type of messages at a time,
            // we iterate through all types to force react to rerender OverlayMessage.
            // It helps to restart animation/timers etc.
            Array.from(messageQueue.entries()).map(([type, messages]) => {
              return consumeOnlyType === type ? (
                <OverlayMessage
                  key={type}
                  onRemove={onRemove}
                  type={type}
                  messages={messages}
                />
              ) : null;
            })}
          </AcFlex>
        </AcFlex>,
        acMobileContext?.rootElement || document.body
      )}
      {props.children}
    </>
  );
};

export default memo(AcOverlayMessageContainer);
