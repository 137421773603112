import { IEventBus } from '@ac/library-utils/dist/communication/event-bus';

export interface FrontendCommunicationState {
  eventBus: IEventBus;
}

export const getDefaultFrontendCommunicationState = (
  eventBus: IEventBus
): FrontendCommunicationState => ({
  eventBus,
});
