import classNames from 'classnames';
import React, { FC } from 'react';
import { ChildRequired, Styleable, Testable } from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import './AcBadgeList.sass';

export interface AcBadgeListProps extends ChildRequired, Styleable, Testable {
  wrap?: boolean;
}

const defaultProps: AcBadgeListProps = {
  children: null,
  wrap: true
};

const AcBadgeList: FC<AcBadgeListProps> = (props: AcBadgeListProps) => {
  const className = classNames(
    'ac-badge-list',
    { 'ac-badge-list-wrap': props.wrap },
    props.className
  );

  return (
    <div
      className={className}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <div className="ac-badge-list-wrapper">{props.children}</div>
    </div>
  );
};

AcBadgeList.defaultProps = defaultProps;
export default AcBadgeList;
