import React from '../../../wc';
import {
  AuthenticationContextProvider,
  UnauthorizedAccessLocker,
} from '../../authentication';
import { FrontendCommunicationContextProvider } from '../../communication';
import { MetricsTrackersInitializer } from '../../developerTools/metrics/MetricsTrackersInitializer';
import { PropsWithChildren } from '../../reactTransition/propsWithChildren';
import { RouteChangeCallbacks, RoutingContextProvider } from '../../routing';
import {
  UserBusinessContextLoader,
  UserBusinessContextProvider,
} from '../businessContext';

export interface ShijiEnterprisePlatformFeatureProps {
  routeChangeCallbacks?: RouteChangeCallbacks;
  customLoadingView?: JSX.Element;
}

/**
 * Context wrapper for feature. By default it will use `window.history` to change routes. Please provide `routeChangeCallbacks` prop to change this behavior.
 *
 * @param routeChangeCallbacks(optional) Options to override default `window.history` way of route change
 * @param routeChangeCallbacks.onAuthenticateRouteChange(optional) Callback to Change route after successful login
 * @param routeChangeCallbacks.onNavigate(optional) Callback to change any route
 * @param routeChangeCallbacks.onNavigateBack(optional) Callback to go back in history
 */
export const ShijiEnterprisePlatformFeature = (
  props: PropsWithChildren<ShijiEnterprisePlatformFeatureProps>
): JSX.Element => {
  return (
    <FrontendCommunicationContextProvider>
      <MetricsTrackersInitializer>
        <RoutingContextProvider
          routeChangeCallbacks={props.routeChangeCallbacks}
        >
          <AuthenticationContextProvider>
            <UnauthorizedAccessLocker
              customLoadingView={props.customLoadingView}
            >
              <UserBusinessContextProvider>
                <UserBusinessContextLoader
                  customLoadingView={props.customLoadingView}
                >
                  {props.children}
                </UserBusinessContextLoader>
              </UserBusinessContextProvider>
            </UnauthorizedAccessLocker>
          </AuthenticationContextProvider>
        </RoutingContextProvider>
      </MetricsTrackersInitializer>
    </FrontendCommunicationContextProvider>
  );
};
