import { UserContext } from '@ac/library-api';

export interface UserBusinessContextState {
  businessContext: UserContext | undefined;
}

export const getDefaultUserBusinessContextState =
  (): UserBusinessContextState => ({
    businessContext: undefined,
  });
