import { Fragment, useEffect } from 'react';

import { SepModuleBusinessContextConverter } from '@ac/library-api';
import { LoginService } from '@ac/library-utils/dist/services';

import { FullPageSpinner } from '../../../../components';
import React from '../../../../wc';
import { PropsWithChildren } from '../../../reactTransition/propsWithChildren';
import { getCurrentGlobalOrLoadBusinessContextData } from '../base';

import { registerSystemBusinessContextUnitChangeHandler } from './registerSystemBusinessContextUnitChangeHandler';
import { useSystemBusinessContext } from './store';

interface SystemBusinessContextLoaderProps {
  customLoadingView?: JSX.Element;
}

export const SystemBusinessContextLoader = (
  props: PropsWithChildren<SystemBusinessContextLoaderProps>
): JSX.Element => {
  if (!LoginService.isLoggedIn()) {
    throw new Error('Please login to use load tenant work context');
  }

  const { state, setBusinessContext } = useSystemBusinessContext();

  useEffect(() => {
    const initializeContext = async () => {
      const contextData = await getCurrentGlobalOrLoadBusinessContextData({});
      const businessContext =
        new SepModuleBusinessContextConverter().buildSystemContext(contextData);

      setBusinessContext(businessContext);
      registerSystemBusinessContextUnitChangeHandler(setBusinessContext);
    };

    void initializeContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state.businessContext ? (
    <Fragment>{props.children}</Fragment>
  ) : (
    props.customLoadingView ?? <FullPageSpinner isVisible={true} />
  );
};
