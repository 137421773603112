import classNames from 'classnames';
import React, { forwardRef, ForwardRefRenderFunction, memo, Ref } from 'react';
import {
  ChildRequired,
  Styleable,
  Testable
} from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import './AcDropdownList.sass';

export interface AcButtonDropdownItemProps
  extends Styleable,
    ChildRequired,
    Testable {}

const AcDropdownList: ForwardRefRenderFunction<
  HTMLDivElement,
  AcButtonDropdownItemProps
> = (props: AcButtonDropdownItemProps, ref: Ref<HTMLDivElement>) => {
  const className = classNames('ac-button-dropdown-list', props.className);

  return (
    <div
      ref={ref}
      className={className}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      {props.children}
    </div>
  );
};

export default memo(forwardRef(AcDropdownList));
