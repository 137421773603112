import classNames from 'classnames';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  HTMLAttributes,
  memo,
  PropsWithChildren
} from 'react';
import { Testable } from '../../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../../utils';
import './AcFooter.sass';

export interface AcFooterProps
  extends HTMLAttributes<HTMLDivElement>,
    Testable {
  noPadding?: boolean;
}

const AcFooter: ForwardRefRenderFunction<
  HTMLDivElement,
  PropsWithChildren<AcFooterProps>
> = (
  { className: classNameProp, noPadding, testSelector, children, ...divProps },
  ref
) => {
  const className = classNames(
    'ac-footer',
    noPadding && 'ac-footer-no-padding',
    classNameProp
  );

  return (
    <footer
      ref={ref}
      {...divProps}
      className={className}
      {...getTestSelectorAttribute(testSelector)}
    >
      {children}
    </footer>
  );
};

const forward = forwardRef(AcFooter);
export default memo(forward);
