import classNames from 'classnames';
import React, {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useRef,
  useState
} from 'react';
import { FileInputAccept, FileInputCapture } from '../../enums';
import { Changeable } from '../../interfaces/componentProps';
import { uid } from '../../utils';
import { AcButton } from '../button';
import { AcButtonProps } from '../button/AcButton';
import './AcFileButton.sass';

export interface AcFileButtonProps
  extends Omit<AcButtonProps, 'onChange'>,
    Changeable<File> {
  accept?: Array<string | FileInputAccept>;
  capture?: FileInputCapture;
}

const AcFileButton: FC<PropsWithChildren<AcFileButtonProps>> = props => {
  const [inputKey, setInputKey] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    accept,
    capture,
    onChange,
    className,
    onClick,
    ...buttonProps
  } = props;
  const filesAccept: string = (props.accept || []).join(',');
  const buttonClassNames = classNames('ac-file-button', props.className);

  const onButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onClick?.(e);
      inputRef.current?.click();
    },
    []
  );

  function onInputChange() {
    if (inputRef.current && inputRef.current.files!.length && props.onChange) {
      const file: File = inputRef.current.files![0];
      props.onChange(file);
    }
    resetInput();
  }

  function resetInput() {
    setInputKey(uid());
  }

  return (
    <AcButton
      {...buttonProps}
      className={buttonClassNames}
      onClick={onButtonClick}
    >
      <input
        ref={inputRef}
        className="ac-file-button-input"
        type="file"
        key={inputKey}
        accept={filesAccept}
        onChange={onInputChange}
        capture={props.capture}
      />
      {props.children}
    </AcButton>
  );
};

export default memo(AcFileButton);
