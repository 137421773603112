import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { AlignItems, Color, TextSize, TextWeight } from '../../enums';
import {
  Childless,
  Disableable,
  Requireable,
  Styleable,
  Testable,
  Themeable,
  Validatable
} from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import { AcFlex } from '../flex';
import { AcRadioButton } from '../radio-button';
import { AcText } from '../text';

export interface SingleRadioProps extends Disableable {
  label: string;
  value: string;
}

interface AcRadioGroupProps
  extends Disableable,
    Themeable,
    Styleable,
    Testable,
    Childless,
    Validatable,
    Requireable {
  name: string;
  label: string;
  value?: string;
  radioList: SingleRadioProps[];
  onChange(state: string): void;
}

/* tslint:disable:jsx-no-lambda */
const AcRadioGroup: FC<AcRadioGroupProps> = (props: AcRadioGroupProps) => {
  const classes = classNames('ac-field', 'ac-radio-group', props.className);
  const labelClass = classNames({ required: props.required });

  return (
    <div
      className={classes}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <AcFlex alignItems={AlignItems.center}>
        <AcText
          color={Color.Gray1}
          size={TextSize.Main2}
          weight={TextWeight.Semibold}
          className={labelClass}
        >
          {props.label}
        </AcText>
        <div className="ac-radio-buttons ac-spacing-left-md">
          {props.radioList.map(radioItem => (
            <AcRadioButton
              name={props.name}
              key={radioItem.value}
              label={radioItem.label}
              contractedTouchArea={true}
              className="ac-spacing-left-md ac-spacing-right-md"
              disabled={props.disabled || radioItem.disabled}
              checked={props.value === radioItem.value}
              onChange={() => props.onChange(radioItem.value)}
              theme={props.theme}
              testSelector={`${props.testSelector}-${radioItem.value}`}
            />
          ))}
        </div>
      </AcFlex>
      {!!props.validation && (
        <div className="ac-field-error-message">{props.validation}</div>
      )}
    </div>
  );
};

export default memo(AcRadioGroup);
