export const extractScriptPath = (
  selectorOfScriptStart: RegExp,
  indexFile: string
): string => {
  const scriptOccurenceStartIndex = indexFile.search(selectorOfScriptStart);
  if (scriptOccurenceStartIndex < 0) {
    throw new Error('Script file not found!');
  }

  const scriptStart = indexFile.substring(scriptOccurenceStartIndex);

  const endScriptIndex = scriptStart.search(/[",'`]/);
  if (endScriptIndex < 0) {
    throw new Error('Cannot find end of script');
  }

  return scriptStart.substring(0, endScriptIndex);
};
