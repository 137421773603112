import classNames from 'classnames';
import React, {
  CSSProperties,
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import { Color } from '../../enums';
import { Disableable, Styleable } from '../../interfaces/componentProps';
import { getBackgroundColorClass, uid } from '../../utils';
import './AcRipple.sass';

export enum AcRippleMode {
  Over,
  Under
}

export interface AcRippleProps extends Disableable, Styleable {
  color?: Color;
  size?: number;
  mode?: AcRippleMode;
  ripplesContainerStyle?: CSSProperties;
  ripplesContainerClassName?: string;
}

interface Ripple {
  id: string;
  x: number;
  y: number;
}

const defaultProps: AcRippleProps = {
  color: Color.PrimaryDark,
  size: 32,
  mode: AcRippleMode.Over,
  disabled: false
};

const AcRipple: FC<PropsWithChildren<AcRippleProps>> = (
  props: PropsWithChildren<AcRippleProps>
) => {
  const [ripples, setRipples] = useState<Ripple[]>([]);
  const areaRef = useRef<HTMLDivElement>(null);
  const maxRipples: number = 4;

  const isOver: boolean = props.mode === AcRippleMode.Over;

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      const currentElement = areaRef.current;
      if (!currentElement || props.disabled) {
        return;
      }
      const newId = uid();
      const rect = currentElement.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const newRipple: Ripple = { x, y, id: newId };
      setRipples(currentRipples =>
        [...currentRipples, newRipple].slice(-maxRipples)
      );
    },
    [props.disabled]
  );

  const onRippleEnd = useCallback((finishedRipple: Ripple) => {
    setRipples(currentRipples =>
      currentRipples.filter(ripple => ripple !== finishedRipple)
    );
  }, []);

  const rippleElements = useMemo(
    () =>
      ripples.map((ripple: Ripple) => {
        const rippleClassName = classNames(
          'ac-ripple-circle',
          props.color ? getBackgroundColorClass(props.color) : null
        );
        const rippleStyle: CSSProperties = {
          width: props.size,
          height: props.size,
          left: ripple.x,
          top: ripple.y
        };
        const onAnimationEnd = () => onRippleEnd(ripple);

        return (
          <div
            key={ripple.id}
            className={rippleClassName}
            style={rippleStyle}
            onAnimationEnd={onAnimationEnd}
          />
        );
      }),
    [ripples, props.color, props.size]
  );

  const container = useMemo(
    () => (
      <div
        className={classNames(
          'ac-ripple-container',
          props.ripplesContainerClassName
        )}
        style={props.ripplesContainerStyle}
      >
        {rippleElements}
      </div>
    ),
    [
      rippleElements,
      props.ripplesContainerClassName,
      props.ripplesContainerStyle
    ]
  );

  const className = classNames(props.className, 'ac-ripple');

  return (
    <div
      ref={areaRef}
      className={className}
      style={props.style}
      onMouseDown={onClick}
    >
      {!isOver && container}
      {props.children}
      {isOver && container}
    </div>
  );
};

AcRipple.defaultProps = defaultProps;
export default memo(AcRipple);
