import { RefObject, useEffect, useState } from 'react';

function useFocused(ref: RefObject<HTMLElement>) {

  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('focus', onFocus);
      ref.current.addEventListener('blur', onBlur);
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener('focus', onFocus);
        ref.current.removeEventListener('blur', onBlur);
      }
    };
  });

  function onFocus() {
    setFocused(true);
  }

  function onBlur() {
    setFocused(false);
  }

  return focused;
}

export default useFocused;
