import { SystemContext } from '@ac/library-api';

export interface SystemBusinessContextState {
  businessContext: SystemContext | undefined;
}

export const getDefaultSystemBusinessContextState =
  (): SystemBusinessContextState => ({
    businessContext: undefined,
  });
