import { Fragment, useEffect } from 'react';

import { SepModuleBusinessContextConverter } from '@ac/library-api';

import { FullPageSpinner } from '../../../../../components';
import React from '../../../../../wc';
import { PropsWithChildren } from '../../../../reactTransition/propsWithChildren';
import { getCurrentGlobalOrLoadBusinessContextData } from '../../base';
import { getCurrentTenantId } from '../../tenant/globalTenant';
import { useCentralReservationOfficeBusinessContext } from '../store';

interface Props {
  tenantId?: string;
  croId: string;
  propertyId?: string;
}

export const ExactCentralReservationOfficeBusinessContextLoader = (
  props: PropsWithChildren<Props>
): JSX.Element => {
  const { state, setBusinessContext } =
    useCentralReservationOfficeBusinessContext();

  useEffect(() => {
    const initializeContext = async () => {
      const tenantId = props.tenantId ?? getCurrentTenantId();

      if (!tenantId) {
        throw new Error(
          'Cannot create Exact Central Reservation Office Business Context without tenant ID'
        );
      }

      const contextData = await getCurrentGlobalOrLoadBusinessContextData({
        tenantId,
        centralReservationOfficeId: props.croId,
        propertyId: props.propertyId,
      });

      const businessContext =
        new SepModuleBusinessContextConverter().buildCentralReservationOfficeContext(
          contextData
        );

      setBusinessContext(businessContext);
    };

    void initializeContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.croId, props.tenantId]);

  return state.businessContext ? (
    <Fragment>{props.children}</Fragment>
  ) : (
    <FullPageSpinner isVisible={true} />
  );
};
