import { ReactNode } from 'react';

export interface OverlayMessageState {
  readonly id: string;
  readonly type: OverlayMessageType;
  readonly node: ReactNode;
}

export enum OverlayMessageType {
  Error = 'error',
  Warning = 'warning',
  Success = 'success'
}

export interface NewOverlayMessage {
  node: ReactNode;
  id?: string;
}

export interface NewTypedOverlayMessage extends NewOverlayMessage {
  type: OverlayMessageType;
}
