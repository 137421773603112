import classNames from 'classnames';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  HTMLAttributes,
  memo,
  PropsWithChildren
} from 'react';
import { FlexDirection } from '../../../enums';
import { AcFlex } from '../../flex';
import './AcFooterWideButtons.sass';

interface Props extends HTMLAttributes<HTMLDivElement> {}

const AcFooterWideButtons: ForwardRefRenderFunction<
  HTMLDivElement,
  PropsWithChildren<Props>
> = ({ className: classNameProp, children, ...divProps }, ref) => {
  const className = classNames('ac-footer-wide-buttons', classNameProp);

  return (
    <AcFlex
      direction={FlexDirection.column}
      ref={ref}
      {...divProps}
      className={className}
    >
      {children}
    </AcFlex>
  );
};

const forward = forwardRef(AcFooterWideButtons);
export default memo(forward);
