const breakpoints = {
  formSm: '(max-width: 1023px)',
  formMd: '(min-width: 1024px) and (max-width: 1365px)',
  formLg: '(min-width: 1366px) and (max-width: 1599px)',
  formXlg: '(min-width: 1600px)',
  mobile: '(max-width: 1365px)',
  desktop: '(min-width: 1366px)',
};

export const checkMedia = (key: keyof typeof breakpoints): boolean => {
  const query = breakpoints[key];

  return Boolean(query && window.matchMedia(query).matches);
};
