import { useCallback, useState } from 'react';

import { CentralReservationOfficeContext } from '@ac/library-api';

import {
  CentralReservationOfficeBusinessContextState,
  getDefaultCentralReservationOfficeBusinessContextState,
} from './state';

export interface CentralReservationOfficeBusinessContextStore {
  state: CentralReservationOfficeBusinessContextState;

  setBusinessContext(businessContext: CentralReservationOfficeContext): void;
}

export const useCreateCentralReservationOfficeBusinessContextStore =
  (): CentralReservationOfficeBusinessContextStore => {
    const [state, setState] = useState(
      getDefaultCentralReservationOfficeBusinessContextState()
    );

    const setBusinessContext = useCallback(
      (businessContext: CentralReservationOfficeContext) => {
        setState((prevState) => ({
          ...prevState,
          businessContext,
        }));
      },
      []
    );

    return {
      state,
      setBusinessContext,
    };
  };
