export { PublicComponentWrapper } from './stable/react';

import React from 'react';
import { ComponentToRender } from '../../rendering/shared';
import {
  createPublicReactModule as createPublicLegacyReactModule,
  createPublicReactComponent as createPublicLegacyReactComponent,
  WithReactRenderer as WithLegacyReactRenderer,
  CreatePublicReactModuleParams as CreatePublicLegacyReactModuleParams,
  CreatePublicReactComponentParams as CreatePublicLegacyReactComponentParams,
} from './legacy/react';
import {
  createPublicReactModule as createPublicStableReactModule,
  createPublicReactComponent as createPublicStableReactComponent,
  createPublicReactComponentV2,
  WithReactRenderer as WithStableReactRenderer,
  CreatePublicReactModuleParams as CreatePublicStableReactModuleParams,
  CreatePublicReactComponentParams as CreatePublicStableReactComponentParams,
  CreatePublicReactComponentParamsV2,
  LoadRootComponent,
  RenderableComponent,
  RenderableComponentProps,
} from './stable/react';

export {
  LoadRootComponent,
  RenderableComponent,
  RenderableComponentProps,
  CreatePublicReactComponentParamsV2,
  createPublicReactComponentV2,
};

const shouldRunStableVersion = (
  params: WithStableReactRenderer | WithLegacyReactRenderer
): params is WithStableReactRenderer => {
  const isReact18 = React.version.startsWith('18');
  const isReactDOMClient = !!(params as WithStableReactRenderer).ReactDOMClient;

  if (isReact18 && !isReactDOMClient) {
    // eslint-disable-next-line no-console
    console.error(
      `You are using react in version 18 but you are NOT importing ReactDOM from "react-dom/client" (instead, you imported ReactDOM from "react-dom").
      Your module is running in old, legacy mode which will be disabled soon. Adjust your codebase!`
    );
  }

  return isReactDOMClient;
};

export type CreatePublicReactModuleParams<ModuleProps = {}> =
  | CreatePublicStableReactModuleParams<ModuleProps>
  | CreatePublicLegacyReactModuleParams<ModuleProps>;

const isCreatePublicStableReactModuleParams = <ModuleProps>(
  params: CreatePublicReactModuleParams<ModuleProps>
): params is CreatePublicStableReactModuleParams<ModuleProps> =>
  shouldRunStableVersion(params);

/**
 * For React17 please use ReactDOM from "react-dom",
 *
 * For React18 please use ReactDOMClient from "react-dom/client"
 */
export const createPublicReactModule = <ModuleProps>(
  params: CreatePublicReactModuleParams<ModuleProps>
) => {
  if (isCreatePublicStableReactModuleParams(params)) {
    return createPublicStableReactModule(params);
  }

  return createPublicLegacyReactModule(params);
};

/**
 * @deprecated use CreatePublicReactComponentParamsV2
 */
export type CreatePublicReactComponentParams<ComponentProps = {}> =
  | CreatePublicStableReactComponentParams<ComponentProps>
  | CreatePublicLegacyReactComponentParams<ComponentProps>;

const isCreatePublicStableReactComponentParams = <ModuleProps>(
  params: CreatePublicReactComponentParams<ModuleProps>
): params is CreatePublicStableReactComponentParams<ModuleProps> =>
  shouldRunStableVersion(params);

/**
 * @deprecated use createPublicComponent from @ac/react-infrastructure
 *
 * Stable version:
 * import { createPublicComponent } from '@ac/react-infrastructure/dist/features/microfrontends/publicComponents/react/stable'
 *
 * For React17 please use ReactDOM from "react-dom",
 *
 * For React18 please use ReactDOM: ReactDOMClient from "react-dom/client"
 */ export const createPublicReactComponent = <ComponentProps = {}>(
  params: CreatePublicReactComponentParams<ComponentProps>
): ComponentToRender<ComponentProps> => {
  if (isCreatePublicStableReactComponentParams(params)) {
    return createPublicStableReactComponent(params);
  }

  return createPublicLegacyReactComponent(params);
};
