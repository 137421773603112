import { BreadcrumbsSlot } from '@ac/web-components';

import {
  defaultNavigateBackCallback,
  defaultNavigateCallback,
  OnNavigateBackCallback,
  OnNavigateCallback,
} from '../../../features/routing/storage/state';
import React from '../../../wc';

import { IBreadcrumbHistoryItem } from './historyItem';
import { ILabelString, Label } from './label';

const getLabelText = (label?: Label): string => {
  switch (true) {
    case typeof label === 'string':
      return label as string;
    case !label:
      return '';
    default:
      return (label as ILabelString).string;
  }
};

export interface IBreadcrumbsProps {
  title?: string;
  prevStates?: IBreadcrumbHistoryItem[];
  class?: string;
  id?: string;
  testSelector?: string;
  children?: React.ReactNode;
  onNavigate?: OnNavigateCallback;
  onNavigateBack?: OnNavigateBackCallback;
}

const Breadcrumbs = ({
  id,
  title,
  children,
  prevStates,
  testSelector,
  class: className,
  onNavigate = defaultNavigateCallback,
  onNavigateBack = defaultNavigateBackCallback,
}: IBreadcrumbsProps): JSX.Element => {
  return (
    <ac-breadcrumbs
      id={id}
      class={className}
      pageTitle={title}
      onBackButtonClickCallback={onNavigateBack}
      data-test-selector={testSelector}
    >
      <div slot={BreadcrumbsSlot.items}>
        {prevStates && prevStates.length
          ? prevStates.map((state, index) => (
              <ac-dropdown-button-item
                key={index}
                label={getLabelText(state.label)}
                onClick={(): void => {
                  onNavigate(state.link);
                }}
              />
            ))
          : null}
      </div>
      {children}
    </ac-breadcrumbs>
  );
};

export default Breadcrumbs;
