import classNames from 'classnames';
import React, { FC, memo, RefObject, useEffect, useRef, useState } from 'react';
import { FieldState } from '../../enums';
import usePressed from '../../hooks/usePressed';
import {
  Childless,
  Contractable,
  Disableable,
  Focusable,
  Nameable,
  Styleable,
  Testable,
  Themeable
} from '../../interfaces/componentProps';
import {
  getFieldStateClass,
  getTestSelectorAttribute,
  getThemeClass,
  randomString
} from '../../utils';
import './AcRadioButton.sass';

interface AcRadioProps
  extends Childless,
    Focusable,
    Styleable,
    Disableable,
    Nameable,
    Themeable,
    Testable,
    Contractable {
  label?: string;
  checked?: boolean;

  onChange?(): void;
}

const defaultProps: AcRadioProps = {
  checked: false
};

const AcRadioButton: FC<AcRadioProps> = (props: AcRadioProps) => {
  const [id, setId] = useState<string>();
  const inputRef = useRef<HTMLInputElement>(null);

  const ref = useRef<HTMLLabelElement>(null);
  const pressed = usePressed(ref as RefObject<HTMLElement>);

  useEffect(() => setId(`${props.name}-${randomString()}`), [props.name]);

  const className: string = classNames(
    'ac-radio',
    'ac-toggle-input',
    getThemeClass(props, 'ac-toggle-input'),
    { 'ac-radio-contracted': props.contractedTouchArea },
    getFieldStateClass(pressed, FieldState.Pressed, 'ac-toggle-input'),
    props.className
  );

  function onChange() {
    if (inputRef.current && !props.disabled) {
      inputRef.current.checked = true;
      if (props.onChange) {
        props.onChange();
      }
    }
  }

  return (
    <label
      tabIndex={props.disabled ? -1 : 0}
      className={className}
      style={props.style}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      htmlFor={id}
      ref={ref}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <input
        id={id}
        type="radio"
        tabIndex={-1}
        ref={inputRef}
        name={props.name}
        onChange={onChange}
        disabled={props.disabled}
        checked={props.checked}
        className="ac-toggle-input-element"
        {...getTestSelectorAttribute(props.testSelector, 'input')}
      />
      <div className="ac-toggle-input-area">
        <div className="ac-toggle-input-focus-circle" />
        <div className="ac-toggle-input-background">
          <div className="ac-toggle-input-indicator" />
        </div>
      </div>
      {!!props.label && (
        <div className="ac-toggle-input-label">{props.label}</div>
      )}
    </label>
  );
};

AcRadioButton.defaultProps = defaultProps;

export default memo(AcRadioButton);
