import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { Color, TextSize, TextWeight } from '../../enums';
import { TextStyle } from '../../enums/textStyle';
import {
  ChildRequired,
  Styleable,
  Testable
} from '../../interfaces/componentProps';
import {
  getColorClass,
  getEqualLineHeightClass,
  getFontSizeClass,
  getFontStyleClass,
  getFontWeightClass,
  getLineHeightClass,
  getTestSelectorAttribute
} from '../../utils';
import './AcText.sass';

export interface AcTextProps extends ChildRequired, Styleable, Testable {
  color?: Color;
  weight?: TextWeight;
  size?: TextSize;
  fontStyle?: TextStyle;
  equalLineHeight?: boolean;
  uppercase?: boolean;
  ellipsis?: boolean;
}

const AcText: FC<AcTextProps> = (props: AcTextProps) => {
  const className: string = classNames(
    'ac-text',
    props.className,
    props.color ? getColorClass(props.color) : undefined,
    props.size ? getFontSizeClass(props.size) : undefined,
    props.size
      ? props.equalLineHeight
        ? getEqualLineHeightClass(props.size)
        : getLineHeightClass(props.size)
      : undefined,
    props.fontStyle ? getFontStyleClass(props.fontStyle) : undefined,
    props.weight ? getFontWeightClass(props.weight) : undefined,
    { 'ac-text-uppercase': props.uppercase },
    { 'ac-text-ellipsis': props.ellipsis }
  );

  return (
    <span
      className={className}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      {props.children}
    </span>
  );
};

export default memo(AcText);
