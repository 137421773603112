import { initializeAppSizeManagement } from './appSizeManager';
import { initializeDoubleTapZoomBlock } from './blockDoubleTapZoom';
import { initializeIosBounceBlock } from './blockIosBounce';
import { initializeIOSZoomBlock } from './blockIosZoom';

export function initializeAppLikeBehaviour() {
  initializeAppSizeManagement();
  initializeDoubleTapZoomBlock();
  initializeIosBounceBlock();
  initializeIOSZoomBlock();
}
