import classNames from 'classnames';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  HTMLAttributes,
  memo,
  PropsWithChildren
} from 'react';
import { Testable } from '../../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../../utils';
import './AcSubheader.sass';

export interface AcSubheaderProps
  extends HTMLAttributes<HTMLDivElement>,
    Testable {
  noPadding?: boolean;
}

const AcSubheader: ForwardRefRenderFunction<
  HTMLDivElement,
  PropsWithChildren<AcSubheaderProps>
> = (
  { className: classNameProp, noPadding, testSelector, children, ...divProps },
  ref
) => {
  const className = classNames(
    'ac-subheader',
    noPadding && 'ac-subheader-no-padding',
    classNameProp
  );

  return (
    <div
      ref={ref}
      {...divProps}
      className={className}
      {...getTestSelectorAttribute(testSelector)}
    >
      {children}
    </div>
  );
};

const forward = forwardRef(AcSubheader);
export default memo(forward);
