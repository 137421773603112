export enum Color {
  PrimaryDark = 'primary-dark',
  PrimarySelected = 'primary-selected',
  PrimaryFocus = 'primary-focus',
  PrimaryPressed = 'primary-pressed',
  PrimaryRegular = 'primary-regular',
  PrimaryLight = 'primary-light',
  Black = 'black',
  Gray1 = 'gray-1',
  Gray2 = 'gray-2',
  Gray3 = 'gray-3',
  Gray4 = 'gray-4',
  Gray5 = 'gray-5',
  Gray6 = 'gray-6',
  Gray7 = 'gray-7',
  White = 'white',
  Alert = 'alert',
  AlertLight = 'alert-light',
  Warning = 'warning',
  WarningLight = 'warning-light',
  Good = 'good',
  Purple = 'purple',
  GoodLight = 'good-light',
  InformationDark = 'information-dark',
  InformationLight = 'information-light',
  RoomStatusPickup = 'room-status-pu',
  RoomStatusInspected = 'room-status-in',
  RoomStatusDirty = 'room-status-di',
  RoomStatusClean = 'room-status-cl',
  OutOfService = 'out-of-service',
  DiscrepancySkip = 'discrepancy-skip',
  DiscrepancySleep = 'discrepancy-sleep',
  DiscrepancyOccupancy = 'discrepancy-occupancy',
  MaintenanceActive = 'maintenance-active',
  MaintenancePending = 'maintenance-pending',
  AttendantTaskInProgress = 'attendant-task-in-progress',
  AttendantTaskCompleted = 'attendant-task-completed',
  AttendantTaskSkipped = 'attendant-task-skipped',
  AttendantTaskPaused = 'attendant-task-paused',
  AttendantTaskPending = 'attendant-task-pending',
  Incognito = 'incognito',
  ServiceDeferred = 'service-deferred'
}
