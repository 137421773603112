import classNames from 'classnames';
import React, {
  FC,
  memo,
  PropsWithChildren,
  useContext,
  useEffect
} from 'react';
import { Styleable } from '../../../interfaces/componentProps';
import AcAccordionElementProps from '../interface/AcAccordionElementProps';
import AcAccordionItemState from '../interface/AcAccordionItemState';
import {
  AcAccordionContext,
  AcAccordionContextData
} from './AcAccordionContext';

export interface AcAccordionsItemProps extends AcAccordionItemState, Styleable {
  render?(childProps: AcAccordionElementProps): React.ReactNode;
}

const AcAccordionItem: FC<PropsWithChildren<AcAccordionsItemProps>> = (
  props: PropsWithChildren<AcAccordionsItemProps>
) => {
  const context: AcAccordionContextData = useContext(AcAccordionContext)!;

  useEffect(() => {
    context.registerItem(props.id, {
      id: props.id,
      opened: !!props.opened,
      disabled: !!props.disabled
    });

    return () => {
      context.unregisterItem(props.id);
    };
  }, []);

  const classes = classNames(props.className, 'ac-accordion-item', {
    'ac-accordion-expanded': context.isItemExpanded(props.id),
    'ac-accordion-collapsed': !context.isItemExpanded(props.id),
    'ac-accordion-disable': context.isItemDisabled(props.id)
  });

  const childrenWithProps = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(
        child as React.ReactElement<{ relatedWithItemId: string }>,
        {
          relatedWithItemId: props.id
        }
      );
    }

    return null;
  });

  return (
    <div id={props.id} className={classes}>
      {props.render
        ? props.render({ relatedWithItemId: props.id })
        : childrenWithProps}
    </div>
  );
};

export default memo(AcAccordionItem);
