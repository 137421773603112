import classNames from 'classnames';
import React, { FC, memo, ReactNode } from 'react';
import { Icon } from '../../enums';
import { Clickable } from '../../interfaces/componentProps';
import { isDefined } from '../../utils/isDefined';
import { AcButton, AcButtonPattern, AcButtonShape } from '../button';
import { AcButtonContent } from '../button-content';
import AcFieldBase from './ac-field-component/AcFieldBase';
import {
  AcFieldProps,
  AcFieldTemplateNodes
} from './ac-field-component/AcFieldProps';
import {
  AcFieldInputMode,
  AcFieldNumberVariant,
  AcFieldTypes
} from './ac-field-component/type';

interface AcFieldNumberProps extends AcFieldProps<number>, Clickable {
  min?: number;
  max?: number;
  step?: number;
  integer?: boolean;
  precision?: number;
  variant?: AcFieldNumberVariant;
}

const defaultProps: Partial<AcFieldNumberProps> = {
  value: 0,
  variant: AcFieldNumberVariant.Normal
};

const AcFieldNumber: FC<AcFieldNumberProps> = (props: AcFieldNumberProps) => {
  const classes: string = classNames(
    'ac-field-number',
    {
      'ac-field-borderless': props.variant === AcFieldNumberVariant.Borderless
    },
    props.className
  );

  function onFieldChange(newValue: string) {
    if (props.onChange) {
      let numeric = Number.parseFloat(newValue);
      let isValid = true;
      if (!isNaN(numeric)) {
        numeric = Math.max(
          !isDefined(props.min) ? Number.MIN_SAFE_INTEGER : props.min!,
          Math.min(
            !isDefined(props.max) ? Number.MAX_SAFE_INTEGER : props.max!,
            numeric
          )
        );
        if (props.integer && Math.floor(numeric) !== numeric) {
          isValid = false;
        }
      }

      if (isValid) {
        props.onChange(
          parseFloat(numeric.toFixed(props.precision ? props.precision : 0))
        );
      }
    }
  }

  const { value, onChange, step, ...nonValueProps } = props;
  const normalizedStep: number = props.integer
    ? Math.floor(step || 1)
    : step || 1;

  const buttonVariant =
    props.variant === AcFieldNumberVariant.Borderless
      ? AcButtonPattern.Tertiary
      : AcButtonPattern.Light;

  function increment() {
    const val = props.value ? props.value : 0;
    onFieldChange((val + normalizedStep).toString());
  }

  function decrement() {
    const val = props.value ? props.value : 0;
    onFieldChange((val - normalizedStep).toString());
  }

  function numberFieldTemplate(templateNodes: AcFieldTemplateNodes): ReactNode {
    return (
      <>
        <div className="ac-field-header">
          {templateNodes.labelNode}
          {templateNodes.counterNode}
        </div>
        <div className="ac-field-background">
          <AcButton
            onClick={decrement}
            contractedTouchArea={true}
            shape={AcButtonShape.Rectangle}
            pattern={buttonVariant}
            disabled={
              props.disabled ||
              props.readonly ||
              (isDefined(props.min) &&
                isDefined(props.value) &&
                props.value! <= props.min!)
            }
          >
            <AcButtonContent icon={Icon.Minus} />
          </AcButton>
          {templateNodes.inputNode}
          <AcButton
            onClick={increment}
            contractedTouchArea={true}
            shape={AcButtonShape.Rectangle}
            pattern={buttonVariant}
            disabled={
              props.disabled ||
              props.readonly ||
              (isDefined(props.max) &&
                isDefined(props.value) &&
                props.value! >= props.max!)
            }
          >
            <AcButtonContent icon={Icon.Plus} />
          </AcButton>
        </div>
        {templateNodes.validationMessageNode}
      </>
    );
  }

  return (
    <AcFieldBase
      {...nonValueProps}
      type={AcFieldTypes.Number}
      className={classes}
      testSelector={props.testSelector}
      pattern={'[0-9]*'}
      inputMode={AcFieldInputMode.Decimal}
      onChange={onFieldChange}
      value={value === null || value === undefined ? '' : value.toString()}
      template={numberFieldTemplate}
    />
  );
};

AcFieldNumber.defaultProps = defaultProps;

export default memo(AcFieldNumber);
