import classNames from 'classnames';
import React, { FC, memo, RefObject, useEffect, useRef, useState } from 'react';
import { Color, FieldState, Icon } from '../../enums';
import useFocused from '../../hooks/useFocused';
import usePressed from '../../hooks/usePressed';
import {
  Changeable,
  Childless,
  Disableable,
  Focusable,
  Nameable,
  Readable,
  Requireable,
  Styleable,
  Testable,
  Validatable
} from '../../interfaces/componentProps';
import { getFieldStateClass, getTestSelectorAttribute, uid } from '../../utils';
import { dayjs } from '../../utils/dayjs-extended';
import { AcIconButton } from '../icon-button';
import { AcFieldTypes, defaultOutputFormat } from './ac-field-component/type';

export interface AcFieldDateProps
  extends Childless,
    Styleable,
    Disableable,
    Focusable,
    Nameable,
    Validatable,
    Requireable,
    Readable,
    Changeable<string | undefined>,
    Testable {
  value?: string;
  min?: string;
  max?: string;
  label?: string;
  placeholder?: string;
  format: string;
  outputUTC?: boolean;

  onClick?(): void;
}

const AcFieldDate: FC<AcFieldDateProps> = (props: AcFieldDateProps) => {
  const [id, setId] = useState<string>();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const prefix = 'ac-field';
  const focused = useFocused(buttonRef as RefObject<HTMLElement>);
  const pressed = usePressed(buttonRef as RefObject<HTMLElement>);

  const HTML5inputDateFormat = 'YYYY-MM-DD';
  const inputValue = getDateValue(props.value, HTML5inputDateFormat);
  const displayValue = getDateValue(props.value, props.format);
  const minDateValue = getDateValue(props.min, HTML5inputDateFormat, undefined);
  const maxDateValue = getDateValue(props.max, HTML5inputDateFormat, undefined);

  function getDateValue(
    date: string | undefined,
    format: string,
    defaultValue: string = ''
  ): string {
    if (!!date) {
      const dateMoment = dayjs(date);

      return dateMoment.isValid() ? dateMoment.format(format) : defaultValue;
    }

    return '';
  }

  const classes = classNames(
    prefix,
    props.className,
    'ac-field-datetimepicker',
    getFieldStateClass(pressed, FieldState.Pressed, prefix),
    getFieldStateClass(focused, FieldState.Focused, prefix),
    getFieldStateClass(!!props.validation, FieldState.Invalid, prefix),
    getFieldStateClass(!!props.readonly, FieldState.Readonly, prefix),
    getFieldStateClass(!!props.disabled, FieldState.Disabled, prefix)
  );

  const labelClasses = classNames('ac-field-label', {
    required: !!props.required
  });

  const onChange = (): void => {
    if (inputRef.current) {
      const oldState = dayjs(props.value);
      const newState = inputRef.current.value
        ? dayjs(inputRef.current.value)
            .set('hour', oldState.get('hour'))
            .set('minute', oldState.get('minute'))
        : undefined;

      if (props.onChange) {
        props.onChange(
          !newState
            ? undefined
            : props.outputUTC
            ? dayjs.utc(newState, HTML5inputDateFormat).format()
            : dayjs(newState, HTML5inputDateFormat).format(defaultOutputFormat)
        );
      }
    }
  };

  useEffect(() => setId(props.name || uid()), [props.name]);

  function onClick() {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  }

  useEffect(() => {
    if (inputRef.current) {
      // needed for clearing
      inputRef.current.defaultValue = '';
    }
  });

  return (
    <div
      className={classes}
      style={props.style}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <div className="ac-field-header">
        <label className={labelClasses} htmlFor={id}>
          {props.label}
        </label>
      </div>
      <div className="ac-field-background ac-field-default">
        <input
          id={id}
          type={AcFieldTypes.Date}
          value={inputValue}
          name={props.name}
          disabled={props.disabled}
          onChange={onChange}
          readOnly={props.readonly}
          className="ac-field-datetime"
          ref={inputRef}
          onBlur={props.onBlur}
          required={props.required}
          min={minDateValue}
          max={maxDateValue}
          {...getTestSelectorAttribute(props.testSelector, 'input')}
        />

        <button ref={buttonRef} className="ac-field-input" onClick={onClick}>
          {displayValue && <span className="value"> {displayValue} </span>}
          {!displayValue && (
            <span className="placeholder"> {props.placeholder} </span>
          )}
        </button>
        <AcIconButton
          icon={Icon.Calendar}
          onClick={onClick}
          color={Color.PrimaryDark}
        />
      </div>
      {!!props.validation && (
        <div className="ac-field-error-message">{props.validation}</div>
      )}
    </div>
  );
};

export default memo(AcFieldDate);
