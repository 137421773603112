import classNames from 'classnames';
import React, { FC, memo, RefObject, useEffect, useRef, useState } from 'react';
import { FieldState, Icon, IconSize } from '../../enums';
import usePressed from '../../hooks/usePressed';
import {
  Changeable,
  Childless,
  Contractable,
  Disableable,
  Focusable,
  Nameable,
  Styleable,
  Testable,
  Themeable
} from '../../interfaces/componentProps';
import {
  getFieldStateClass,
  getTestSelectorAttribute,
  getThemeClass,
  randomString
} from '../../utils';
import { AcIcon } from '../icon';
import './AcCheckbox.sass';

enum VisibleState {
  Checked,
  Indeterminate
}

export interface AcCheckboxProps
  extends Childless,
    Focusable,
    Styleable,
    Disableable,
    Changeable<boolean>,
    Nameable,
    Themeable,
    Contractable,
    Testable {
  label?: string;
  checked?: boolean;
  indeterminate?: boolean;
}

const defaultProps: AcCheckboxProps = {
  checked: false,
  indeterminate: false
};

const AcCheckbox: FC<AcCheckboxProps> = (props: AcCheckboxProps) => {
  const ref = useRef<HTMLLabelElement>(null);
  const pressed = usePressed(ref as RefObject<HTMLElement>);
  const [id, setId] = useState<string>();
  const [lastVisible, setLastVisible] = useState<VisibleState>(
    VisibleState.Checked
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const displayIcon = props.indeterminate
    ? VisibleState.Indeterminate
    : props.checked
    ? VisibleState.Checked
    : lastVisible;
  const className: string = classNames(
    'ac-checkbox',
    'ac-toggle-input',
    { 'ac-checkbox-contracted': props.contractedTouchArea },
    getThemeClass(props, 'ac-toggle-input'),
    getFieldStateClass(pressed, FieldState.Pressed, 'ac-toggle-input'),
    props.className
  );

  useEffect(() => {
    if (props.checked || props.indeterminate) {
      setLastVisible(
        props.checked ? VisibleState.Checked : VisibleState.Indeterminate
      );
    }
  }, [props.checked, props.indeterminate]);

  useEffect(() => setId(props.name || randomString()), [props.name]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.indeterminate = !!props.indeterminate;
    }
  });

  function onChange() {
    if (inputRef.current) {
      const newState: boolean = inputRef.current.checked;
      inputRef.current.checked = !!props.checked;
      inputRef.current.indeterminate = !!props.indeterminate;
      if (props.onChange) {
        props.onChange(newState);
      }
    }
  }

  return (
    <label
      tabIndex={props.disabled ? -1 : 0}
      className={className}
      style={props.style}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      htmlFor={id}
      ref={ref}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      <input
        className="ac-toggle-input-element"
        tabIndex={-1}
        name={props.name}
        id={id}
        ref={inputRef}
        type="checkbox"
        disabled={props.disabled}
        onChange={onChange}
        checked={props.checked}
        {...getTestSelectorAttribute(props.testSelector, 'input')}
      />
      <div className="ac-toggle-input-area">
        <div className="ac-toggle-input-focus-circle" />
        <div className="ac-toggle-input-background">
          <div className="ac-toggle-input-text-indicator">
            <AcIcon
              size={IconSize.Small}
              icon={
                displayIcon === VisibleState.Checked ? Icon.Check : Icon.Minus
              }
            />
          </div>
        </div>
      </div>
      {!!props.label && (
        <div className="ac-toggle-input-label">{props.label}</div>
      )}
    </label>
  );
};

AcCheckbox.defaultProps = defaultProps;
export default memo(AcCheckbox);
