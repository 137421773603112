import { getTimestamp } from '../utils';

let lastTouchEnd = 0;

export function initializeDoubleTapZoomBlock() {
  addEventListener('touchend', preventDoubleTapsZoom, false);
}

function preventDoubleTapsZoom(event: { preventDefault: () => void }): void {
  const now = getTimestamp();
  if (now - lastTouchEnd <= 300) {
    event.preventDefault();
  }
  lastTouchEnd = now;
}
