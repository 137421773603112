import { isEqual, merge, xorBy } from 'lodash';
import React, { ReactNode } from 'react';
import { AcSelectTranslation, AcSelectValue } from '..';
import { AlignItems, Icon, JustifyContent } from '../../../enums';
import { Testable } from '../../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../../utils';
import { AcButton, AcButtonPattern } from '../../button';
import { AcButtonContent } from '../../button-content';
import { AcCheckbox } from '../../checkbox';
import { AcFieldSearch } from '../../field';
import { AcFlex } from '../../flex';
import {
  AcModal,
  AcModalBody,
  AcModalFooter,
  AcModalHeader,
  AcModalSubHeader
} from '../../modal';
import { AcRadioButton } from '../../radio-button';
import { AcText } from '../../text';

interface AcSelectListProps extends Testable {
  label?: string;
  showSearch?: boolean;
  multiple: boolean;
  isVisible: boolean;
  showInputs: boolean;
  defaultSelectedItems: string | string[];
  itemsList: AcSelectValue[];
  translations?: AcSelectTranslation;
  forceUpdate?: boolean;

  onClose(): void;
  updateSelect(item: string[]): void;
  itemTemplate?(item: AcSelectValue): ReactNode;
}

interface ACSelectListState {
  searchState: string;
  selectedItems: string[];
}

enum ListState {
  Empty,
  PartiallySelected,
  AllSelected
}

const getDefaultSelection = (props: AcSelectListProps): string[] => {
  return Array.isArray(props.defaultSelectedItems)
    ? props.defaultSelectedItems
    : [props.defaultSelectedItems];
};

/* tslint:disable:jsx-no-lambda */
class AcSelectList extends React.PureComponent<
  AcSelectListProps,
  ACSelectListState
> {
  public static defaultTranslations: AcSelectTranslation = {
    searchPlaceholderTranslation: 'Fill',
    searchLabelTranslation: 'Type to see matching results.',
    itemNotFoundTranslation: 'Item not found',
    confirmButtonTranslation: 'Confirm',
    checkAllButtonTranslation: 'Check all',
    clearAllButtonTranslation: 'Clear all',
    clearButtonTranslation: 'Clear'
  };

  public static defaultItemTemplate(item: AcSelectValue): JSX.Element {
    return <AcText>{item.itemLabel}</AcText>;
  }

  public translations: AcSelectTranslation;

  public readonly state = {
    searchState: '',
    selectedItems: getDefaultSelection(this.props)
  };

  constructor(props: AcSelectListProps) {
    super(props);

    this.translations = merge(
      AcSelectList.defaultTranslations,
      props.translations
    );
  }

  public onSelectItem = (item: AcSelectValue): void => {
    /* single select */
    if (!this.props.multiple) {
      this.setState(
        {
          selectedItems: [item.value]
        },
        () => this.onConfirmModal()
      );
    } else {
      /* multi select select */
      this.setState(prevState => ({
        selectedItems: xorBy(prevState.selectedItems, [item.value])
      }));
    }
  };

  public onConfirmModal = (): void => {
    const { selectedItems } = this.state;

    if (this.props.forceUpdate || this.shouldUpdateValues(selectedItems)) {
      this.props.updateSelect(selectedItems);
    }

    this.props.onClose();
  };

  public onCancel = () => {
    this.setState({
      selectedItems: getDefaultSelection(this.props)
    });
    this.props.onClose();
  };

  public shouldUpdateValues = (values: string[]): boolean => {
    return !isEqual(getDefaultSelection(this.props).sort(), values.sort());
  };

  public searchFilter = (item: AcSelectValue): boolean => {
    return (
      !!item.itemLabel &&
      item.itemLabel
        .toLowerCase()
        .indexOf(this.state.searchState.toLowerCase()) !== -1
    );
  };

  public isChecked = (item: AcSelectValue): boolean => {
    return this.state.selectedItems.some(
      (selectedItem: string) => item.value === selectedItem
    );
  };

  public getListSelectionStatus(): ListState {
    if (this.state.selectedItems.length === 0) {
      return ListState.Empty;
    }

    if (this.props.itemsList.length > this.state.selectedItems.length) {
      return ListState.PartiallySelected;
    }

    return ListState.AllSelected;
  }

  public autoSelectionHandler = (): void => {
    switch (this.getListSelectionStatus()) {
      case ListState.Empty:
        this.setState({
          selectedItems: this.props.itemsList.map(item => item.value)
        });
        break;
      case ListState.AllSelected:
      case ListState.PartiallySelected:
        this.setState({ selectedItems: [] });
    }
  };

  public render() {
    const filteredList: AcSelectValue[] = this.props.itemsList.filter(
      this.searchFilter
    );
    const buttonStatus: ListState = this.getListSelectionStatus();

    return (
      <AcModal
        isOpen={this.props.isVisible}
        onClose={this.onCancel}
        className={'ac-modal-select'}
      >
        <AcModalHeader testSelector={`${this.props.testSelector}-modal-header`}>
          {this.props.label}
        </AcModalHeader>
        {this.props.showSearch && (
          <AcModalSubHeader>
            <div className="ac-field-select-search">
              <AcFieldSearch
                value={this.state.searchState}
                label={this.translations.searchLabelTranslation}
                placeholder={this.translations.searchPlaceholderTranslation}
                onChange={value => this.setState({ searchState: value })}
                testSelector={`${this.props.testSelector}-search`}
              />
            </div>
          </AcModalSubHeader>
        )}
        <AcModalBody>
          {filteredList && (
            <ul className="ac-select-list">
              {filteredList.length > 0 ? (
                filteredList.map((item, key) => {
                  return (
                    // tslint:disable-next-line:jsx-no-lambda
                    <li
                      className="ac-select-list-item"
                      key={key}
                      onClick={() => this.onSelectItem(item)}
                    >
                      {this.props.showInputs &&
                        (this.props.multiple ? (
                          <AcCheckbox
                            checked={this.isChecked(item)}
                            testSelector={`${this.props.testSelector}-${item.value}-checkbox`}
                          />
                        ) : (
                          <AcRadioButton
                            checked={this.isChecked(item)}
                            testSelector={`${this.props.testSelector}-${item.value}-radio`}
                          />
                        ))}

                      <span
                        {...getTestSelectorAttribute(
                          this.props.testSelector,
                          `item-${item.value}`
                        )}
                      >
                        {this.props.itemTemplate
                          ? this.props.itemTemplate(item)
                          : AcSelectList.defaultItemTemplate(item)}
                      </span>
                    </li>
                  );
                })
              ) : (
                <li className="ac-select-list-item ac-select-item-not-found">
                  {this.translations.itemNotFoundTranslation}
                </li>
              )}
            </ul>
          )}
        </AcModalBody>
        {this.props.multiple && (
          <AcModalFooter className="multi-select-footer-container">
            <AcFlex
              justifyContent={JustifyContent.spaceBetween}
              alignItems={AlignItems.center}
              className="multi-select-footer"
            >
              {buttonStatus === ListState.Empty && (
                <AcButton
                  pattern={AcButtonPattern.Tertiary}
                  onClick={this.autoSelectionHandler}
                  {...getTestSelectorAttribute(
                    this.props.testSelector,
                    'button-empty'
                  )}
                >
                  <AcButtonContent
                    icon={Icon.Check}
                    text={this.translations.checkAllButtonTranslation}
                  />
                </AcButton>
              )}

              {buttonStatus === ListState.PartiallySelected && (
                <AcButton
                  pattern={AcButtonPattern.Tertiary}
                  onClick={this.autoSelectionHandler}
                  {...getTestSelectorAttribute(
                    this.props.testSelector,
                    'button-partially'
                  )}
                >
                  <AcButtonContent
                    icon={Icon.Cancel}
                    text={this.translations.clearButtonTranslation}
                  />
                </AcButton>
              )}

              {buttonStatus === ListState.AllSelected && (
                <AcButton
                  pattern={AcButtonPattern.Tertiary}
                  onClick={this.autoSelectionHandler}
                  {...getTestSelectorAttribute(
                    this.props.testSelector,
                    'button-all'
                  )}
                >
                  <AcButtonContent
                    icon={Icon.Cancel}
                    text={this.translations.clearAllButtonTranslation}
                  />
                </AcButton>
              )}

              <AcButton
                onClick={this.onConfirmModal}
                {...getTestSelectorAttribute(
                  this.props.testSelector,
                  'button-confirm'
                )}
              >
                <AcButtonContent
                  text={this.translations.confirmButtonTranslation}
                />
              </AcButton>
            </AcFlex>
          </AcModalFooter>
        )}
      </AcModal>
    );
  }
}

export default AcSelectList;
