import classNames from 'classnames';
import React, { FC, memo, ReactNode } from 'react';
import { Icon } from '../../enums';
import { Clickable, Nameable } from '../../interfaces/componentProps';
import { AcIconButton } from '../icon-button';
import AcFieldBase from './ac-field-component/AcFieldBase';
import {
  AcFieldProps,
  AcFieldTemplateNodes
} from './ac-field-component/AcFieldProps';
import { AcFieldTypes } from './ac-field-component/type';

interface AcFieldTextProps extends AcFieldProps, Nameable, Clickable {
  icon?: Icon;
  maxlength?: number;
}

const AcFieldText: FC<AcFieldTextProps> = (props: AcFieldTextProps) => {
  const classes: string = classNames('ac-field-text', props.className);

  function textFieldTemplate(templateNodes: AcFieldTemplateNodes): ReactNode {
    return (
      <>
        <div className="ac-field-header">
          {templateNodes.labelNode}
          {templateNodes.counterNode}
        </div>
        <div className="ac-field-background">
          {templateNodes.inputNode}
          {props.icon && (
            <AcIconButton
              icon={props.icon}
              disabled={props.disabled || props.readonly}
              onClick={props.onClick}
            />
          )}
        </div>
        {templateNodes.validationMessageNode}
      </>
    );
  }

  return (
    <AcFieldBase
      {...props}
      className={classes}
      type={AcFieldTypes.Text}
      testSelector={props.testSelector}
      template={textFieldTemplate}
    />
  );
};

export default memo(AcFieldText);
