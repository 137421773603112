// Copy of "iNoBounce" with a hotfix for horizontal scrolling

// tslint:disable
export function initializeIosBounceBlock() {
  let startY = 0;
  let startX = 0;

  let supportsPassiveOption = false;
  try {
    let opts = Object.defineProperty({}, 'passive', {
      get: function() {
        supportsPassiveOption = true;
      }
    });
    window.addEventListener('test', () => {}, opts);
  } catch {}

  let handleTouchmove = function(evt: any) {
    // Get the element that was scrolled upon
    let el = evt.target as any;

    // Allow zooming
    let zoom = window.innerWidth / window.document.documentElement.clientWidth;
    if (evt.touches.length > 1 || zoom !== 1) {
      return;
    }

    // Check all parent elements for scrollability
    while (
      el !== document.body &&
      el !== document.documentElement &&
      el !== document
    ) {
      // Get some style properties
      let style = window.getComputedStyle(el);

      if (!style) {
        // If we've encountered an element we can't compute the style for, get out
        break;
      }

      // Ignore range input element
      if (el.nodeName === 'INPUT' && el.getAttribute('type') === 'range') {
        return;
      }

      let scrolling = style.getPropertyValue('-webkit-overflow-scrolling');
      let overflowY = style.getPropertyValue('overflow-y');
      let overflowX = style.getPropertyValue('overflow-x');
      let height = parseInt(style.getPropertyValue('height'), 10);
      let width = parseInt(style.getPropertyValue('width'), 10);

      // Determine if the element should scroll
      let isScrollableY =
        scrolling === 'touch' &&
        (overflowY === 'auto' || overflowY === 'scroll');
      let isScrollableX =
        scrolling === 'touch' &&
        (overflowX === 'auto' || overflowX === 'scroll');
      let canScrollY = el.scrollHeight > el.offsetHeight;
      let canScrollX = el.scrollWidth > el.offsetWidth;

      if (isScrollableY && canScrollY) {
        // Get the current Y position of the touch
        let curY = evt.touches ? evt.touches[0].screenY : evt.screenY;

        // Determine if the user is trying to scroll past the top or bottom
        // In this case, the window will bounce, so we have to prevent scrolling completely
        let isAtTop = startY <= curY && el.scrollTop === 0;
        let isAtBottom =
          startY >= curY && el.scrollHeight - el.scrollTop === height;

        // Stop a bounce bug when at the bottom or top of the scrollable element
        if (isAtTop || isAtBottom) {
          evt.preventDefault();
        }

        // No need to continue up the DOM, we've done our job
        return;
      }
      if (isScrollableX && canScrollX) {
        // Get the current Y position of the touch
        let curX = evt.touches ? evt.touches[0].screenX : evt.screenX;

        // Determine if the user is trying to scroll past the top or bottom
        // In this case, the window will bounce, so we have to prevent scrolling completely
        let isAtLeft = startX <= curX && el.scrollLeft === 0;
        let isAtRight =
          startX >= curX && el.scrollWidth - el.scrollLeft === width;

        // Stop a bounce bug when at the bottom or top of the scrollable element
        if (isAtLeft || isAtRight) {
          evt.preventDefault();
        }

        // No need to continue up the DOM, we've done our job
        return;
      }

      // Test the next parent
      el = el.parentNode;
    }

    // Stop the bouncing -- no parents are scrollable
    evt.preventDefault();
  };

  let handleTouchstart = function(evt: any) {
    // Store the first Y position of the touch
    startY = evt.touches ? evt.touches[0].screenY : evt.screenY;
    startX = evt.touches ? evt.touches[0].screenX : evt.screenX;
  };

  let enable = function() {
    // Listen to a couple key touch events
    window.addEventListener(
      'touchstart',
      handleTouchstart,
      supportsPassiveOption ? { passive: false } : false
    );
    window.addEventListener(
      'touchmove',
      handleTouchmove,
      supportsPassiveOption ? { passive: false } : false
    );
  };

  // Enable by default if the browser supports -webkit-overflow-scrolling
  // Test this by setting the property with JavaScript on an element that exists in the DOM
  // Then, see if the property is reflected in the computed style
  let testDiv = document.createElement('div');
  document.documentElement.appendChild(testDiv);
  testDiv.style['WebkitOverflowScrolling'] = 'touch';
  let scrollSupport =
    'getComputedStyle' in window &&
    window.getComputedStyle(testDiv)['-webkit-overflow-scrolling'] === 'touch';
  document.documentElement.removeChild(testDiv);

  if (scrollSupport) {
    enable();
  }
}
