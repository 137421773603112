import { useCallback, useState } from 'react';

import {
  ConfigurationEntitiesAggregate,
  PropertyContext,
} from '@ac/library-api';

import { PropertyDateTimeFormatSettings } from '../dateTimeFormats/propertyDateTimeFormatSettings';

import {
  getDefaultPropertyBusinessContextState,
  PropertyBusinessContextState,
} from './state';

export interface PropertyBusinessContextStore {
  state: PropertyBusinessContextState;

  setBusinessContext(businessContext: PropertyContext): void;
  setPropertyDateTimeFormatSettings(
    propertyDateTimeFormatSettings: PropertyDateTimeFormatSettings
  ): void;
  setConfigurationEntities(
    configurationEntities: ConfigurationEntitiesAggregate
  ): void;
}

export const useCreatePropertyBusinessContextStore =
  (): PropertyBusinessContextStore => {
    const [state, setState] = useState(
      getDefaultPropertyBusinessContextState()
    );

    const setBusinessContext = useCallback(
      (businessContext: PropertyContext) => {
        setState((prevState) => ({
          ...prevState,
          businessContext,
          configurationEntities: businessContext.configurationEntities.entities,
        }));
      },
      []
    );

    const setPropertyDateTimeFormatSettings = useCallback(
      (propertyDateTimeFormatSettings: PropertyDateTimeFormatSettings) => {
        setState((prevState) => ({
          ...prevState,
          propertyDateTimeFormatSettings,
        }));
      },
      []
    );

    const setConfigurationEntities = (
      configurationEntities: ConfigurationEntitiesAggregate
    ): void => {
      setState((prevState) => ({
        ...prevState,
        configurationEntities,
      }));
    };

    return {
      state,
      setBusinessContext,
      setPropertyDateTimeFormatSettings,
      setConfigurationEntities,
    };
  };
