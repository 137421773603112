import React from '../../../../../wc';
import { PropsWithChildren } from '../../../../reactTransition/propsWithChildren';

import { CentralReservationOfficeBusinessContext } from './context';
import { useCreateCentralReservationOfficeBusinessContextStore } from './store';

export const CentralReservationOfficeBusinessContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useCreateCentralReservationOfficeBusinessContextStore();

  return (
    <CentralReservationOfficeBusinessContext.Provider value={store}>
      {props.children}
    </CentralReservationOfficeBusinessContext.Provider>
  );
};
