import React, { FC, memo } from 'react';
import { AcSelectValue } from '../select';
import '../select/AcSelect.sass';
import AcSelectBase from '../select/components/AcSelectBase';
import { AcSelectGeneric } from '../select/interfaces/AcSelectGeneric';

export interface AcSingleSelectProps extends AcSelectGeneric<string[]> {
  onChange(selectedItem: string[]): void;
}

const AcMultiSelect: FC<AcSingleSelectProps> = (props: AcSingleSelectProps) => {

  function clearSelectedValue(): void {
      props.onChange([]);
  }

  function getSelectedLabels(): string {
    const selectedValues: string[] = props.selectedItem.map(selected => {
      const foundedItem = props.itemsList.find((item: AcSelectValue) => item.value === selected);

      return foundedItem ? foundedItem.inputLabel || foundedItem.itemLabel : getUnlistedLabel();
    });

    return selectedValues.filter(Boolean).join(', ');
  }

  function getUnlistedLabel(): string {
    return props.unlistedValueLabel || '';
  }

  function isSelectEmpty(): boolean {
    return !props.selectedItem.length;
  }

  function onUpdate(selectedItems: string[]): void {
    props.onChange(selectedItems);
  }

  return (
    <AcSelectBase<string[]>
      {...props}
      clearSelectedValue={clearSelectedValue}
      getSelectedLabels={getSelectedLabels}
      isSelectEmpty={isSelectEmpty}
      onUpdate={onUpdate}
      multiply={true}
      translations={props.translations}
      testSelector={props.testSelector}
    />
  );
};

export default memo(AcMultiSelect);
