import { LoginService } from '@ac/library-utils/dist/services';
import { isInContainer } from '@ac/library-utils/dist/utils/micro-frontends';

import { AuthenticatedUserType } from './types/AuthenticatedUserType';

export const checkIfUserIsAuthenticated = (): AuthenticatedUserType => {
  const isAuthenticated = isInContainer()
    ? LoginService.isLoggedIn()
    : LoginService.init();

  return isAuthenticated
    ? AuthenticatedUserType.Authenticated
    : AuthenticatedUserType.Anonymous;
};
