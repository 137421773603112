/** To support React 17+ */
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { AppProps } from 'single-spa';

import singleSpaReact from 'single-spa-react-legacy';
import { isDefined } from '../../../../isDefined';
import {
  CreatePublicReactModuleParams as CreatePublicStableReactModuleParams,
  CreatePublicReactComponentParams as CreatePublicStableReactComponentParams,
  createLoadRootComponentFunction,
} from '../stable/react';
import {
  AppToRender,
  ComponentToRender,
  createModuleToRender,
} from '../../../rendering/shared';
import { WithoutKeys } from '../../../../../declarations/types';

type RenderType = 'hydrate' | 'render';

type ReactRenderer = {
  [T in RenderType]?: unknown;
};

type LegacyReact = typeof React;

export interface WithReactRenderer {
  ReactDOM: ReactRenderer;
}

export interface CreatePublicReactModuleParams<ModuleProps = {}>
  extends WithoutKeys<
      CreatePublicStableReactModuleParams<ModuleProps>,
      'ReactDOMClient' | 'React'
    >,
    WithReactRenderer {
  React: LegacyReact;
}

export const createPublicReactModule = <ModuleProps>(
  params: CreatePublicReactModuleParams<ModuleProps>
): AppToRender => {
  const lifeCycles = createModuleToRender(
    singleSpaReact({
      // casting explained in `stable` version
      ...(params as CreatePublicReactModuleParams<ModuleProps & AppProps>),
      ReactDOM: params.ReactDOM as typeof ReactDOM,
    })
  );

  return {
    ...lifeCycles,
    bootstrap: [...lifeCycles.bootstrap, params.initApp].filter(isDefined),
  };
};

export interface CreatePublicReactComponentParams<ComponentProps = {}>
  extends WithoutKeys<
      CreatePublicStableReactComponentParams<ComponentProps>,
      'ReactDOMClient' | 'React'
    >,
    WithReactRenderer {
  React: LegacyReact;
  name?: string;
}

export const createPublicReactComponent = <ComponentProps extends {} = {}>(
  params: CreatePublicReactComponentParams<ComponentProps>
): ComponentToRender<ComponentProps> => {
  const loadRootComponent = createLoadRootComponentFunction(
    params.loadRootComponent,
    params.wrapWith
  );

  const componentLifeCycles = createModuleToRender(
    singleSpaReact({
      ...params,
      ReactDOM: params.ReactDOM as typeof ReactDOM,
      loadRootComponent,
    })
  );

  return {
    ...componentLifeCycles,
    bootstrap: [
      ...componentLifeCycles.bootstrap,
      params.initModuleForComponentUsage,
    ].filter(isDefined),
    name: params.name,
  };
};
