import { RefObject, useEffect, useState } from 'react';

interface TimerIdStorage {
  id: number | undefined;
}

function usePressed(ref: RefObject<HTMLElement>) {
  const pressLength = 250;
  const [pressed, setPressed] = useState(false);
  const [timer] = useState<TimerIdStorage>({ id: undefined });

  useEffect(() => {
    window.addEventListener('touchend', onTouchEnd, { passive: true });
    const element = ref.current;
    if (element) {
      element.addEventListener('touchstart', onTouchStart, { passive: true });
    }

    return () => {
      cancelTimer();
      window.removeEventListener('touchend', onTouchEnd);
      if (element) {
        element.removeEventListener('touchstart', onTouchStart);
      }
    };
  }, [ref.current]);

  function cancelTimer() {
    if (timer.id) {
      window.clearTimeout(timer.id);
      timer.id = undefined;
    }
  }

  function startTimer(callback: () => void) {
    cancelTimer();
    timer.id = window.setTimeout(callback, pressLength);
  }

  function onTouchStart() {
    cancelTimer();
    setPressed(true);
  }

  function onTouchEnd() {
    startTimer(() => setPressed(false));
  }

  return pressed;
}

export default usePressed;
