import classNames from 'classnames';
import React, {
  FC,
  memo,
  ReactNode,
  useContext,
  useEffect,
  useRef
} from 'react';
import { Icon, IconSize } from '../../enums';
import {
  Clickable,
  Disableable,
  Styleable,
  Testable,
  Themeable
} from '../../interfaces/componentProps';
import { formatTestSelector } from '../../utils';
import {
  AcButton,
  AcButtonPattern,
  AcButtonShape,
  AcButtonSize
} from '../button';
import { AcIcon } from '../icon';
import './AcFab.sass';
import AcFloatingContext from './AcFabContext';

export interface AcFabMainButtonProps
  extends Styleable,
    Themeable,
    Clickable,
    Disableable,
    Testable {
  id: string;
  icon: Icon;
  expandedIcon?: Icon;
  pattern?: AcButtonPattern;
  children?: ReactNode;
}

const defaultProps: Partial<AcFabMainButtonProps> = {
  pattern: AcButtonPattern.Primary
};

const AcFab: FC<AcFabMainButtonProps> = (props: AcFabMainButtonProps) => {
  const acFloatingContext = useContext(AcFloatingContext)!;

  const prefix = 'ac-fab-main-button';
  const className: string = classNames(prefix, props.className);

  const onClick = () => {
    if (props.children) {
      acFloatingContext.getExpandedItem() === props.id
        ? acFloatingContext.closeExpandedItem()
        : acFloatingContext.setItemToExpand(props.id);
    } else if (acFloatingContext.getExpandedItem()) {
      acFloatingContext.closeExpandedItem(props.onClick);
    } else if (props.onClick) {
      props.onClick();
    }
  };

  const isExpanded = acFloatingContext.getExpandedItem() === props.id;

  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    acFloatingContext.registerFab(ref);

    return () => {
      acFloatingContext.unregisterFab(ref);
    };
  }, [acFloatingContext.registerFab, acFloatingContext.unregisterFab]);

  return (
    <div className={`${className}`}>
      {!!props.children && (
        <div
          className={`${prefix}-children-list ${isExpanded ? 'visible' : ''}`}
        >
          {props.children}
        </div>
      )}
      <AcButton
        ref={ref}
        className={`${prefix} fab-button`}
        backgroundClassName="fab-button-background"
        shape={AcButtonShape.Circular}
        size={AcButtonSize.Large}
        pattern={props.pattern}
        theme={props.theme}
        onClick={onClick}
        testSelector={props.testSelector}
        disabled={props.disabled || acFloatingContext.disableAllButtons}
      >
        <AcIcon
          testSelector={formatTestSelector(props.testSelector, 'icon')}
          size={IconSize.Large}
          icon={
            isExpanded && props.expandedIcon ? props.expandedIcon : props.icon
          }
        />
      </AcButton>
    </div>
  );
};

AcFab.defaultProps = defaultProps;

export default memo(AcFab);
