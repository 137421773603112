import { Fragment, useEffect } from 'react';

import { SepModuleBusinessContextConverter } from '@ac/library-api';

import { FullPageSpinner } from '../../../../components';
import React from '../../../../wc';
import { PropsWithChildren } from '../../../reactTransition/propsWithChildren';
import { getCurrentGlobalOrLoadBusinessContextData } from '../base';

import { registerUserBusinessContextUnitChangeHandler } from './registerUserBusinessContextUnitChangeHandler';
import { useUserBusinessContext } from './store';

interface UserBusinessContextLoaderProps {
  customLoadingView?: JSX.Element;
}

export const UserBusinessContextLoader = (
  props: PropsWithChildren<UserBusinessContextLoaderProps>
): JSX.Element => {
  const { state, setBusinessContext } = useUserBusinessContext();

  useEffect(() => {
    const initializeContext = async () => {
      const contextData = await getCurrentGlobalOrLoadBusinessContextData({});
      const businessContext =
        new SepModuleBusinessContextConverter().buildUserContext(contextData);

      setBusinessContext(businessContext);
      registerUserBusinessContextUnitChangeHandler(setBusinessContext);
    };

    void initializeContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return state.businessContext ? (
    <Fragment>{props.children}</Fragment>
  ) : (
    props.customLoadingView ?? <FullPageSpinner isVisible={true} />
  );
};
