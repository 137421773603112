import { useCallback, useState } from 'react';

import { AuthenticatedUserType } from '../types';

import { AuthenticationState, getDefaultAuthenticationState } from './state';

export interface AuthenticationStore {
  state: AuthenticationState;

  setAuthenticationResult(userType: AuthenticatedUserType): void;
}

export const useAuthenticationStore = (): AuthenticationStore => {
  const [state, setState] = useState(getDefaultAuthenticationState());

  const setAuthenticationResult = useCallback(
    (userType: AuthenticatedUserType) => {
      setState((previousState) => ({
        ...previousState,
        authenticatedUserType: userType,
      }));
    },
    []
  );

  return {
    state,
    setAuthenticationResult,
  };
};
