import classNames from 'classnames';
import React, {
  forwardRef,
  ForwardRefRenderFunction,
  HTMLAttributes,
  memo,
  PropsWithChildren,
  ReactNode
} from 'react';
import { Color } from '../../../enums';
import { Testable } from '../../../interfaces/componentProps';
import {
  getBackgroundColorClass,
  getTestSelectorAttribute
} from '../../../utils';
import './AcHeader.sass';

export interface AcHeaderProps
  extends HTMLAttributes<HTMLDivElement>,
    Testable {
  color?: Color;
  left?: ReactNode;
  right?: ReactNode;
}

const AcHeader: ForwardRefRenderFunction<
  HTMLDivElement,
  PropsWithChildren<AcHeaderProps>
> = (
  {
    className: classNameProp,
    testSelector,
    children,
    color = Color.PrimaryDark,
    left,
    right,
    ...divProps
  },
  ref
) => {
  const className = classNames(
    'ac-header',
    getBackgroundColorClass(color),
    !!left && 'ac-header-has-left-side',
    !!right && 'ac-header-has-right-side',
    classNameProp
  );

  return (
    <header
      ref={ref}
      {...divProps}
      className={className}
      {...getTestSelectorAttribute(testSelector)}
    >
      {left && <div className="ac-header-side-left">{left}</div>}
      <div className="ac-header-center">{children}</div>
      {right && <div className="ac-header-side-right">{right}</div>}
    </header>
  );
};

const forward = forwardRef(AcHeader);
export default memo(forward);
