import { acConfig } from '../../declarations';
import {
  GlobalAcConfigSEPInternalFrontendUrls,
  getFrontendURL,
} from '../../declarations/config/ac.config';
import { isStandalone } from '../../utils/micro-frontends/isStandalone';
import { I18N_DEFAULT_NS, TRANSLATIONS_DIRECTORY } from '../constants';
import { removeTrailingSlash } from '../../utils/removeTrailingSlash';
import { I18nConfig, TranslationsModuleName } from './types';
import { getI18nManifest } from '../manifest';

const languagesMap = {
  de: 'de',
  es: 'es',
  fr: 'fr',
  it: 'it',
  ja: 'ja',
  ko: 'ko',
  nl: 'nl',
  pl: 'pl',
  th: 'th',
  tr: 'tr',
  'zh-hans': 'zh-Hans',
  'zh-hant': 'zh-Hant',
};

const getHash = (namespace: string): string => {
  return getI18nManifest().hashMap[namespace] ?? '';
};

const getModuleBaseUrl = (moduleName: TranslationsModuleName): string => {
  return getFrontendURL(
    moduleName,
    acConfig.frontendUrls,
    acConfig.containerFrontendUrl,
    acConfig.newFrontendUrls as GlobalAcConfigSEPInternalFrontendUrls
  );
};

const getRemoteTranslationBaseUrl = (
  config: I18nConfig
): string | undefined => {
  return `${acConfig.frontendUrls.translations}/${TRANSLATIONS_DIRECTORY}/${config.repositoryName}`;
};

const getStaticTranslationBaseUrl = (config: I18nConfig): string => {
  const { moduleName = 'localhost' } = config;

  if (isStandalone()) {
    return `${location.origin}/${TRANSLATIONS_DIRECTORY}`;
  }

  return `${removeTrailingSlash(
    getModuleBaseUrl(moduleName)
  )}/${TRANSLATIONS_DIRECTORY}`;
};

export const initPathResolver = (config: I18nConfig) => {
  const { useRemoteTranslations, repositoryName } = config;
  const staticTranslationsBaseUrl = getStaticTranslationBaseUrl(config);
  const remoteTranslationsBaseUrl = getRemoteTranslationBaseUrl(config);
  const isProduction = process.env['NODE_ENV'] === 'production';

  return (languages: string[], namespaces: string[]): string => {
    const [language] = languages;
    const [namespace] = namespaces;

    const isDefaultNS = namespace == I18N_DEFAULT_NS;
    const nsPath = isDefaultNS ? '' : `/${namespace}`;

    let resourcePath = `${staticTranslationsBaseUrl}${nsPath}/en.json`;

    if (language in languagesMap && language !== 'en') {
      resourcePath =
        useRemoteTranslations && repositoryName
          ? `${remoteTranslationsBaseUrl}${nsPath}/en.${languagesMap[language]}.json`
          : `${staticTranslationsBaseUrl}${nsPath}/en.${languagesMap[language]}.json`;
    } else if (language !== 'en') {
      // eslint-disable-next-line no-console
      console.warn(
        `language ${language} is not defined in known languages list. this should
         be included in the list or something is wrong`
      );
    }

    return `${resourcePath}${
      isProduction ? `?v=${getHash(namespace || I18N_DEFAULT_NS)}` : ''
    }`;
  };
};
