import { loadPage } from '../shared/loadPage';
import { loadExternalStyles } from '../shared/stylesheet';
import { EmberApplication } from './emberApplication';
import { loadEmberScripts } from './loadEmberApp';
import {
  extractAppCssPath,
  extractAppJsPath,
  extractVendorCssPath,
  extractVendorJsPath,
} from './scriptPathExtraction';

export const loadEmberApp = async <AdditionalModuleExports = object>(
  appUrl: string,
  appName: string
): Promise<EmberApplication<AdditionalModuleExports>> => {
  const indexPage = await loadPage(appUrl);

  const vendorCssUrl = extractVendorCssPath(indexPage);
  const appCssUrl = extractAppCssPath(indexPage, appName);
  loadExternalStyles(appUrl, [
    { filePath: vendorCssUrl },
    { filePath: appCssUrl },
  ]);

  const vendorJsUrl = extractVendorJsPath(indexPage);
  const appJsUrl = extractAppJsPath(indexPage, appName);

  const result = await loadEmberScripts<AdditionalModuleExports>(
    appName,
    `${appUrl}/${appJsUrl}`,
    `${appUrl}/${vendorJsUrl}`
  );

  return result;
};
