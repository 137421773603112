import {
  PropertyContext,
  SepModuleBusinessContextConverter,
  SepModuleBusinessContextData,
} from '@ac/library-api';

import { getACPNamespace } from '../../../../namespace/globalNamespace';

export const registerPropertyBusinessContextUnitChangeHandler = (
  handler: (context: PropertyContext) => void
): void => {
  const businessContext = getACPNamespace()?.businessContext;

  if (!businessContext) {
    return;
  }

  const currentOnUnitChange = businessContext.onUnitChanged;
  const newOnUnitChange = (updatedContext: SepModuleBusinessContextData) => {
    if (!updatedContext.property) {
      return;
    }

    const newContext =
      new SepModuleBusinessContextConverter().buildPropertyContext(
        updatedContext
      );

    handler(newContext);
  };

  businessContext.onUnitChanged = (context: SepModuleBusinessContextData) => {
    currentOnUnitChange?.(context);
    newOnUnitChange(context);
  };
};
