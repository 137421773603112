import classNames from 'classnames';
import * as H from 'history';
import React, { FC, memo, ReactNode, useMemo } from 'react';
import {
  Color,
  FlexDirection,
  Icon,
  IconSize,
  TextSize,
  TextWeight
} from '../../enums';
import {
  ChildRequired,
  Styleable,
  Testable
} from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import { AcFlex } from '../flex';
import { AcIcon } from '../icon';
import { AcText } from '../text';
import './AcTile.sass';
import { AcTileVariant } from './acTileVariant';

export interface AcTileNavItem extends Testable {
  link: H.Location;
  title: string;
}

export interface AcTileProps extends ChildRequired, Styleable, Testable {
  title?: string;
  icon?: Icon;
  variant?: AcTileVariant;
  navItems?: AcTileNavItem[];
  customHeaderTemplate?: ReactNode;
}

const defaultProps: AcTileProps = {
  title: '',
  variant: AcTileVariant.Normal,
  children: null
};

/* tslint:disable:jsx-no-lambda */
const AcTile: FC<AcTileProps> = (props: AcTileProps) => {
  const className: string = classNames(
    'ac-tile',
    `ac-tile-${props.variant}`,
    props.className
  );

  const defaultHeaderTemplate = useMemo(() => {
    return (
      <>
        {props.icon && (
          <AcIcon
            size={IconSize.Small}
            icon={props.icon}
            className="ac-spacing-right-sm"
          />
        )}
        <AcText
          uppercase={true}
          color={Color.Black}
          size={TextSize.Main2}
          weight={TextWeight.Semibold}
        >
          {props.title}
        </AcText>
      </>
    );
  }, [props.title, props.icon]);

  return (
    <AcFlex
      style={props.style}
      className={className}
      direction={FlexDirection.column}
      {...getTestSelectorAttribute(props.testSelector)}
    >
      {(props.customHeaderTemplate || props.title) && (
        <div className="tile-header">
          {props.customHeaderTemplate
            ? props.customHeaderTemplate
            : defaultHeaderTemplate}
        </div>
      )}

      <div className="tile-content">{props.children}</div>
    </AcFlex>
  );
};

AcTile.defaultProps = defaultProps;
export default memo(AcTile);
