import { ViewBreakpoint } from './viewBreakpoint';

export const getCurrentViewBreakpoint = (): ViewBreakpoint => {
  const body = document.getElementsByTagName('body')[0];

  const contextHelpWidth =
    parseInt(
      getComputedStyle(document.documentElement)
        .getPropertyValue('--sp-context-help-width')
        ?.substring(-2),
      10
    ) || 0;
  const currentWidth =
    (window.innerWidth ||
      document.documentElement.clientWidth ||
      body.clientWidth) - contextHelpWidth;

  switch (true) {
    case currentWidth >= ViewBreakpoint.XLG:
      return ViewBreakpoint.XLG;
    case currentWidth >= ViewBreakpoint.LG:
      return ViewBreakpoint.LG;
    case currentWidth >= ViewBreakpoint.MD:
      return ViewBreakpoint.MD;
    case currentWidth >= ViewBreakpoint.SM:
    default:
      return ViewBreakpoint.SM;
  }
};
