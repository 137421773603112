import React from '../../../../../wc';
import { PropsWithChildren } from '../../../../reactTransition/propsWithChildren';

import { PropertyBusinessContext } from './context';
import { useCreatePropertyBusinessContextStore } from './store';

export const PropertyBusinessContextProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const store = useCreatePropertyBusinessContextStore();

  return (
    <PropertyBusinessContext.Provider value={store}>
      {props.children}
    </PropertyBusinessContext.Provider>
  );
};
