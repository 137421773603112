import classNames from 'classnames';
import React, { FC, HTMLAttributes, memo } from 'react';
import { Color, Icon, IconSize } from '../../enums';
import { Testable } from '../../interfaces/componentProps';
import { getTestSelectorAttribute } from '../../utils';
import { AcIcon } from '../icon';
import './AcDotBadge.sass';

export interface AcDotBadgeProps
  extends HTMLAttributes<HTMLSpanElement>,
    Testable {
  color?: Color;
}

const AcDotBadge: FC<AcDotBadgeProps> = ({
  color,
  children,
  testSelector,
  className: classNameProp,
  ...spanProp
}) => {
  const className = classNames('ac-dot-badge', classNameProp);

  return (
    <span
      className={className}
      {...spanProp}
      {...getTestSelectorAttribute(testSelector)}
    >
      <AcIcon
        size={IconSize.Small}
        color={color}
        icon={Icon.Dot}
        className="ac-spacing-right-xxs"
      />
      <span {...getTestSelectorAttribute(testSelector, 'text')}>
        {children}
      </span>
    </span>
  );
};

export default memo(AcDotBadge);
