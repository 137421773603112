import { useCallback, useState } from 'react';

import { CustomerContext } from '@ac/library-api';

import {
  getDefaultTenantBusinessContextState,
  TenantBusinessContextState,
} from './state';

export interface TenantBusinessContextStore {
  state: TenantBusinessContextState;

  setBusinessContext(businessContext: CustomerContext): void;
}

export const useCreateTenantBusinessContextStore =
  (): TenantBusinessContextStore => {
    const [state, setState] = useState(getDefaultTenantBusinessContextState());

    const setBusinessContext = useCallback(
      (businessContext: CustomerContext) => {
        setState((prevState) => ({
          ...prevState,
          businessContext,
        }));
      },
      []
    );

    return {
      state,
      setBusinessContext,
    };
  };
